import styled from 'styled-components';
import { currentBranding } from 'v2/helpers/brandingHelpers';
import { SidebarSubMenuContainerWithDegree } from '../SidebarSubMenuDegree/SidebarSubMenuDegree.styles';

const getHoverColor = () => {
  switch (currentBranding) {
    case 'OSD':
      return 'midnightGreen';
    case 'WM':
      return 'japaneseIndigo200';
  }
};

const getContainerTopValueByItemTitle = (title) => {
  switch (title) {
    case 'Vendas':
      return 'top: 30px !important;';
    case 'Cadastros':
      return 'top: 60px !important;';
    case 'Relatórios':
      return 'top: 260px !important;';
    case 'Notas Fiscais':
      return 'top: 400px !important;';
  }
};

export const SidebarSubMenuContainer = styled.div`
  display: none;
  cursor: default;
  min-width: 250px;
  width: max-content;
  padding: 5px 15px;
  position: absolute;
  left: 63px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.branding1000};
  border-top-right-radius: ${(props) => props.theme.radii.sm};
  border-bottom-right-radius: ${(props) => props.theme.radii.sm};
  z-index: 1000;

  ${({ title }) => getContainerTopValueByItemTitle(title)}
`;

export const SidebarSubMenuTitle = styled.h2`
  color: ${(props) => props.theme.colors.white};
  text-align: left;
  padding-bottom: 4px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
  margin-top: 10px !important;
`;

export const SidebarSubMenuItemsContainer = styled.ul`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SidebarSubMenuItem = styled.li`
  background-color: transparent;
  border: 0;
  font-size: 14px;

  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  z-index: 2000;

  color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii.sm};

  &:hover {
    background-color: ${(props) => props.theme.colors[getHoverColor()]};
    cursor: pointer;
  }

  &:hover > ${SidebarSubMenuContainerWithDegree} {
    display: block;
  }

  ${(props) =>
    props.disabledStyle
      ? `
     color: ${props.theme.colors.gray100};
  `
      : ''}
`;
