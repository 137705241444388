import React from 'react'
import { Col } from 'react-bootstrap'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'

const DashboardCard = ({
  link,
  title,
  value,
  icon,
  color,
  fontColor,
  pendingAmount,
  onClick,
  averageTicket,
  showMessage,
  Message,
  isLoading,
}) => {
  return (
    <Link to={link} id="card-dashboard">
      <Button onClick={onClick}>
        <main>
          <p>{title}</p>
          <h1>
            <strong style={{ color: fontColor }}>{value}</strong>
          </h1>
          <div
            style={{
              backgroundColor: color,
              borderRadius: '800px',
              height: '40px',
            }}
          >
            <FontAwesomeIcon
              icon={icon}
              className="iconedashboard"
              aria-hidden="false"
            />
          </div>
          {showMessage && !isLoading && <Message />}
          {pendingAmount ? (
            <span style={{ fontSize: '10px' }}>
              Existe o valor de{' '}
              <strong style={{ color: fontColor }}> {pendingAmount} </strong>{' '}
              pendente de baixa
            </span>
          ) : (
            <div />
          )}
          {averageTicket ? (
            <span style={{ fontSize: '9px', color: '#d9534f' }}>
              {averageTicket}
            </span>
          ) : (
            <div />
          )}
        </main>
      </Button>
    </Link>
  )
}

export default DashboardCard
