import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useRecoilState } from 'recoil';

import Form from './SalesConfig';
import companiesRepository from '../../../../repositories/Companies';
import { useAuth } from '../../../../contexts/auth';
import constants from '../../../../utils/constants';
import companyConfigRepository from 'repositories/CompanyConfig';
import schedulesRepository from 'repositories/Schedules';

import companyConfigStorage from 'storage/companyConfigStorage';

const SalesConfig = () => {
  const { loadCompany, companyId, company } = useAuth();
  const [inventoryWriteDownMode, setInventoryWriteDownMode] = useRecoilState(
    companyConfigStorage.inventoryWriteDownMode
  );

  const dispatch = useDispatch();

  const [companyLastSaleCode, setCompanyLastSaleCode] = useState('');

  useEffect(() => {
    if (company) {
      loadInfo(company);
    }
  }, [company]);

  async function loadInfo(company) {
    const configs = await companyConfigRepository.show(company.id);

    const initialValues = {
      salePrintingSheetId: String(company.salePrintingSheetId),
      orcamentPrintingSheetId: String(company.orcamentPrintingSheetId),
      pdvPrintingSheetId: String(company.pdvPrintingSheetId),
      orderOfServicePrintingSheetId: String(
        company.orderOfServicePrintingSheetId
      ),
      saleWithZeroStockPermissionId: String(
        company.saleWithZeroStockPermissionId
      ),
      vehiclesAnnotationType: String(company.vehiclesAnnotationType),
      productCommissionCalculatedBy: String(
        company.productCommissionCalculatedBy
      ),
      defaultOrcamentFooterText: String(
        company.defaultOrcamentFooterText === null
          ? ''
          : company.defaultOrcamentFooterText
      ),
      defaultSalesOrderFooterText: String(
        company.defaultSalesOrderFooterText === null
          ? ''
          : company.defaultSalesOrderFooterText
      ),
      serviceCommissionCalculatedBy: String(
        company.serviceCommissionCalculatedBy
      ),
      defaultProductCommissionRate: company.defaultProductCommissionRate,
      defaultServiceCommissionRate: company.defaultServiceCommissionRate,
      schedulingOnSale: company.schedulingOnSale,
      changePriceOnSale: company.changePriceOnSale,
      showPurchasePriceOnSale: company.showPurchasePriceOnSale,
      sendSMSWhenFinished: company.sendSMSWhenFinished,
      boxPrismaOnSale: company.boxPrismaOnSale,
      responsibleSeller: company.responsibleSeller,
      updateServiceDescriptionOnSale: configs.updateServiceDescriptionOnSale,
      averageTicketPeriodInMonths: configs.averageTicketPeriodInMonths,
      showSheetSignatureField: configs.showSheetSignatureField,
      sendSmsAndEmailToRememberSchedule:
        configs.sendSmsAndEmailToRememberSchedule,
      sendEmailToRememberSchedule: configs.sendEmailToRememberSchedule,
      daySelectedToSendSMS: configs.daySelectedToSendSMS,
      closeBudgetAutomatically: configs.closeBudgetAutomatically,
      daysToCloseBudgetAutomatically: configs.daysToCloseBudgetAutomatically,
      lossReasonId: configs.lossReasonId,
      lastSaleCode: configs.lastSaleCode,
      printSaleWhenCreate: configs.printSaleWhenCreate,
      allowOrderOfServiceWithoutVehicle:
        configs.allowOrderOfServiceWithoutVehicle,
      salesRepresentative: company.salesRepresentative,
      typeOfService: company.typeOfService,
      advancedSearchType: configs.advancedSearchType,
      displayNotesInChecklist: company.displayNotesInChecklist,
      kitRegistration: configs.kitRegistration,
      stockWriteOff: configs.stockWriteOff,
      monthRangeOfCustomerReturnCalc: configs.monthRangeOfCustomerReturnCalc,
      saleItemApprovalCancellation: configs.saleItemApprovalCancellation,
      budgetPaymentSuggestions: configs.budgetPaymentSuggestions,
      AutoAddSingleItemToPDV: configs.AutoAddSingleItemToPDV,
      comissionProrate: configs.comissionProrate,
      sellingPriceType: configs.sellingPriceType,
      activateWorkshopPanel: configs.activateWorkshopPanel,
      pointingByThePanel: configs.pointingByThePanel,
      autoPanelAppointment: configs.autoPanelAppointment,
      discountAboveLimit: configs.discountAboveLimit,
      partsRequisitionForOpenOS: configs.partsRequisitionForOpenOS,
    };
    dispatch(initialize('salesConfiguration', initialValues));

    setCompanyLastSaleCode(configs.lastSaleCode);
  }

  async function handleUpdateSalesConfiguration(values) {
    const {
      salePrintingSheetId,
      orcamentPrintingSheetId,
      orderOfServicePrintingSheetId,
      pdvPrintingSheetId,
      saleWithZeroStockPermissionId,
      productCommissionCalculatedBy,
      serviceCommissionCalculatedBy,
      defaultProductCommissionRate,
      defaultServiceCommissionRate,
      schedulingOnSale,
      changePriceOnSale,
      showPurchasePriceOnSale,
      vehiclesAnnotationType,
      defaultOrcamentFooterText,
      defaultSalesOrderFooterText,
      sendSMSWhenFinished,
      boxPrismaOnSale,
      responsibleSeller,
      updateServiceDescriptionOnSale,
      averageTicketPeriodInMonths,
      showSheetSignatureField,
      sendSmsAndEmailToRememberSchedule,
      sendEmailToRememberSchedule,
      daySelectedToSendSMS,
      closeBudgetAutomatically,
      daysToCloseBudgetAutomatically,
      lossReasonId,
      lastSaleCode,
      printSaleWhenCreate,
      allowOrderOfServiceWithoutVehicle,
      salesRepresentative,
      typeOfService,
      advancedSearchType,
      displayNotesInChecklist,
      kitRegistration,
      stockWriteOff,
      monthRangeOfCustomerReturnCalc,
      saleItemApprovalCancellation,
      budgetPaymentSuggestions,
      AutoAddSingleItemToPDV,
      comissionProrate,
      activateWorkshopPanel,
      sellingPriceType,
      pointingByThePanel,
      autoPanelAppointment,
      discountAboveLimit,
      partsRequisitionForOpenOS,
    } = values;

    if (closeBudgetAutomatically) {
      if (
        parseInt(daysToCloseBudgetAutomatically) === 0 ||
        lossReasonId === ''
      ) {
        return toastr.warning(
          'Preencha corretamente o dia e motivo para ativar o cancelamento automático de orçamento'
        );
      }
    }

    if (lastSaleCode < companyLastSaleCode) {
      return toastr.warning(
        'O número da última venda/OS selecionado já foi utilizado',
        'Por favor, verifique e tente novamente.'
      );
    }

    try {
      await companiesRepository.updateSalesConfiguration(companyId, {
        salePrintingSheetId,
        orcamentPrintingSheetId,
        orderOfServicePrintingSheetId,
        pdvPrintingSheetId,
        saleWithZeroStockPermissionId,
        productCommissionCalculatedBy,
        serviceCommissionCalculatedBy,
        defaultProductCommissionRate,
        defaultServiceCommissionRate,
        schedulingOnSale,
        changePriceOnSale,
        showPurchasePriceOnSale,
        vehiclesAnnotationType,
        defaultOrcamentFooterText,
        defaultSalesOrderFooterText,
        sendSMSWhenFinished,
        updateServiceDescriptionOnSale,
        averageTicketPeriodInMonths,
        showSheetSignatureField,
        sendSmsAndEmailToRememberSchedule,
        sendEmailToRememberSchedule,
        daySelectedToSendSMS,
        closeBudgetAutomatically,
        responsibleSeller,
        boxPrismaOnSale,
        daysToCloseBudgetAutomatically,
        lossReasonId,
        lastSaleCode,
        printSaleWhenCreate,
        allowOrderOfServiceWithoutVehicle,
        salesRepresentative,
        typeOfService,
        advancedSearchType,
        displayNotesInChecklist,
        kitRegistration,
        stockWriteOff,
        monthRangeOfCustomerReturnCalc,
        saleItemApprovalCancellation,
        budgetPaymentSuggestions,
        AutoAddSingleItemToPDV,
        comissionProrate,
        activateWorkshopPanel,
        sellingPriceType,
        pointingByThePanel,
        autoPanelAppointment,
        discountAboveLimit,
        partsRequisitionForOpenOS,
      });

      setInventoryWriteDownMode(stockWriteOff);

      toastr.success('Configurações', 'Alterações realizadas com sucesso.');
      loadCompany();
    } catch (err) {
      if (err.response) {
        return toastr.warning(err.response.data.message);
      }
      toastr.warning(
        'Ocorreu um erro ao atualizar as configurações de impresso'
      );
    }
  }

  const initialValues = {
    salePrintingSheetId: constants.PRINTING_SHEETS.SHEET1,
    orcamentPrintingSheetId: constants.PRINTING_SHEETS.SHEET1,
    orderOfServicePrintingSheetId: constants.PRINTING_SHEETS.SHEET1,
    pdvPrintingSheetId: constants.PRINTING_SHEETS.THERMAL_COIL,
    saleWithZeroStockPermissionId: constants.STOCK_PERMISSIONS_ID.ALLOW,
    productCommissionCalculatedBy: 1,
    serviceCommissionCalculatedBy: 1,
    defaultProductCommissionRate: 0,
    defaultServiceCommissionRate: 0,
    vehiclesAnnotationType: 1,
    updateServiceDescriptionOnSale: false,
    averageTicketPeriodInMonths: '3',
    showSheetSignatureField: 0,
    sendSmsAndEmailToRememberSchedule: 0,
    sendEmailToRememberSchedule: 0,
    closeBudgetAutomatically: false,
    daysToCloseBudgetAutomatically: 0,
    lossReasonId: '',
    lastSaleCode: 0,
  };

  return (
    <Form
      onSubmit={handleUpdateSalesConfiguration}
      initialValues={initialValues}
    />
  );
};

export default SalesConfig;
