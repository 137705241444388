import api from '../services/api'

export const index = async (query) => {
  const { data } = await api.get('customer', { params: query })
  return data
}

export const indexCustomersWithVehicles = async (query) => {
  const { data } = await api.get('customer/vehicle', { params: query })
  return data
}

const CustomerRepository = {
  index,
  indexCustomersWithVehicles,
}

export default CustomerRepository
