import React, { useState } from 'react'
import { Field } from 'redux-form'

import { onlyLetters } from 'client/components/ToNormalize/ToNormalize'

import { yearMask } from 'utils/masks'

import '../styles.css'
import { BikeManufacturerModal } from '../BikeManufacturerModal'
import { BikeModelModal } from '../BikeModelModal'
import { onlyNumbersLetters } from 'client/components/ToNormalize/ToNormalize'

const BikeForm = ({
  manufacturers,
  loadBicycleManufacturers,
  getBicycleModelsByBrand,
  RenderField,
  bicycleRearDerailleurId,
  bicycleBrakeTypes,
  bicycleSuspensionTypes,
  bicycleFrontGears,
  bicycleRearGears,
  bicycleRimSizes,
  bicycleFrameSizes,
  bicycleModalities,
  bicycleRearDerailleurs,
  bicycleFrameMaterials,
  bicycleModels,
  dispatch,
  change,
}) => {
  const [isBikeManufacturerModalOpen, setIsBikeManufacturerModalOpen] =
    useState(false)
  const [isBikeModelModalOpen, setIsBikeModelModalOpen] = useState(false)

  return (
    <>
      <div
        className="form-new-vehicle__simple-row"
        style={{ width: 350, marginTop: 5 }}
      >
        <label>
          Série/Identificação: <span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          name="serialIdentification"
          component={RenderField}
          maxLength="30"
          normalize={onlyNumbersLetters}
        />
      </div>

      <div className="form-new-vehicle__row">
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <label>
              Marca: <span style={{ color: 'red' }}>*</span>{' '}
            </label>

            <label>
              <a
                style={{
                  cursor: 'pointer',
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: '----',
                  textDecoration: 'none',
                }}
                onClick={() => setIsBikeManufacturerModalOpen(true)}
              >
                <u>Nova Marca</u>
              </a>
            </label>
          </div>

          <Field
            onChange={() => dispatch(change('vehicle', 'bicycleModelId', null))}
            name="Brand"
            required
            as="select"
            component={RenderField}
          >
            <option value="1">Selecione</option>
            {manufacturers?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.Description}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 400 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <label>
              Modelo: <span style={{ color: 'red' }}>*</span>{' '}
            </label>

            <label>
              <a
                style={{
                  cursor: 'pointer',
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: '----',
                  textDecoration: 'none',
                }}
                onClick={() => setIsBikeModelModalOpen(true)}
              >
                <u>Novo Modelo</u>
              </a>
            </label>
          </div>
          <Field
            name="bicycleModelId"
            as="select"
            component={RenderField}
            required
          >
            <option value="">
              {bicycleModels?.length > 0
                ? 'Selecione'
                : 'Nenhum modelo encontrado'}
            </option>
            {bicycleModels?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div>
          <label>Modalidade:</label>
          <Field name="bicycleModalityId" as="select" component={RenderField}>
            <option value="">Selecione</option>
            {bicycleModalities?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 100 }}>
          <label>Ano:</label>
          <Field
            name="Year_Manufacture"
            {...yearMask}
            component={RenderField}
          />
        </div>
      </div>

      <div className="form-new-vehicle__row">
        <div style={{ width: 250 }}>
          <label>Material do Quadro:</label>
          <Field
            name="bicycleFrameMaterialId"
            as="select"
            component={RenderField}
          >
            <option value="">Selecione</option>
            {bicycleFrameMaterials?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 200 }}>
          <label>Tamanho do Quadro:</label>
          <Field name="bicycleFrameSizeId" as="select" component={RenderField}>
            <option value="">Selecione</option>
            {bicycleFrameSizes?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 220 }}>
          <label>Tamanho da Roda (aro):</label>
          <Field name="bicycleRimSizeId" as="select" component={RenderField}>
            <option value="">Selecione</option>
            {bicycleRimSizes?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description}
              </option>
            ))}
          </Field>
        </div>

        <div>
          <label>Tipo de Suspensão:</label>
          <Field
            name="bicycleSuspensionTypeId"
            as="select"
            component={RenderField}
          >
            <option value="">Selecione</option>
            {bicycleSuspensionTypes?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 220 }}>
          <label>Tipo de Freio:</label>
          <Field name="bicycleBrakeTypeId" as="select" component={RenderField}>
            <option value="">Selecione</option>
            {bicycleBrakeTypes?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-new-vehicle__row">
        <div style={{ width: 250 }}>
          <label>Câmbio Traseiro:</label>
          <Field
            name="bicycleRearDerailleurId"
            as="select"
            component={RenderField}
          >
            <option value="">Selecione</option>
            {bicycleRearDerailleurs?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        {bicycleRearDerailleurId === '71' && (
          <div style={{ width: 250 }}>
            <label>Qual?</label>
            <Field
              name="otherRearDerailleurDescription"
              as="text"
              component={RenderField}
            />
          </div>
        )}

        <div>
          <label>Nº marchas dianteiras:</label>
          <Field name="bicycleFrontGearId" as="select" component={RenderField}>
            <option value="">Selecione</option>
            {bicycleFrontGears?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.quantity}
              </option>
            ))}
          </Field>
        </div>

        <div>
          <label>Nº marchas traseiras:</label>
          <Field name="bicycleRearGearId" as="select" component={RenderField}>
            <option value="">Selecione</option>
            {bicycleRearGears?.map((child) => (
              <option key={child.id} value={child.id}>
                {child?.quantity}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 150 }}>
          <label>Cor:</label>

          <Field
            name="Color"
            as="text"
            component={RenderField}
            normalize={(value) => onlyLetters(value.toUpperCase())}
          />
        </div>
      </div>

      {isBikeManufacturerModalOpen && (
        <BikeManufacturerModal
          loadBicycleManufacturers={loadBicycleManufacturers}
          type={'bicycleManufacturer'}
          onCancel={() => setIsBikeManufacturerModalOpen(false)}
        />
      )}

      {isBikeModelModalOpen && (
        <BikeModelModal
          getBicycleModelsByBrand={getBicycleModelsByBrand}
          manufacturers={manufacturers}
          onCancel={() => setIsBikeModelModalOpen(false)}
        />
      )}
    </>
  )
}

export default BikeForm
