import React from 'react';
import { useRecoilState } from 'recoil';

import UnavailableFeatureModal from 'client/components/UnavailableFeatureModal';
import { InactiveModuleModal } from 'client/components/InactiveModuleModal';

import { useBlockModals } from 'v2/hooks/useBlockModals';
import FiscalModuleInactiveModal from 'client/components/FiscalModuleInactiveModal';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import constants from 'utils/constants';
import { SegmentsBlockedModal } from 'client/components/SegmentsBlockedModal';
import { useAuth } from 'contexts/auth';

export function GlobalBlockModals() {
  const {
    isFeatureNotAvailableModalOpen,
    setIsFeatureNotAvailableModalOpen,

    isModuleInactiveModalOpen,
    setIsModuleInactiveModalOpen,

    currentInactiveModule,
    currentInactiveFiscalModule,

    isFiscalModuleInactiveModalOpen,
    setIsFiscalModuleInactiveModalOpen,

    isFiscalModuleInactivePlanVariationModalOpen,
    setIsFiscalModuleInactivePlanVariationModalOpen,

    isSegmentsBlockedModalOpen,
    setIsSegmentsBlockedModalOpen,
  } = useBlockModals();

  const { companyId } = useAuth();
  const { isPlanFree } = usePlanSignatureContext();

  const handleNavigateToMyCompany = async () => {
    setIsSegmentsBlockedModalOpen(false);

    window.open(
      window.location.origin + `/client/${constants.ROUTES.COMPANY_DETAILS}`,
      '_blank'
    );
  };

  return (
    <>
      <UnavailableFeatureModal
        show={isFiscalModuleInactivePlanVariationModalOpen}
        onHide={() => setIsFiscalModuleInactivePlanVariationModalOpen(false)}
        unavailableFeatureText={
          `O plano ${
            isPlanFree ? 'free' : 'start'
          } não possui emissão de documento fiscal. ` +
          'Entre em contato com o nosso comercial e solicite a troca de plano e o módulo fiscal.'
        }
      />

      <UnavailableFeatureModal
        show={isFeatureNotAvailableModalOpen}
        onHide={() => setIsFeatureNotAvailableModalOpen(false)}
        unavailableFeatureText={
          'Para ter acesso a funcionalidade você precisa solicitar o upgrade do seu plano, para isso, ' +
          'clique no botão solicitar troca de plano para conversar com um dos nossos consultores comerciais.'
        }
      />

      <InactiveModuleModal
        show={isModuleInactiveModalOpen}
        onHide={() => setIsModuleInactiveModalOpen(false)}
        module={currentInactiveModule}
      />

      <FiscalModuleInactiveModal
        show={isFiscalModuleInactiveModalOpen}
        onHide={() => setIsFiscalModuleInactiveModalOpen(false)}
        module={currentInactiveFiscalModule}
      />

      <SegmentsBlockedModal
        companyId={companyId}
        show={isSegmentsBlockedModalOpen}
        onHide={() => setIsSegmentsBlockedModalOpen(false)}
        onSubmit={() => handleNavigateToMyCompany()}
      />
    </>
  );
}
