import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { withRouter } from 'react-router';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import constants from '../../../../utils/constants'

const Footer = ({ history, handleSubmit, loading, handleCancel, titleId }) => {
  const { amount, discount, openValue, payments, liquidValue, paidValue, addition, code, billStatusId } = useSelector(state => state.form.billToPay.values)

  const dispatch = useDispatch()
  useEffect(() => {
    const serializedPayments = payments.filter(payment => !!payment.paymentDate)

    const paidValue = !serializedPayments.length
    ? 0
    : serializedPayments.length === 1
      ? serializedPayments[0].value
      : serializedPayments.reduce((prev, curr) => prev + curr.value, 0)
      
    dispatch(change('billToPay', 'paidValue', paidValue))
  }, [payments])

  useEffect(() => {
    dispatch(change('billToPay', 'liquidValue', amount + addition - discount))
  }, [amount, addition, discount])

  useEffect(() => {
    const openValue = (liquidValue - paidValue).toFixed(2)
    dispatch(change('billToPay', 'openValue', openValue))
  }, [liquidValue, paidValue])

  const isClosedOrCanceled = billStatusId === constants.BILLS_STATUS.CLOSED || billStatusId === constants.BILLS_STATUS.CANCELED
  const isClosed = billStatusId === constants.BILLS_STATUS.CLOSED
  const isCanceled = billStatusId === constants.BILLS_STATUS.CANCELED
  const isOpen = billStatusId === constants.BILLS_STATUS.OPEN

  return (
    <div id='bill-to-receive-footer'>
      <header>
        <strong>Nº Título: </strong>
        <strong>{code}</strong>
      </header>
      <main>
        <div>
          <strong>Valor Bruto: {currency(amount)}</strong>
          <strong>Acréscimo: {currency(addition)}</strong>
          <strong>Desconto: {currency(discount)}</strong>
        </div>
        <div>
          <strong>Valor Líquido: {currency(liquidValue)}</strong>
          <strong>Valor Pago: {currency(paidValue)}</strong>
          <strong>Valor em Aberto: <span style={{ color: Number(openValue) !== 0 ? 'red' : 'green' }}>{currency(Number(openValue))}</span></strong>
        </div>

      </main>
      <footer>
        <Button
          bsStyle='info'
          fill
          onClick={handleSubmit}
          id={isCanceled ? "btn-title" : ""}
          disabled={loading || isClosedOrCanceled}
        >
          <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          {isClosed
            ? <span>Título Finalizado</span>
            : isCanceled
              ? <span>Título Cancelado</span>
              : <span>Salvar</span>
          }
        </Button>
        <Button
          bsStyle='danger'
          fill
          onClick={() => isCanceled ? history.push(constants.ROUTES.BILLS_TO_PAY) : handleCancel()}
          disabled={loading}
        >
          {
            ((isClosed || isOpen) && titleId)
            ? <span>Cancelar Título</span>
            : <span>Voltar</span>
          }
        </Button>
      </footer>
    </div>
  )
}

export default withRouter(Footer)