import { faLineChart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../styles.css';
import { useBranding } from 'hooks/useBranding';

const Header = () => {
  const { isPO360 } = useBranding();

  return (
    <>
      <div className="income-statement__header">
        <div
          className={`income-statement__header-rectangle ${
            isPO360 ? 'po360-bg-primary' : ''
          }`}
        />
      </div>

      <div
        className={`income-statement__title ${
          isPO360 ? 'po360-text-primary' : ''
        }`}
      >
        <FontAwesomeIcon icon={faLineChart} style={{ marginRight: '10px' }} />
        DRE - Demonstrativo de Resultado de Exercício
      </div>

      <center>
        <div className="income-statement__line" />
      </center>
    </>
  );
};

export default Header;
