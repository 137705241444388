import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import CryptoJS from 'crypto-js';

import { useAuth } from '../../contexts/auth';

import './styles.css';
import OverwriteUserSessionModal from '../../components/OverwriteUserSessionModal';

import api from 'services/api';
import { useBranding } from 'hooks/useBranding';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

const LoginPage = ({ history, location, match }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginURL, setLoginURL] = useState(false);

  const [
    isConfirmOverwriteUserSessionMoldalOpen,
    setIsConfirmOverwriteUserSessionMoldalOpen,
  ] = useState(false);
  const [userIdToOverwrite, setUserIdToOverwrite] = useState(null);
  const [eye, setEye] = useState('fa fa-eye');

  const { userId, signIn, clearToken } = useAuth();

  useEffect(() => {
    callCheckURL();
    if (location.search?.split('expiredSession=')[1] === 'true') {
      toastr.info('Sessão Expirada', 'Por favor, faça o login novamente.');
    }
  }, []);

  useEffect(() => {
    if (loginURL) {
      checkURL();
      if (email && password) {
        submit();
      }
    }
  }, [loginURL, email, password]);

  function callCheckURL() {
    const params = new URLSearchParams(location.search);

    let userEmail = params.get('email');
    let userPassword = params.get('pass');

    if (userEmail && userPassword) {
      setLoginURL(true);
    }

    //Se tiver apenas email (vindo do Criar conta > Conta já existente), colocar email no input
    if (userEmail) {
      setEmail(userEmail);
    }
  }

  const checkURL = async () => {
    /// MÉTODO PARA CHECAR SE O LINK JÁ É COM O USUÁRIO LOGADO

    const params = new URLSearchParams(location.search); // classe para pegar queryString da URL

    let userEmail = params.get('email');
    let userPassword = params.get('pass');

    if (userEmail && userPassword) {
      localStorage.clear();
      /// TROCANDO ESPAÇO EM BRANCO POR + DO HASH
      while (
        userEmail.toString().includes(' ') ||
        userPassword.toString().includes(' ')
      ) {
        userEmail = userEmail.replace(' ', '+');
        userPassword = userPassword.replace(' ', '+');
      }

      userEmail = await CryptoJS.AES.decrypt(userEmail, 'userEmail'); // MÉTODO PARA DECRIPTAR string hasheada
      userPassword = await CryptoJS.AES.decrypt(userPassword, 'userPassword'); // MÉTODO PARA DECRIPTAR string hasheada

      userEmail = userEmail.toString(CryptoJS.enc.Utf8);
      userPassword = userPassword.toString(CryptoJS.enc.Utf8);

      if (userEmail && userPassword) {
        setEmail(userEmail);
        setPassword(userPassword);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') submit();
  };

  const verifyEmail = () => {
    if (
      email !== '' &&
      !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      toastr.error(
        'Email inválido',
        'O email informado é inválido. Insira um email válido e tente novamente.'
      );
    }
  };

  const submit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);

    try {
      const developerMode = email.indexOf('DEVOSD_') != -1;
      await signIn({
        email: email.replace('DEVOSD_', ''),
        password,
        developerMode,
      });
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 404) {
        toastr.error(
          'E-mail e/ou senha inválidos',
          'Verifique os dados informados e tente novamente. Em caso de dúvidas, informe o problema ao administrador do sistema de sua empresa.'
        );
        return;
      }

      if (err.response?.status === 405) {
        const { userId, token } = err.response.data.error;

        api.defaults.headers.common['x-access-token'] = token;
        setUserIdToOverwrite(userId);
        return setIsConfirmOverwriteUserSessionMoldalOpen(true);
      }

      toastr.error(
        'Falha ao logar no sistema',
        'Houve um problema ao tentar logar no sistema e já estamos verificando o que ocorreu. Tente novamente mais tarde.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleShowPassword = () => {
    if (eye === 'fa fa-eye') {
      setEye('fa fa-eye-slash');
    } else {
      setEye('fa fa-eye');
    }
  };

  async function handleOveriteUserSession() {
    await clearToken(userIdToOverwrite);
    submit();
    setIsConfirmOverwriteUserSessionMoldalOpen(false);
  }

  const {
    isWorkmotor,
    isTecnomotor,
    isBarros,
    isPO360,
    getMainLogoByBranding,
  } = useBranding();

  useEffect(() => {
    document.title = currentBrandingName + ' - Login';
  }, []);

  return (
    <div
      id="client-login-page"
      className={`
        ${isWorkmotor ? 'wm-bg-primary' : ''}
        ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
        ${isBarros ? 'barros-bg-primary' : ''}
        ${isPO360 ? 'po360-bg-primary' : ''}
      `}
    >
      <form onSubmit={submit} id="card-login">
        <header>
          <img src={getMainLogoByBranding()} alt={currentBrandingName} />
          <center>
            <p
              style={{
                fontSize: '14px',
                color: '#444',
                paddingTop: isTecnomotor || isBarros ? '10px' : '0',
              }}
            >
              Informe os seus dados abaixo:
            </p>
          </center>
        </header>

        <main>
          <fieldset>
            <p
              style={{
                fontSize: '14px',
                color: '#444',
                fontWeight: '600',
              }}
            >
              E-mail:
            </p>
            <input
              className="form-control foco-input"
              maxLength="50"
              placeholder="seu@gmail.com"
              type="text"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onBlur={verifyEmail}
            />
          </fieldset>

          <fieldset>
            <p
              style={{
                fontSize: '14px',
                color: '#444',
                fontWeight: '600',
              }}
            >
              Senha:
            </p>

            <div className="password-div">
              <input
                autoComplete="off"
                type={eye === 'fa fa-eye-slash' ? 'text' : 'password'}
                maxLength="18"
                className="form-control foco-input"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <span
                className={`fa fa-fw fa-eye ${eye}`}
                onClick={() => handleShowPassword()}
              />
            </div>
          </fieldset>
        </main>

        <footer style={{ width: '295px' }}>
          <button
            className="btn-link"
            type="button"
            onClick={() => history.push('EsqueciMinhaSenha')}
            style={{ marginBottom: '25px' }}
          >
            Esqueci a minha senha
          </button>

          <button
            className="btn btn-sucesso"
            type="submit"
            disabled={loading || !email || !password}
          >
            <span
              className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
              style={{ marginRight: loading ? '5px' : '' }}
            />
            Entrar
          </button>

          <button
            className="btn-link"
            type="button"
            onClick={() => history.push('ExperimenteGratis')}
            style={{ marginTop: '10px' }}
          >
            Não tem conta? Experimente grátis!
          </button>
        </footer>
      </form>

      <OverwriteUserSessionModal
        show={isConfirmOverwriteUserSessionMoldalOpen}
        onCancel={() => {
          api.defaults.headers.common['x-access-token'] = null;
          setIsConfirmOverwriteUserSessionMoldalOpen(false);
        }}
        onSubmit={handleOveriteUserSession}
      />
    </div>
  );
};

export default withRouter(LoginPage);
