import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import './styles.css'

const renderDragMessage = (isDragActive, isDragReject, label) => {
  if (!isDragActive) return <span>{label}</span>

  if (isDragReject) return <span style={{ color: 'red' }}>Arquivo não suportado</span>

  return <span style={{ color: 'green' }}>Arquivos suportados</span>
}

const UploadContainer = ({ getRootProps, getInputProps, isDragActive, isDragReject, image, onRemove, label }) => {
  return (
    <>
      {!image?.preview
        ? <div {...getRootProps()} className='container-include-image'>
          <FontAwesomeIcon icon={faPlusCircle} />
          <br />
          {renderDragMessage(isDragActive, isDragReject, label)}

          <input {...getInputProps()} />
        </div>

        : <div>
          <div className='container-presents-image' >
            <FontAwesomeIcon icon={faTimes} onClick={onRemove} />
            <img src={image?.preview} alt={image.name} />
          </div>

          <center>
            <button {...getRootProps()} type='button' className='btn-link'>
              <input {...getInputProps()} />
              Alterar
            </button>
          </center>

        </div>
      }
    </>
  )
}

export default UploadContainer;