import React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';

import { useAuth } from 'contexts/auth';

import { DefaultWorkshopPanel } from './Default';
import { AppointmentByEmployeePanel } from './AppointmentByEmployeePanel';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

export function WorkshopPanel() {
  const { company } = useAuth();
  const workShopPanelMode = useMemo(() => {
    return company?.companyConfig?.pointingByThePanel
      ? 'appointment-by-employee'
      : 'default';
  }, [company]);

  useEffect(() => {
    document.title = `${currentBrandingName} - Painel da Oficina`;
  }, []);

  if (!company)
    return (
      <div className="flex center w-100 h-100vh">
        <LoadingSpinnerFullHeight />
      </div>
    );

  if (workShopPanelMode === 'default') return <DefaultWorkshopPanel />;
  if (workShopPanelMode === 'appointment-by-employee')
    return <AppointmentByEmployeePanel />;

  return 'Modo inválido';
}
