import { onlyLetters } from 'client/components/ToNormalize/ToNormalize';
import { onlyNumbersLetters } from 'client/components/ToNormalize/ToNormalize';
import { milhar, onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
import Select from 'react-select';
import { Field } from 'redux-form';
import { yearMask } from 'utils/masks';
import '../styles.css';

const CarMotobikeTruckForm = ({
  loadingVehiclesByPlate,
  licensePlateMask,
  hasSale,
  loadVehicleByPlate,
  License_Plate,
  RenderField,
  brands,
  isVehicleByPlate,
  Type,
  Editing,
  vehiclesOptions,
  defaultModelIndex,
  selectedModel,
  modelInput,
  loadingBrandModels,
  handleModelChange,
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '600px',
          marginTop: 5,
        }}
      >
        <label>
          Placa: <span style={{ color: 'red' }}>*</span>
        </label>
        <Field
          name="License_Plate"
          required
          disabled={hasSale}
          component={RenderField}
          normalize={(value) => value.toUpperCase()}
          {...licensePlateMask}
          style={{ width: '212px' }}
        />
        <button
          className="btn btn-sucesso"
          type="button"
          onClick={loadVehicleByPlate}
          disabled={License_Plate.length < 7}
          style={{
            width: '240px',
            height: '35px',
            marginLeft: '15px',
            marginTop: '-2px',
          }}
        >
          <span
            className={`${
              loadingVehiclesByPlate ? 'fa fa-spinner fa-pulse fa-1x' : ''
            }`}
          />
          Buscar Dados do Veículo
        </button>
      </div>

      <div className="form-new-vehicle__row">
        <div>
          <label>
            Marca: <span style={{ color: 'red' }}>*</span>{' '}
          </label>

          <Field
            name="Brand"
            required
            as="select"
            component={RenderField}
            disabled={isVehicleByPlate && Type == 1}
          >
            <option value="">Selecione</option>
            {brands?.map((child) => (
              <option key={child.Brand} value={child.Brand}>
                {child.Brand.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 400 }}>
          <label>
            Modelo: <span style={{ color: 'red' }}>*</span>
          </label>
          {!Editing || (Editing && vehiclesOptions?.length !== 0) ? (
            <Select
              options={vehiclesOptions}
              defaultValue={vehiclesOptions[defaultModelIndex]?.value}
              value={selectedModel}
              placeholder="Selecione"
              openMenuOnFocus
              noOptionsMessage={() => 'Nenhum modelo encontrado'}
              ref={modelInput}
              isDisabled={loadingBrandModels}
              onChange={(model) => handleModelChange(model)}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '35px',
                  minHeight: '35px',
                  borderColor: '#E3E3E3',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  marginTop: '-6px',
                }),
              }}
            />
          ) : (
            ''
          )}
        </div>

        <div>
          <label>Renavam:</label>
          <Field
            name="Vin_Renavam"
            component={RenderField}
            style={{ width: '200px' }}
            normalize={(value) => onlyNumbers(value)}
          />
        </div>
      </div>

      <div className="form-new-vehicle__row">
        <div style={{ maxWidth: '120px' }}>
          <label htmlFor="year">
            Ano <strong>Fab.</strong>:<span style={{ color: 'red' }}>*</span>
          </label>
          <Field
            name="Year_Manufacture"
            component={RenderField}
            {...yearMask}
          />
        </div>

        <div style={{ maxWidth: '130px' }}>
          <label htmlFor="modelYear">
            Ano <strong>Mod.</strong>:<span style={{ color: 'red' }}>*</span>
          </label>
          <Field name="Year_Model" component={RenderField} {...yearMask} />
        </div>

        <div>
          <label>
            KM:<span style={{ color: 'red' }}>*</span>
          </label>
          <Field
            name="Kilometers"
            required
            component={RenderField}
            normalize={(value) => milhar(onlyNumbers(value))}
            maxLength={10}
          />
        </div>

        <div>
          <label>Cor:</label>
          <Field
            name="Color"
            component={RenderField}
            normalize={(value) => onlyLetters(value.toUpperCase())}
          />
        </div>

        <div>
          <label>Chassi/Série:</label>
          <Field
            name="NChassi"
            component={RenderField}
            normalize={(value) => onlyNumbersLetters(value.toUpperCase())}
          />
        </div>

        <div>
          <label>Combustível:</label>
          <Field
            label="Combustível"
            name="Fuel_Type"
            component="select"
            placeholder="Selecione"
            className="form-control foco-input"
          >
            <option value="">Selecione</option>
            <option value="Álcool">Álcool</option>
            <option value="Diesel">Diesel</option>
            <option value="Elétrico">Elétrico</option>
            <option value="Gás">Gás</option>
            <option value="Gasolina">Gasolina</option>
            <option value="Gasolina/Álcool">Gasolina/Álcool</option>
            <option value="Gasolina/Álcool/Gás">Gasolina/Álcool/Gás</option>
            <option value="Gasolina/Gás">Gasolina/Gás</option>
            <option value="Gás/Álcool">Gás/Álcool</option>
            <option value="Gasolina/Elétrico">
              Híbrido (Gasolina/Elétrico)
            </option>
            <option value="Outro">Outro</option>
          </Field>
        </div>
      </div>
    </>
  );
};

export default CarMotobikeTruckForm;
