import styled from 'styled-components';

export const Container = styled.div`
  background: #1ea14e;
  padding: 7.5px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;

  &span {
    margin-left: 12px;
    font-weight: bold;
  }

  &:hover {
    filter: brightness(90%);
  }

  &svg {
    font-size: 20px;
  }
`;
