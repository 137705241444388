import React from 'react';
import { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

import { useAuth } from '../../../../contexts/auth';

import billsToReceiveRepository from 'repositories/BillsToReceive';

import { cashierBankHistoricValidations } from 'client/components/BlockSalesModals/actions';
import { DebtLimitModalBlock } from 'client/components/DebtLimitModalBlock';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import ChangeModal from './ChangeModal';
import customerCreditRepository from 'repositories/CustomerCredit';
import { useBranding } from 'hooks/useBranding';
import { useBonus } from 'hooks/useBonus';
import { CRMBonusRepository } from 'repositories/CRMBonus';
import { useEffect } from 'react';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import { isDefaultCustomer } from 'utils/isDefaultCustomer';
import { GenerateTitleModalMessage } from 'client/views/Sales/NewSale/FormSale/SaveModal';
import AlertModal from 'components/AlertModal/AlertModal';
import { ModalChangeOperator } from 'v2/components/ChangeOperator';

const Footer = ({
  onSubmit,
  onOpenOrcaments,
  onOpenCashierBankHistoric,
  onOpenListNFCe,
  hasSettingsNFCe,
  hasCashierControl,
}) => {
  const {
    step,
    paidValue,
    cart,
    totalSale,
    customerId,
    isSubmitting,
    availableCredit,
    customerCreditPaymentValue,
    installments,
    hasCustomer,
    salesSubtypeDescription,
    client,
    generateTitleAfterCloseSale,
    neededToCreateCustomerCredit,
  } = useSelector((state) => state.form.pdvOnline.values);

  useEffect(() => {
    if (neededToCreateCustomerCredit) {
      handleCreateExchange();
    }
  }, [neededToCreateCustomerCredit]);
  const { isPlanFree, isPlanStart, isPlanBasic, isPaidWorkmotor } =
    usePlanSignatureContext();
  const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;

  const dispatch = useDispatch();

  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);

  const [debtLimitInfo, setDebtLimitInfo] = useState({});
  const [isDebtLimitBlockModalOpen, setIsDebtLimitBlockModalOpen] =
    useState(false);

  const [isGenerateTitleModalOpen, setIsGenerateTitleModalOpen] =
    useState(false);

  const [isGenerateExchange, setIsGenerateExchange] = useState(false);

  const [isChangeOperatorModalOpen, setIsChangeOperatorModalOpen] =
    useState(false);

  const { userName, company, companyId } = useAuth();

  const {
    companyHasCRMBonus,
    handleRedeemPDVBonusClick,
    crmcontinueWithoutBonusTrigger,
    savePDVTrigger,
    companyCRMBonusStoreCode,
    crmIdsBonus,
    crmBonusCustomerId,
    selectedCampaign,
    crmCustomerPIN,
    crmBonusCurrentCustomer,
    redeemedBonus,
    setBonusId,
    setOrderId,
    crmApplyBonusTrigger,
    handleOpenSelectCampaignModal,
    pdvValidatedCustomer,
    usedCRMBonus,
    consultedBonus,
  } = useBonus();

  const handleCreateExchange = async () => {
    if (
      !isDefaultCustomer(client, 'cpfCnpj') &&
      company?.companyConfig?.manageInternalCredit
    ) {
      await customerCreditRepository.create({
        customerId: customerId,
        value: paidValue - totalSale,
        type: 'Entrada',
        reason: 'Troco',
      });
    }
  };

  const handleSaveSaleWithBonus = async () => {
    try {
      const res = await CRMBonusRepository.checkout({
        storeCode: companyCRMBonusStoreCode,
        timeRestriction: false,
        useBonus: redeemedBonus > 0 ? true : false,
        idsBonus: usedCRMBonus ? crmIdsBonus || '' : '',
        crmCustomerId: crmBonusCustomerId,
        grossValue: totalSale + (redeemedBonus || 0),
        redeemedBonus: redeemedBonus || 0,
        campaignId: selectedCampaign.id,
        sms: crmCustomerPIN || '',
        isMasterPin: crmCustomerPIN === '2018',
        sellerName: userName,
        cellphone: onlyNumbers(crmBonusCurrentCustomer.Phones[0].Number_Phone2),
      });

      if (res.message !== 'sucesso')
        throw new Error(res.message || 'Falha ao relizar checkout');
      dispatch(change('pdvOnline', 'bonusId', res.bonusId));
      dispatch(change('pdvOnline', 'orderId', res.orderId));
    } catch (err) {
      console.log(err);
      toastr.error(
        'Houve um erro na comunicação com a CRM Bônus',
        'Por gentileza, verifique com o suporte da CRM o lançamento manual do bônus para o cliente. Caso o problema persista, entre em contato com o atendimento'
      );
    }
  };

  async function handleCloseSale(skipPaymentValidation) {
    dispatch(change('pdvOnline', 'isSubmitting', true));

    if (!skipPaymentValidation) {
      const bonus = companyHasCRMBonus && redeemedBonus ? redeemedBonus : 0;

      if (
        step === 2 &&
        Number(paidValue.toFixed(2)) < Number(totalSale.toFixed(2)) - bonus
      ) {
        dispatch(change('pdvOnline', 'isSubmitting', false));
        return toastr.error(
          'Insira valores de pagamento igual ou maior que o valor total para prosseguir.'
        );
      }

      const installmentWithCustomerCredit = installments.filter(
        (installment) => installment.isCustomerCreditPayment
      );

      const hasCustomerCreditPayment = installmentWithCustomerCredit.length > 0;

      if (hasCustomerCreditPayment) {
        if (
          !hasCustomer &&
          isPaidWorkmotor &&
          company?.companyConfig?.manageInternalCredit
        ) {
          dispatch(change('pdvOnline', 'isSubmitting', false));
          return toastr.warning(
            'Importante',
            'Cliente Consumidor não trabalha com crédito da loja. Faça a identificação do cliente e tente novamente.'
          );
        }

        const customerCreditPaymentValue = installmentWithCustomerCredit.reduce(
          (prev, curr) => prev + Number(curr.value),
          0
        );

        if (
          customerCreditPaymentValue > availableCredit &&
          hasCustomer &&
          isPaidWorkmotor &&
          company?.companyConfig?.manageInternalCredit
        ) {
          dispatch(change('pdvOnline', 'isSubmitting', false));
          return toastr.warning(
            'Saldo insuficiente de crédito.',
            'O total de crédito disponível é insuficiente para os valores das parcelas selecionados. Verifique e tente novamente.'
          );
        }

        await dispatch(
          change(
            'pdvOnline',
            'customerCreditPaymentValue',
            customerCreditPaymentValue
          )
        );
      }

      if (company?.hasCashierControl) {
        const isValid = await dispatch(
          cashierBankHistoricValidations(companyId)
        );
        if (!isValid.payload) return;
        dispatch(change('pdvOnline', 'isSubmitting', false));
      }
      if (step === 1) {
        const isValid = await isDebtLimitExceeded();
        if (isValid) {
          setIsDebtLimitBlockModalOpen(true);
          return;
        }

        dispatch(change('pdvOnline', 'step', 2));
        dispatch(change('pdvOnline', 'isSubmitting', false));
        dispatch(change('pdvOnline', 'typeBtn', 'submit'));
        return;
      }
    }

    dispatch(change('pdvOnline', 'isSubmitting', true));
    dispatch(change('pdvOnline', 'typeBtn', 'submit'));
    onSubmit();
  }

  async function isDebtLimitExceeded() {
    dispatch(change('pdvOnline', 'isSubmitting', true));
    try {
      const debtLimitInfo =
        await billsToReceiveRepository.getCustomerDebtLimitInfo(customerId);

      let isExceeded =
        debtLimitInfo?.debtLimit !== 0 && debtLimitInfo?.isDebtLimitExceeded;

      setDebtLimitInfo(debtLimitInfo);
      return isExceeded;
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(change('pdvOnline', 'isSubmitting', false));
    }
  }

  function handleClickSubmit() {
    if (generateTitleAfterCloseSale) {
      if (
        !crmApplyBonusTrigger &&
        !crmcontinueWithoutBonusTrigger &&
        companyHasCRMBonus
      ) {
        handleRedeemPDVBonusClick({
          liquidValue: totalSale,
          customer: client,
        });
      }
      if (
        !companyHasCRMBonus ||
        (companyHasCRMBonus &&
          (crmApplyBonusTrigger || crmcontinueWithoutBonusTrigger) &&
          step !== 2) ||
        (companyHasCRMBonus &&
          crmcontinueWithoutBonusTrigger &&
          !consultedBonus)
      ) {
        handleOnClickCloseSale();
      }

      if (step === 2 && crmApplyBonusTrigger && companyHasCRMBonus) {
        handleOpenSelectCampaignModal();
        return;
      }
      if (step === 2 && consultedBonus && companyHasCRMBonus) {
        handleOpenSelectCampaignModal();
        return;
      }
    }

    if (step === 2 && !generateTitleAfterCloseSale) {
      setIsGenerateTitleModalOpen(true);
    }
  }

  function handleSubmitGenerateTitleModal() {
    dispatch(change('pdvOnline', 'paymentConditionId', ''));
    dispatch(change('pdvOnline', 'installments', []));
    setIsGenerateTitleModalOpen(false);
    handleCloseSale(true);
  }

  const GenerateTitleModalMessage = () => {
    return (
      <div className="flex column gap-075">
        <span className="typography__text-1">
          Você tem certeza que deseja realizar o fechamento desse venda/os com o
          tipo {salesSubtypeDescription}?
        </span>
        <span className="typography__text-1">
          Ao fechar, o estoque será movimentado caso haja itens, mas não será
          gerado nenhum título.
        </span>

        <span className="typography__caption text-red text-center">
          O processo é irreversível. Uma vez fechada, não poderá ser reaberta
          posteriormente.
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (
      (companyHasCRMBonus &&
        (crmApplyBonusTrigger || crmcontinueWithoutBonusTrigger) &&
        step !== 2) ||
      (companyHasCRMBonus && crmcontinueWithoutBonusTrigger)
    ) {
      handleOnClickCloseSale();
    }
  }, [crmApplyBonusTrigger]);

  useEffect(() => {
    if (
      (companyHasCRMBonus &&
        (crmApplyBonusTrigger || crmcontinueWithoutBonusTrigger) &&
        step !== 2) ||
      (companyHasCRMBonus && crmcontinueWithoutBonusTrigger)
    ) {
      handleOnClickCloseSale();
    }
  }, [crmcontinueWithoutBonusTrigger]);

  useEffect(() => {
    if (savePDVTrigger) {
      handleSavePDVTrigger();
    }
  }, [savePDVTrigger]);

  const handleSavePDVTrigger = async () => {
    await handleSaveSaleWithBonus();
    handleOnClickCloseSale();
  };

  useEffect(() => {
    if (usedCRMBonus) {
      dispatch(change('pdvOnline', 'usedCRMBonus', true));
    }
    if (redeemedBonus > 0) {
      dispatch(change('pdvOnline', 'redeemedBonus', redeemedBonus));
    }
  }, [crmApplyBonusTrigger, redeemedBonus, usedCRMBonus]);

  useEffect(() => {
    const client = {
      name: crmBonusCurrentCustomer.Company_Name,
      cpfCnpj: crmBonusCurrentCustomer.Cpf_Cnpj,
      email: crmBonusCurrentCustomer.Email,
    };
    dispatch(change('pdvOnline', 'client', client));
    dispatch(change('pdvOnline', 'customerId', crmBonusCurrentCustomer.id));
  }, [pdvValidatedCustomer]);

  useEffect(() => {
    dispatch(
      change('pdvOnline', 'totalSaleWithBonus', totalSale - redeemedBonus)
    );
  }, [redeemedBonus]);

  function handleOnClickCloseSale() {
    if (
      paidValue > totalSale &&
      hasCustomer &&
      isPlanPrime &&
      company?.companyConfig?.manageInternalCredit
    ) {
      setIsChangeModalOpen(true);
      return;
    }
    handleCloseSale();
  }

  function handleGoBack() {
    dispatch([
      change('pdvOnline', 'step', 1),
      change('pdvOnline', 'installments', []),
      change('pdvOnline', 'paymentConditionId', null),
      change('pdvOnline', 'typeBtn', null),
    ]);
  }

  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();

  return (
    <>
      <div id="pdv-footer">
        <div
          className={`container-user
            ${isWorkmotor ? 'wm-bg-primary' : ''}
            ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
            ${isBarros ? 'barros-bg-primary' : ''}
            ${isPO360 ? 'po360-bg-primary' : ''}
          `}
        >
          <span>Operador</span>
          <p>{userName}</p>

          <button
            style={{ fontSize: '15px', color: '#fff' }}
            className="btn-link"
            onClick={() => setIsChangeOperatorModalOpen(true)}
          >
            <strong>Alterar Operador</strong>
          </button>
        </div>

        <section>
          {step === 1 ? (
            <>
              {!!hasCashierControl && (
                <button className="cashier" onClick={onOpenCashierBankHistoric}>
                  Caixa
                </button>
              )}
              {!!hasSettingsNFCe && (
                <button className="info" onClick={onOpenListNFCe}>
                  Listagem de NFC-e/SAT
                </button>
              )}
              <button className="warning" onClick={onOpenOrcaments}>
                Recebimentos
              </button>
              <button
                disabled={!cart.length}
                onClick={onSubmit}
                className="danger"
              >
                Cancelar Venda Atual
              </button>
            </>
          ) : (
            <button className="danger back-button" onClick={handleGoBack}>
              Voltar
            </button>
          )}
          <button
            disabled={
              (step === 2 && generateTitleAfterCloseSale && paidValue === 0) ||
              !cart.length ||
              isSubmitting
            }
            className="success"
            onClick={handleClickSubmit}
          >
            <span
              className={isSubmitting ? 'fa fa-spinner fa-pulse fa-1x' : ''}
            />{' '}
            &nbsp; Fechar Venda
          </button>
        </section>
      </div>

      <DebtLimitModalBlock
        show={isDebtLimitBlockModalOpen}
        setShow={setIsDebtLimitBlockModalOpen}
        info={debtLimitInfo}
      />

      <ChangeModal
        show={isChangeModalOpen}
        setShow={setIsChangeModalOpen}
        handleSubmit={handleCloseSale}
      />

      <AlertModal
        show={isGenerateTitleModalOpen}
        onHide={() => setIsGenerateTitleModalOpen(false)}
        onCancel={() => setIsGenerateTitleModalOpen(false)}
        onSubmit={(e) => handleSubmitGenerateTitleModal()}
        title="Fechamento de Venda/OS"
        message={<GenerateTitleModalMessage />}
      />

      {isChangeOperatorModalOpen && (
        <ModalChangeOperator
          open={isChangeOperatorModalOpen}
          onClose={() => setIsChangeOperatorModalOpen(false)}
        />
      )}
    </>
  );
};

export default Footer;
