import React, { useEffect, useState } from 'react';
import RenderField from 'components/RenderField';
import { useModulesContext } from '../../../../../contexts/modules';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { handleChange } from './redux/actions';
import { useSelector, useDispatch } from 'react-redux';

import salesRepository from '../../../../../repositories/Sales';
import companyConfigRepository from '../../../../../repositories/CompanyConfig';

import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';

function CheckboxOSPanel({ vehicles, saleTypeId, items, saleId }) {
  const [hasVehicle, setHasVehicle] = useState(true);

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const dispatch = useDispatch();
  const { companyId } = useAuth();

  const { showOnPanel, saleStatusId } = useSelector(
    (state) => state.saleReducer
  );

  const { isWorkshopPanelModuleActive, isWorkshopPanelConfigActive } =
    useModulesContext();

  const handleCheckboxChange = async (value) => {
    dispatch(handleChange(value, 'showOnPanel'));
  };

  useEffect(() => {
    if (vehicles) {
      if (Object.keys(vehicles).length === 0) {
        setHasVehicle(false);
      } else {
        setHasVehicle(true);
      }
    }
  }, [vehicles]);

  let hasService = items?.length
    ? items.filter((item) => item.Type === 'Serviço').length > 0
      ? true
      : false
    : false;

  if (!hasVehicle && hasService) {
    return null;
  }

  return (
    <>
      {!isPlanFree &&
      !isPlanStart &&
      isWorkshopPanelModuleActive &&
      isWorkshopPanelConfigActive ? (
        <div
          className="form-check"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <label className="form-check-label">Exibir no Painel</label>
          <input
            className="form-check-input"
            type="checkbox"
            name="showOnPanel"
            disabled={
              !vehicles?.Model ||
              !hasService ||
              (saleTypeId !== 3 && saleId) ||
              (saleStatusId === 5 && showOnPanel === false)
                ? true
                : false
            }
            checked={showOnPanel}
            onChange={(e) => handleCheckboxChange(!showOnPanel)}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default CheckboxOSPanel;
