import api from '../services/api';

export const create = async (saleData) => {
  const { data } = await api.post('sales', saleData);
  return data;
};

export const update = async (id, saleData) => {
  const { data } = await api.put('sales/' + id, saleData);
  return data;
};

export const verifyCRMBonusByCustomer = async (customerId) => {
  const { data } = await api.get('sales/' + customerId);
  return data;
};

const SaleRepository = {
  create,
  update,
  verifyCRMBonusByCustomer,
};

export default SaleRepository;
