import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import { isEqual } from 'date-fns';
import DatePicker from 'react-datepicker';
import { toastr } from 'react-redux-toastr';

import 'react-datepicker/dist/react-datepicker.css';

import RenderField from 'components/RenderField';
import {
  cnpjMask,
  onlyNumbers,
  maxLength,
  dosentAllowSpaces,
} from 'client/components/ToNormalize/ToNormalize';
import providerRepository from '../../../../repositories/Providers';
import cashierBankRepository from '../../../../repositories/CashierBank';
import accountPlansRepository from 'repositories/AccountPlans';
import constants from '../../../../utils/constants';
import { useAuth } from 'contexts/auth';
import NewProviderHyperlinkWithFieldLabel from 'client/components/NewProviderHyperlinkWithFieldLabel';
import Purchases from 'repositories/Purchases';

const Title = ({ titleId, isPurchase }) => {
  const [provider, setProvider] = useState([]);
  const [providerNameSuggestions, setProviderNameSuggestions] = useState([]);
  const [cashierBank, setcashierBank] = useState([]);
  const [accountPlans, setAccountPlans] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const {
    providerId,
    searchProvider,
    entryNumber,
    billStatusId,
    issueDate,
    dueDate,
    invoice,
    isGrouped,
  } = useSelector((state) => state.form.billToPay.values);
  const dispatch = useDispatch();
  const { companyId } = useAuth();

  const currencyMask = createNumberMask({
    prefix: 'R$ ',
    decimalPlaces: 2,
  });

  const percentMask = createNumberMask({
    suffix: ' %',
    decimalPlaces: 2,
  });

  useEffect(() => {
    loadActiveProvider();
    loadAccountPlans();
    cashierBankRepository
      .getAllByCompanyActiveSortedByDescription(companyId)
      .then((resp) => setcashierBank(resp));
  }, []);

  async function loadActiveProvider() {
    try {
      const providers = await providerRepository.getAllActiveByCompany(
        companyId
      );

      const providerNameSuggestions = providers.map((provider) => {
        return `${cnpjMask(provider.cpfCnpj)} | ${provider.companyName} | ${
          provider.tradingName
        }`;
      });

      setProvider(providers);
      setProviderNameSuggestions(providerNameSuggestions);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os fornecedores. Por favor, tente novamente.'
      );
    }
  }

  async function loadAccountPlans() {
    try {
      const { data } = await accountPlansRepository.index({
        companyId,
        type: 'Débito',
        isActive: 1,
      });

      setAccountPlans(data);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar o Plano de Contas. Por favor, tente novamente.'
      );
    }
  }
  useEffect(() => {
    let providerCpfCnpj = searchProvider.split('|')[0];
    let providerName = searchProvider.split('|')[1];

    if (!!providerCpfCnpj) {
      providerCpfCnpj = onlyNumbers(providerCpfCnpj.trim());

      if (!!providerCpfCnpj) {
        getProviderFromCpfCnpj(providerCpfCnpj);
      } else {
        if (!!providerName) {
          getProviderFromName(providerName.trim());
        }
      }
    }
  }, [searchProvider]);

  function getProviderFromCpfCnpj(providerCpfCnpj) {
    const providers = provider.find(
      (provider) => provider.cpfCnpj === providerCpfCnpj
    );

    if (!!providers) {
      dispatch([
        change('billToPay', 'providerId', providers.id),
        change('billToPay', 'searchProviderName', providers.companyName),
      ]);
    }
  }

  function getProviderFromName(providerName) {
    const providers = provider.find(
      (provider) => provider.companyName === providerName
    );

    if (!!providers) {
      dispatch([
        change('billToPay', 'providerId', providers.id),
        change('billToPay', 'searchProviderName', providers.companyName),
      ]);
    }
  }

  function handleRemoveClient() {
    dispatch([
      change('billToPay', 'providerId', null),
      change('billToPay', 'searchProviderName', null),
      change('billToPay', 'searchProvider', ''),
    ]);
  }

  async function handleSelectCreatedProvider(provider) {
    if (titleId) return;
    await loadActiveProvider();
    dispatch(
      change(
        'billToPay',
        'searchProvider',
        `${cnpjMask(provider.cpfCnpj)} | ${provider.companyName} | ${
          provider.tradingName
        }`
      )
    );
  }

  const isClosedOrCanceled =
    billStatusId === constants.BILLS_STATUS.CLOSED ||
    billStatusId === constants.BILLS_STATUS.CANCELED;
  const isOpen = billStatusId === constants.BILLS_STATUS.OPEN;

  return (
    <>
      <Row className="row-min-margin">
        <Col xs={12} sm={12} md={2} lg={2} className="date-field">
          <Field
            name="dueDate"
            component={RenderField}
            label="Vencimento:"
            required
            type="date"
            disabled={isClosedOrCanceled}
          />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1} className="price-field">
          <Field
            name="amount"
            component={RenderField}
            label="Valor:"
            required
            {...currencyMask}
            disabled={
              (isOpen && entryNumber) || isClosedOrCanceled || isGrouped
            }
          />
        </Col>
        <Col xs={12} sm={12} md={3} lg={3}>
          <NewProviderHyperlinkWithFieldLabel
            label="Fornecedor"
            fieldId="searchProvider"
            handleSelectCreatedProvider={handleSelectCreatedProvider}
            required
            disabled={isGrouped}
            onlyView={titleId ? true : false}
            providerId={titleId ? providerId : null}
          />
          {!providerId ? (
            <>
              <Field
                name="searchProvider"
                component={RenderField}
                placeholder="Pesquisa por CNPJ/CPF, Razão Social ou Nome Fantasia"
                required
                list="providerSuggestions"
              />
              <datalist id="providerSuggestions">
                {providerNameSuggestions.map((provider) => (
                  <option key={provider} value={provider}></option>
                ))}
              </datalist>
            </>
          ) : (
            <Field
              name="searchProviderName"
              className="selected-provider-field"
              component={RenderField}
              onClick={handleRemoveClient}
              required
              disabled={
                (isOpen && entryNumber) || isClosedOrCanceled || isGrouped
              }
            />
          )}
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} className="date-field">
          <Field
            name="issueDate"
            component={RenderField}
            label="Dt. Criação do Título:"
            type="date"
            max={new Date().toISOString().split('T')[0]}
            disabled={isClosedOrCanceled}
          />
        </Col>
        {isGrouped && (
          <Col xs={12} sm={12} md={2} lg={2} className="date-field">
            <Field
              name="dischargeDate"
              component={RenderField}
              label="Data de Baixa:"
              type="date"
              disabled
            />
          </Col>
        )}
        {!isGrouped && (
          <>
            <Col xs={12} sm={12} md={4} lg={4} className="entry-number-field">
              <Field
                name="entryNumber"
                component={RenderField}
                label="Nº Entrada:"
                disabled
              />
            </Col>
            <Col xs={12} sm={12} md={1} lg={1}>
              <Field
                name="parcelNumber"
                component={RenderField}
                label="Parcela:"
                disabled
              />
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="date-field">
              <Field
                name="entryDate"
                component={RenderField}
                label="Dt. Entrada:"
                type="date"
                disabled
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        {!isGrouped && (
          <Col xs={12} sm={12} md={4} lg={4} className="entry-number-field">
            <Field
              name="invoice"
              component={RenderField}
              label="Nota:"
              normalize={(value) => maxLength(dosentAllowSpaces(value), 9)}
              disabled={isPurchase || isClosedOrCanceled}
            />
          </Col>
        )}
        <Col xs={12} sm={12} md={1} lg={1} className="price-field">
          <Field
            name="addition"
            component={RenderField}
            label="Acréscimo:"
            {...currencyMask}
            disabled={isClosedOrCanceled}
          />
        </Col>

        <Col xs={12} sm={12} md={1} lg={1} className="price-field">
          <Field
            name="discount"
            component={RenderField}
            label="Desconto:"
            {...currencyMask}
            disabled={isClosedOrCanceled}
          />
        </Col>
        {isGrouped && (
          <>
            <Col xs={12} sm={6} md={2} lg={2}>
              <Field
                label="Caixa/Banco:"
                name="cashierBankId"
                component={RenderField}
                as="select"
                required
                disabled={isClosedOrCanceled}
              >
                <option value="">Selecione</option>
                {cashierBank.map(
                  ({ id, description, CashierBankType: { initials } }) => (
                    <option key={id} value={id}>
                      {initials} - {description}
                    </option>
                  )
                )}
              </Field>
            </Col>
            <Col xs={12} sm={6} md={2} lg={2}>
              <Field
                label="Plano de Conta:"
                name="accountPlanId"
                component={RenderField}
                as="select"
                required
                disabled={isClosedOrCanceled}
              >
                <option value="">Selecione</option>
                {accountPlans.map(({ id, description }) => (
                  <option key={id} value={id}>
                    {description}
                  </option>
                ))}
              </Field>
            </Col>
          </>
        )}
        {!isGrouped && (
          <Col xs={12} sm={12} md={2} lg={2} className="date-field">
            <Field
              name="dischargeDate"
              component={RenderField}
              label="Data de Baixa:"
              type="date"
              disabled
            />
          </Col>
        )}
        <Col xs={12} sm={12} md={4} lg={4}>
          <Field
            name="observations"
            component={RenderField}
            label="Observações:"
            as="textarea"
            maxLength={100}
            disabled={isClosedOrCanceled}
          />
        </Col>

        <Row className="row-min-margin">
          <Col xs={12} sm={12} md={12} lg={12}>
            {!isGrouped && (
              <>
                <Col xs={12} sm={6} md={2} lg={2}>
                  <Field
                    label="Caixa/Banco:"
                    name="cashierBankId"
                    component={RenderField}
                    as="select"
                    required
                    disabled={isClosedOrCanceled}
                  >
                    <option value="">Selecione</option>
                    {cashierBank.map(
                      ({ id, description, CashierBankType: { initials } }) => (
                        <option key={id} value={id}>
                          {initials} - {description}
                        </option>
                      )
                    )}
                  </Field>
                </Col>
                <Col xs={12} sm={6} md={2} lg={2}>
                  <Field
                    label="Plano de Conta:"
                    name="accountPlanId"
                    component={RenderField}
                    as="select"
                    required
                    disabled={isClosedOrCanceled}
                  >
                    <option value="">Selecione</option>
                    {accountPlans.map(({ id, description }) => (
                      <option key={id} value={id}>
                        {description}
                      </option>
                    ))}
                  </Field>
                </Col>
              </>
            )}
            {isGrouped && (
              <Col xs={12} sm={6} md={4} lg={4}>
                <Field
                  label="Compras"
                  name="linkedPurchases"
                  disabled={true}
                  component={RenderField}
                />
              </Col>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default Title;
