export const themeBarros = {
  colors: {
    bannerText: '#444444',
    buttonGradient100: '#6D6D6D',
    buttonGradient200: '#595959',
    buttonGradient300: '#474747',
    branding100: '#E5E5E5',
    branding200: '#CCCCCC',
    branding300: '#B2B2B2',
    branding400: '#999999',
    branding500: '#7F7F7F',
    branding600: '#666666',
    branding700: '#4D4D4D',
    branding800: '#333333',
    branding900: '#4D4D4D',
    branding1000: '#444444',
  },
};
