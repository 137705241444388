import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';

import api from '../../services/api';
import config from '../../config';
import { useAuth } from '../../contexts/auth';
import conpass from '../../services/conpass';

import TryItFreeForm from './TryItFreeForm';
import { useBranding } from 'hooks/useBranding';
import { useEffect } from 'react';
import { currentBrandingId } from 'v2/helpers/brandingHelpers';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

const TryForFree = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const { isWorkmotor } = useBranding();

  const handleSubmit = async (values) => {
    if (values.Name.length < 2) {
      return;
    }
    if (values.Password < 6) {
      return;
    }

    setLoading(true);

    try {
      const {
        data: { user },
      } = await api.post(config.endpoint + 'users/tryitforfree', {
        userInfo: {
          Name: values.Name,
          Email: values.Email.toLowerCase(),
          Phone: values.Phone.replace(/\D/g, ''),
          User_Password: values.Password,
          branchId: values.branchId,
        },
        brandingId: currentBrandingId,
        location: window.location.origin,
      });

      await signIn({
        email: user.Email,
        password: user.User_Password,
      });

      conpass.init(user.Name, user.Email);
      history.push('/client/home');
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      return toastr.error(err.response?.data?.message || 'Erro inesperado');
    }
  };

  useEffect(() => {
    document.title = currentBrandingName + ' - Experimente Grátis';
  }, []);

  return (
    <body className="bg-expGratis">
      <TryItFreeForm onSubmit={handleSubmit} loading={loading} />
    </body>
  );
};

export default withRouter(TryForFree);
