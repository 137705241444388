import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { withRouter } from 'react-router-dom';
import {
  onlyNumbers,
  kmMask,
  description,
} from 'client/components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import api from 'services/api';
import config from 'config';
import { change } from 'redux-form';
import constants from '../../../../utils/constants';
import { useBranding } from 'hooks/useBranding';

class FormRevisao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      description: '',
      km: '',
      months: '',
      comments: '',
      showAlertCompany: false,
      companyId: 0,
      reviewId: '',
      breadcrumbDescription: '',
      loading: false,
      disabled: false,
    };
  }

  componentDidMount() {
    this.descriptionInput.focus();
    this.checkCompanyId(localStorage.getItem('ID_USUARIO'));
  }

  loadReview = async () => {
    var _this = this;
    await api
      .get(config.endpoint + `review/${this.props.ReviewId}`)
      .then((resp) => {
        _this.setState({
          code: resp.data.Code,
          description: resp.data.Description,
          km: kmMask(resp.data.Km?.toString()),
          months: resp.data.Months,
          comments: resp.data.Comments,
          reviewId: resp.data.id,
          breadcrumbDescription: resp.data.Description,
        });
      });
  };

  checkCompanyId = async (User_id) => {
    var _this = this;
    const res = await api.get(config.endpoint + `companyuser/${User_id}`);
    if (res.data.status) {
      _this.setState({
        showAlertCompany: res.data.data.Company_id ? false : true,
        companyId: res.data.data.Company_id,
      });
    } else {
      setTimeout(() => _this.setState({ showAlertCompany: true }), 500);
    }

    if (!this.props.modal) {
      this.props.newReview ? this.loadLastCode() : this.loadReview();
    } else {
      this.loadLastCode();
    }
  };

  loadLastCode = async () => {
    var _this = this;
    await api
      .get(config.endpoint + `review/LastOne/${this.state.companyId}`)
      .then((resp) => {
        if (resp.data.status) {
          _this.setState({ code: parseInt(resp.data.data) + 1 });
        } else {
          _this.setState({ code: 1 });
        }
      });
  };

  handleChange(event) {
    let value = event.target.value;
    if (
      event.target.name === 'description' ||
      event.target.name === 'comments'
    ) {
      value = description(event.target.value);
      if (event.target.name === 'description') {
        this.setState({ description: value.toUpperCase() });
      } else {
        this.setState({ comments: value });
      }
    } else if (event.target.name === 'months') {
      value = onlyNumbers(event.target.value);
      this.setState({ [event.target.name]: value });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    var self = this.state;
    var _this = this;
    await _this.setState({
      disabled: !_this.state.disabled,
      loading: !_this.state.loading,
    });
    let stopMethod = 0;
    if (self.description === '') {
      await _this.setState({
        disabled: !_this.state.disabled,
        loading: !_this.state.loading,
      });
      return toastr.warning('Por favor, informe a  Descrição da revisão');
    }

    if (
      (self.km === '' || self.km == 0) &&
      (self.months === '' || self.months == 0)
    ) {
      await _this.setState({
        disabled: !_this.state.disabled,
        loading: !_this.state.loading,
      });
      return toastr.warning(
        'Não foi possível salvar!',
        'É obrigatório o preenchimento do meses ou KM para salvar. Ambos poderão ser preenchidos.'
      );
    }
    if (self.months > 99) {
      await _this.setState({
        disabled: !_this.state.disabled,
        loading: !_this.state.loading,
      });
      return toastr.warning("Número inválido no campo 'Meses'");
    }

    this.loadLastCode();

    let description = self.description.trim();

    await api.get(config.endpoint + 'review').then(async (resp) => {
      for (let i = 0; i < resp.data.length; i++) {
        if (
          resp.data[i].Description == description &&
          resp.data[i].Company_id == self.companyId
        ) {
          stopMethod = 1;
          await _this.setState({
            disabled: !_this.state.disabled,
            loading: !_this.state.loading,
          });
          return toastr.warning('Revisão já existente');
        }
      }
    });

    if (stopMethod != 0) return;

    await api
      .post(config.endpoint + `review/${self.companyId}`, {
        Description: description,
        Km: self.km.replace(/[^\d]/g, ''),
        Months: self.months,
        Comments: self.comments,
        Code: self.code,
      })
      .then(async (resp) => {
        if (resp.data.status) {
          toastr.success('Sucesso', `Revisão cadastrada com sucesso`);
          if (!_this.props.modal) {
            _this.setState({
              code: '',
              description: '',
              km: '',
              months: '',
              comments: '',
              loading: false,
              disabled: false,
            });
            _this.loadLastCode();
            _this.descriptionInput.focus();
          } else {
            switch (_this.props.Calling) {
              case 'Cadastro Produto':
                _this.props.dispatch(
                  change('formProduto', 'Review', resp.data.data.id)
                );
                return _this.props.handleModal('review');
              case 'Cadastro Familia':
                return _this.props.handleModal('review', resp.data.data.id);
              case 'Cadastro Serviço':
                return _this.props.handleModal('review', resp.data.data.id);
            }
          }
          return this.props.history.push(constants.ROUTES.REVISIONS);
        } else {
          console.log(resp.data.message);
          await _this.setState({
            disabled: !_this.state.disabled,
            loading: !_this.state.loading,
          });
          return toastr.error(
            'Ocorreu algum problema',
            'Por favor, tente novamente'
          );
        }
      })
      .catch(async (e) => {
        console.log(e);
        await _this.setState({
          disabled: !_this.state.disabled,
          loading: !_this.state.loading,
        });
        return toastr.error(
          'Ocorreu algum problema',
          'Por favor, tente novamente'
        );
      });
  };

  attReview = async (event) => {
    event.preventDefault();
    var self = this.state;
    var _this = this;
    await _this.setState({
      disabled: !_this.state.disabled,
      loading: !_this.state.loading,
    });
    let stopMethod = 0;
    if (self.description === '') {
      await _this.setState({
        disabled: !_this.state.disabled,
        loading: !_this.state.loading,
      });
      return toastr.warning('Por favor, informe a  Descrição da revisão');
    }

    if (
      (self.km === '' || self.km == 0) &&
      (self.months === '' || self.months == 0)
    ) {
      await _this.setState({
        disabled: !_this.state.disabled,
        loading: !_this.state.loading,
      });
      return toastr.warning(
        'Não foi possível salvar!',
        'É obrigatório o preenchimento do meses ou KM para salvar. Ambos poderão ser preenchidos.'
      );
    }
    if (self.months > 99) {
      await _this.setState({
        disabled: !_this.state.disabled,
        loading: !_this.state.loading,
      });
      return toastr.warning("Número inválido no campo 'Meses'");
    }
    let description = self.description.trim();

    await api.get(config.endpoint + 'review').then(async (resp) => {
      for (let i = 0; i < resp.data.length; i++) {
        if (
          resp.data[i].Description == description &&
          resp.data[i].Company_id == self.companyId
        ) {
          if (resp.data[i].id !== self.reviewId) {
            stopMethod = 1;
            await _this.setState({
              disabled: !_this.state.disabled,
              loading: !_this.state.loading,
            });
            return toastr.warning('Revisão já existente');
          }
        }
      }
    });

    if (stopMethod != 0) return;

    await api
      .put(config.endpoint + `review/${self.reviewId}`, {
        Description: description,
        Km: self.km.toString().replace(/[^\d]/g, ''),
        Months: self.months,
        Comments: self.comments,
        Code: self.code,
      })
      .then(async (resp) => {
        if (resp.data.status) {
          await _this.setState({
            disabled: !_this.state.disabled,
            loading: !_this.state.loading,
          });

          this.props.history.push(constants.ROUTES.REVISIONS);
          return toastr.success('Sucesso', `Revisão atualizada com sucesso`);
        } else {
          console.log(resp.data.message);
          await _this.setState({
            disabled: !_this.state.disabled,
            loading: !_this.state.loading,
          });
          return toastr.error(
            'Ocorreu algum problema',
            'Por favor, tente novamente'
          );
        }
      })
      .catch(async (e) => {
        console.log(e);
        await _this.setState({
          disabled: !_this.state.disabled,
          loading: !_this.state.loading,
        });
        return toastr.error(
          'Ocorreu algum problema',
          'Por favor, tente novamente'
        );
      });
  };

  render() {
    const {
      showAlertCompany,
      reviewId,
      code,
      description,
      km,
      months,
      comments,
      breadcrumbDescription,
    } = this.state;

    const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();

    return (
      <Card
        content={
          <form
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            style={{
              marginBottom: '10px',
              marginLeft: '-10px',
              marginTop: '15px',
            }}
          >
            <div className="cardComponent row">
              <div className="div-card">
                <Button
                  id="btn-components"
                  className={`
                    ${isWorkmotor ? 'wm-bg-primary' : ''}
                    ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
                    ${isBarros ? 'barros-bg-primary' : ''}
                    ${isPO360 ? 'po360-bg-primary' : ''}
                  `}
                  onClick={(e) => e.preventDefault()}
                >
                  <a id="btn-a">Dados da Revisão</a>
                </Button>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 div-card-body">
                <Col xs={12} sm={3} md={2} lg={2}>
                  <label>Código</label>
                  <input
                    disabled={true}
                    onChange={(event) => event.preventDefault()}
                    style={{ textAlign: 'right' }}
                    className="form-control foco-input"
                    name="code"
                    value={code}
                  />
                </Col>
                <Col xs={12} sm={9} md={6} lg={7}>
                  <label>Descrição</label>
                  <input
                    ref={(input) => {
                      this.descriptionInput = input;
                    }}
                    maxLength="40"
                    className="form-control foco-input"
                    onChange={(event) => this.handleChange(event)}
                    name="description"
                    value={description}
                  />
                </Col>
                <Col xs={6} sm={6} md={2} lg={2}>
                  <label>KM</label>
                  <input
                    className="form-control foco-input"
                    onChange={(event) =>
                      this.setState({
                        km: kmMask(onlyNumbers(event.target.value)),
                      })
                    }
                    maxLength="7"
                    name="km"
                    value={km}
                  />
                </Col>
                <Col xs={6} sm={6} md={2} lg={1}>
                  <label>Meses</label>
                  <input
                    maxLength="2"
                    className="form-control foco-input"
                    onChange={(event) => this.handleChange(event)}
                    name="months"
                    value={months}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <label>Observação</label>
                  <textarea
                    maxLength="500"
                    className="form-control foco-input"
                    onChange={(event) => this.handleChange(event)}
                    name="comments"
                    value={comments}
                  />
                </Col>
              </div>
            </div>
            <Button
              onClick={(event) =>
                reviewId ? this.attReview(event) : this.handleSubmit(event)
              }
              disabled={this.state.disabled}
              type="button"
              bsStyle="info"
              pullRight
              fill
            >
              <span
                className={this.state.loading && 'fa fa-spinner fa-pulse fa-1x'}
                style={{ marginRight: '5px' }}
              ></span>
              {reviewId ? 'Atualizar' : 'Salvar'}
            </Button>
            <Button
              style={{ marginRight: '5px' }}
              disabled={this.state.disabled}
              onClick={() =>
                this.props.modal
                  ? this.props.handleModal('review')
                  : this.props.history.push(constants.ROUTES.REVISIONS)
              }
              type="button"
              bsStyle="danger"
              pullRight
              fill
            >
              Cancelar
            </Button>
          </form>
        }
      />
    );
  }
}

export default withRouter(FormRevisao);
