import React from 'react';
import ReactTable from 'react-table';

export const Table = React.forwardRef(
  (
    {
      loading,
      columns,
      fontSize,
      showPagination = true,
      height,
      trHeight,
      overflowY,
      data,
      footerProps = {},
      noDataText = 'Não há informação',
      pageSizeOptions = [5, 10, 15, 20, 25, 50, 100],
      ...props
    },
    ref
  ) => {
    return (
      <ReactTable
        loading={loading}
        data={data}
        columns={columns}
        defaultPageSize={10}
        showPagination={showPagination}
        sortable={true}
        style={{
          display: 'flex',
          textAlign: 'center',
          fontSize: fontSize || '14px',
          height,
          overflowY,
        }}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={pageSizeOptions}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={noDataText}
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        getTheadThProps={() => {
          return {
            style: {
              fontWeight: 'bold',
            },
          };
        }}
        getTdProps={() => {
          return {
            style: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              whiteSpace: 'pre-wrap',
            },
          };
        }}
        getTrProps={() => {
          return {
            style: {
              height: trHeight,
            },
          };
        }}
        getPaginationProps={() => {
          return {
            style: {
              fontWeight: 'bold',
            },
          };
        }}
        getTfootProps={() => {
          return footerProps;
        }}
        {...props}
      />
    );
  }
);

Table.displayName = 'Table';
