const envConfig = {
  endpoint: 'http://localhost:21061/',
  queridocarroendpoint: 'https://sobrerodasapi.carrook.com.br/',
  whatsappendpoint: 'https://wa.me/55',
  mysuiteendpoint: 'http://workg.mysuite.com.br/client/chatan.php?:',
  movideskendpoint: 'https://workgroup.movidesk.com',
  sobreRodasWebEndpoint: 'https://sobrerodas.carrook.com.br',
};

export default envConfig;
