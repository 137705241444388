import React from 'react'
import { Modal } from 'react-bootstrap'
import VehicleForm from '../../Cadastros/Vehicle'

const FormModalVehicle = ({
  onCancel,
  vehicleId,
  customerId,
  companyId,
  onSubmit,
  setSelectedVehicleType,
}) => {
  return (
    <Modal show onHide={onCancel} animation dialogClassName="modal-90w">
      <Modal.Header
        style={{ borderBottom: '1px solid black', borderColor: '#E5E5E5' }}
        closeButton
      >
        <Modal.Title>
          <strong>{!vehicleId ? 'Cadastrar Veículo' : 'Editar Veículo'}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        id="modal-bottom"
        style={{
          height: '500px',
        }}
      >
        <VehicleForm
          customerId={customerId}
          vehicleId={vehicleId}
          companyId={companyId}
          onCancel={onCancel}
          onSubmit={onSubmit}
          setSelectedVehicleType={setSelectedVehicleType}
        />
      </Modal.Body>
    </Modal>
  )
}

export default FormModalVehicle
