import React from 'react';
import './styles.css';

import config from '../../../config';
import constants from '../../../utils/constants';

import { useAuth } from 'contexts/auth';
import { cnpjMask, cpfMask } from 'client/components/ToNormalize/ToNormalize';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { usePlanSignatureContext } from '../../../contexts/plan-signature';
import { useBranding } from 'hooks/useBranding';

const SupportButton = ({ buttonText, customLink, showWhatsAppIcon }) => {
  const { company } = useAuth();
  const { isPO360 } = useBranding();

  const { signature, isPlanFree } = usePlanSignatureContext();
  const verifySignature = () => {
    let formatedCpfCnpj;
    let password = 'test';
    if (company.Type === 'Jurídica') {
      formatedCpfCnpj = cnpjMask(company.Cpf_Cnpj);
    } else {
      formatedCpfCnpj = cpfMask(company.Cpf_Cnpj);
    }

    const movideskPassword =
      company.movideskCompanyCredentials?.movidesk_password;

    const SUPPORT_LINK = `/Account/Authenticate?token=8e9b93bb-ca8f-4a95-9805-76210cd5a4e2&userName=${formatedCpfCnpj}&password=${movideskPassword}`;

    if (isPlanFree) return handleOpenWindowPlanFree();

    try {
      // se tiver cancelado, porém dentro da vigencia mandar para suporte
      const { SignatureStatus_id, End_Date } = signature;
      var today = new Date().toISOString().substring(0, 10);
      var end = new Date(End_Date).toISOString().substring(0, 10);

      if (customLink) {
        return window.open(customLink, '_blank');
      }

      if (
        SignatureStatus_id === constants.STATUS_ID.ACTIVE ||
        (SignatureStatus_id === constants.STATUS_ID.CANCELED && today < end)
      ) {
        return handleOpenWindow(SUPPORT_LINK);
      } else {
        return handleOpenWindowPlanFree();
      }
    } catch (err) {
      console.error(err);
      console.log('Erro ao carregar assinatura.');
    }
  };

  const handleOpenWindow = (path) => {
    const movideskWork = config.movideskendpoint;
    const movideskPO360 = 'https://atendimentopo360.movidesk.com';

    let endpoint = '';

    console.log(endpoint);

    console.log('isPO360', isPO360);
    switch (true) {
      case isPO360:
        endpoint = movideskPO360;
        break;
      default:
        endpoint = movideskWork;
        break;
    }

    console.log(endpoint);
    window.open(endpoint + path, '_blank');
  };

  const handleOpenWindowPlanFree = () => {
    window.open(
      'https://api.whatsapp.com/send?phone=5519982440071&text=Ol%C3%A1%2c%20quero%20saber%20mais%20sobre%20o%20OS%20Digital.',
      '_blank',
      'toolbar=yes, scrollbars=yes, resizable=yes, top=3, left=1, width=1350, height=660'
    );
  };

  return (
    <div className="support-button" onClick={verifySignature}>
      {showWhatsAppIcon ? (
        <FontAwesomeIcon icon={faWhatsapp} />
      ) : isPlanFree ? (
        <FontAwesomeIcon icon={faWhatsapp} />
      ) : (
        <FontAwesomeIcon icon={faHeadset} />
      )}
      <span>{buttonText || 'Suporte Online'}</span>
    </div>
  );
};

export default SupportButton;
