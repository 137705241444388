import React, { useEffect, useState } from 'react';
import { usePlanSignatureContext } from '../../../../../contexts/plan-signature';
import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from './redux/actions';
import { SalesPhotosList } from './SalePhotosList';
import { SalePhotosAdd } from './SalePhotosAdd';
import { toastr } from 'react-redux-toastr';
import AlertModal from 'components/AlertModal/AlertModal';

export const SalePhotosButton = () => {
  const [isConfirmCloseAddPhotoModalOpen, setIsConfirmCloseAddPhotoModalOpen] =
    useState(false);

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const dispatch = useDispatch();
  const {
    saleReducer: {
      isPhotosModalOpen,
      isAddPhotosModalOpen,
      photos,
      photoToImport,
      saleStatusId,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (isPhotosModalOpen && !!!photos.length)
      dispatch(handleChange(true, 'isAddPhotosModalOpen'));
  }, [isPhotosModalOpen]);

  const handleAddPhoto = () => {
    if (!!!photoToImport.description || !!!photoToImport.name)
      return toastr.warning(
        'Verifique a descrição e/ou a imagem e tente novamente.'
      );

    const newPhotosArray = [...photos];
    newPhotosArray.push(photoToImport);
    dispatch([
      handleChange(newPhotosArray, 'photos'),
      handleChange({}, 'photoToImport'),
      handleChange(false, 'isAddPhotosModalOpen'),
    ]);
  };

  const handleCloseAddPhotoModal = () => {
    if (!!photoToImport.name || !!photoToImport.description)
      return setIsConfirmCloseAddPhotoModalOpen(true);
    closeAddPhotoModal();
  };

  const handleConfirmCloseAddPhotoModal = () => {
    setIsConfirmCloseAddPhotoModalOpen(false);
    closeAddPhotoModal();
  };

  const closeAddPhotoModal = () => {
    dispatch([
      handleChange(!isAddPhotosModalOpen, 'isAddPhotosModalOpen'),
      handleChange({}, 'photoToImport'),
    ]);
  };

  return (
    <>
      <Button
        bsStyle="warning"
        className="button button-h35 flex center"
        fill
        onClick={() => dispatch(handleChange(true, 'isPhotosModalOpen'))}
        style={{
          marginRight: 10,
          color: '#FFFFFF',
        }}
        disabled={
          isPlanFree ||
          isPlanStart ||
          (saleStatusId === 5 && photos.length === 0) ||
          (saleStatusId === 2 && photos.length === 0)
        }
      >
        {photos.length > 0 ? 'Visualizar Fotos' : 'Adicionar Fotos'}
      </Button>
      <Modal show={isPhotosModalOpen} dialogClassName="modal-60w">
        <Modal.Header>
          <Modal.Title>
            <strong>Fotos da OS</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SalesPhotosList />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              dispatch(handleChange(!isPhotosModalOpen, 'isPhotosModalOpen'))
            }
            bsStyle="danger"
            fill
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isAddPhotosModalOpen} dialogClassName="modal-60w">
        <Modal.Header>
          <Modal.Title>
            <strong>Adicionar Foto</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SalePhotosAdd />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseAddPhotoModal} bsStyle="danger" fill>
            Voltar
          </Button>
          <Button onClick={handleAddPhoto} bsStyle="info" fill>
            Importar Foto
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isConfirmCloseAddPhotoModalOpen}
        message="Deseja cancelar a importação da foto? A foto poderá ser importada posteriormente."
        onCancel={() => setIsConfirmCloseAddPhotoModalOpen(false)}
        onSubmit={handleConfirmCloseAddPhotoModal}
        onHide={() => setIsConfirmCloseAddPhotoModalOpen(false)}
      />
    </>
  );
};
