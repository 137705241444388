import developmentOS from './osdigital/development.js';
import productionOS from './osdigital/production.js';
import releaseOS from './osdigital/release.js';
import stagingOS from './osdigital/staging.js';

import developmentWM from './workmotor/development.js';
import productionWM from './workmotor/production.js';
import releaseWM from './workmotor/release.js';
import stagingWM from './workmotor/staging.js';

import developmentTecnomotor from './tecnomotor/development.js';
import productionTecnomotor from './tecnomotor/production.js';

import local from './local.js';

export default function ConfigureAppEnvironment() {
  const hostname = window && window.location && window.location.hostname;

  const customEnvironment = process.env.REACT_APP_CUSTOM_ENVIRONMENT;

  if (!customEnvironment) {
    switch (hostname) {
      case 'osdig.carrook.com.br':
        return developmentOS;
      case 'workmotorapp.carrook.com.br':
        return developmentWM;
      case 'tecnomotor.carrook.com.br':
        return developmentTecnomotor;
      case 'release.app.osdig.com.br':
        return releaseOS;
      case 'release.app.workmotor.app':
        return releaseWM;
      case 'stg.app.osdig.com.br':
        return stagingOS;
      case 'stg.app.workmotor.app':
        return stagingWM;
      case 'app.osdig.com.br':
        return productionOS;
      case 'app.workmotor.app':
        return productionWM;
      case 'release.app.sistemabarros.com.br':
        return releaseOS;
      case 'app.sistemabarros.com.br':
        return productionOS;
      case 'rel.app.po360comercial.com.br':
        return releaseOS;
      case 'app.osdigitaltecnomotor.com.br':
        return productionTecnomotor;
      case 'app.po360comercial.com.br':
        return productionOS;
      case 'stg.app.po360comercial.com.br':
        return stagingOS;
      default:
        return local;
    }
  } else {
    switch (customEnvironment) {
      case 'development':
        return developmentOS;
      case 'production':
        return productionOS;
      case 'release':
        return releaseOS;
      case 'staging':
        return stagingOS;
      default:
        return local;
    }
  }
}
