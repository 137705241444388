import React from 'react';

import { Header } from './components/Header';
import { ManagementIndicatorsContainer } from './ManagementIndicators.styles';
import { IndicatorCard } from './components/IndicatorCard';
import { Financial } from './sections/Financial';
import { Sales } from './sections/Sales';

import useManagementIndicatorsStore from './store/management-indicators-store';

export function ManagementIndicators() {
  const { selectedOption } = useManagementIndicatorsStore();

  return (
    <ManagementIndicatorsContainer>
      <Header />
      {selectedOption === 'Financeiro' && <Financial />}
      {selectedOption === 'Vendas' && <Sales />}
    </ManagementIndicatorsContainer>
  );
}
