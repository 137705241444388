import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import promise from 'redux-promise';
import multi from 'redux-multi';
import thunk from 'redux-thunk';

import { RecoilRoot } from 'recoil';

import reducers from './main/reducers';
import { ToasterWrapper } from './components/ToasterWrapper';

import EsqueciMinhaSenha from './views/ForgotPassword';
import LoginPage from './views/LoginPage';
import NovaSenha from './views/NewPassword';
import TryForFree from './views/TryForFree';
import Termos from './views/Terms';
import BlockNavegador from './views/BloqueioNavegador';

import SaleShareSheet from './views/ShareSheets/Sale';
import PDVShareSheet from './views/ShareSheets/PDV';
import NFCeShareSheet from './views/ShareSheets/NFCE/LoadNFCE';
import QuickQuoteShareSheet from './views/ShareSheets/QuickQuote';

import Sheets from 'views/Sheets';
import QuickQuoteSheet from './views/Sheets/QuickQuote';

import CompanyBlock from './views/Block';
import PDV from './client/views/PdvOnline';

import CanceledClient from './views/CanceledClient';
import BlockedClient from './views/BlockedClient';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';

import './styles/workmotor.css';
import './styles/tecnomotor.css';
import './styles/barros.css';
import './styles/po360.css';

import ContextProviders from './contexts/context-providers';
import ContextProvidersV2 from './v2/contexts/context-providers';
import AdminRoutes from './admin/routes/index.routes';
import ClientRoutes from './client/routes/index.routes';

import { isChrome, isEdge, isSafari, isFirefox } from './utils/browserTypes';
import CashClosingReport from 'views/Reports/CashClosing';
import CashierBankFlow from 'views/Reports/CashierBankFlow';
import { SheetsAnnotation } from 'views/SheetsAnnotation';
import { SheetsChecklistPrint } from 'views/SheetsChecklistPrint';
import { SheetsCustomerHistoric } from './views/SheetsCustomerHistory';
import CommissionsReport from './views/Reports/Commissions';
import ProductHistoryReport from './views/Reports/ProductHistory';
import { SaleReturnReport } from 'views/Reports/SaleReturns';
import { SaleReturnsByPeriodReport } from 'views/Reports/SaleReturnsByPeriod';
import FinancialStatementReport from './views/Reports/FinancialStatement';
import RevenuesPerPeriodReport from 'views/Reports/RevenuesPerPeriod';
import PurchaseSuggestion from './views/Reports/PurchaseSuggestion';

import OnboardingVideoRedirect from 'views/OnboardingVideoRedirect';

import './styles/global.css';
import CanceledBudgetsReport from 'views/Reports/CanceledBudgets';
import BillsToReceiveReceiptReport from 'views/Reports/BillsToReceiveReceipt';
import SingleQuotationReport from 'views/Reports/Quotation/SingleQuotationReport';
import { QuotationResponse } from 'views/QuotationResponse';
import { VehicleHistoryReport } from 'views/Reports/VehicleHistory';
import GeneralQuotationReport from 'views/Reports/Quotation/GeneralQuotationReport';
import { VourchersIssuedReport } from 'views/Reports/VouchersIssued';
import InvoicesSynthetic from 'client/views/Reports/Invoices/synthetic';
import InvoicesAnalytical from 'client/views/Reports/Invoices/analytical';
import ReturnDocument from 'views/Reports/ReturnReport';
import { SalesByTypeReport } from 'views/Reports/SalesByType';
import { QuotesSupplierReport } from 'views/Reports/QuotesSupplier';
import ReturnDocumentPurchasesSupplier from 'views/Reports/PurchasesSupplierReport';
import { ProductsFamilyOrBrandReport } from 'views/Reports/ProductsFamilyOrBrand';
import InventoryMovementListingReport from 'views/Reports/InventoryMovementListingReport';
import ProductServiceReviewsReport from 'views/Reports/ProductServiceReviewsReport';
import CustomersListReport from 'views/Reports/CustomersList';
import { SalesPerFamilyProductReport } from 'views/Reports/SalesPerFamilyProduct';
import PendingCustomerSuppliersReport from 'views/Reports/PendingCustomersSuppliers';
import ReportsListEmployees from 'views/Reports/ReportsListEmployees/synthetic';
import ReportsListEmployeesAnalytical from 'views/Reports/ReportsListEmployees/analytical';
import InventoryCountReport from 'views/Reports/InventoryCountReport';
import PartsPerApplication from 'views/Reports/PartsPerApplication';
import ExpenseForecast from 'views/Reports/ExpenseForecast';
import { RepresentativeComissions } from 'views/Reports/RepresentativeComissions';
import { FinancialStatement } from 'views/FinancialStatement';
import { SalesPerFamilyServiceReport } from 'views/Reports/SalesPerFamilyService';
import SalesByAttendanceReport from 'views/Reports/SalesByAttendance';
import { ExternalWorkshopPanel } from 'client/views/WorkshopPanel/ClientWorkshopPanel';
import { ExternalIndicators } from 'client/views/ExternalIndicators';

import BillsReceivedsReport from 'views/Reports/BillsReceivedsReport';
import IncomeStatement from 'views/IncomeStatement';
import SalesByFormPaymentReport from 'views/Reports/SalesByFormPayment';
import SalesPassingReport from 'views/Reports/SalesPassing';
import AccountsReceivableSyntheticReport from 'views/Reports/AccountsReceivableReport/synthetic';
import AccountsReceivableAnalyticalReport from 'views/Reports/AccountsReceivableReport/analytical';
import AccountsPayableSyntheticReport from 'views/Reports/AccountsPayableReport/synthetic';
import AccountsPayableAnalyticalReport from 'views/Reports/AccountsPayableReport/analytical';
import ValuesByStatusReport from 'views/Reports/ValuesByStatus';
import IssuedSlipReport from 'views/Reports/IssuedSlip';

import { HangTagPDF } from 'client/views/HangTag/components/PDF';

import { ThemeProvider } from 'styled-components';

import { applicationTheme } from 'v2/styles/themes';
import { GlobalStyle } from 'v2/styles/global';
import SalesPerDayDocument from 'client/views/Reports/SalesPerDay/components/SalesPerDayDocument';
import SalesPerDayReport from 'client/views/Reports/SalesPerDay/report';
import ListCanceledItemsReport from 'client/views/Reports/ListCanceledItems/report';
import { ProfitPanel } from 'v2/views/client/ProfitPanel';
import { ExportXML } from 'v2/views/client/ExportXML';
import { WorkshopPanel } from 'v2/views/client/WorkshopPanel';
import { WorkshopPanelExternal } from 'v2/views/client/WorkshopPanelExternal';
import { ManagementIndicators } from 'v2/views/client/ManagementIndicators/ManagementIndicators';

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.location.host.includes('localhost') &&
  window.__REDUX_DEVTOOLS_EXTENSION__();
const store = applyMiddleware(thunk, multi, promise)(createStore)(
  reducers,
  devTools
);
ReactDOM.render(
  <Provider store={store}>
    <RecoilRoot>
      <ContextProvidersV2>
        <ContextProviders>
          <ThemeProvider theme={applicationTheme}>
            <GlobalStyle />
            <BrowserRouter>
              <ToasterWrapper />
              {isChrome || isEdge || isSafari || isFirefox ? (
                <Switch>
                  <Route path="/client*" component={ClientRoutes} />
                  <Route path="/profit-panel" component={ProfitPanel} />
                  <Route path="/export-xml" component={ExportXML} />
                  <Route
                    path="/management-indicators"
                    component={ManagementIndicators}
                  />
                  <Route
                    path="/EsqueciMinhaSenha"
                    component={EsqueciMinhaSenha}
                  />
                  <Route path="/ExperimenteGratis" component={TryForFree} />
                  <Route path="/NovaSenha" component={NovaSenha} />
                  <Route path="/Termos" component={Termos} />
                  <Route path="/client-login" component={LoginPage} />
                  <Route path="/canceled-client" component={CanceledClient} />
                  <Route path="/blocked-client" component={BlockedClient} />
                  <Route path="/company-block" component={CompanyBlock} />
                  <Route path="/admin*" component={AdminRoutes} />
                  <Route path="/pdv-online" component={PDV} />
                  <Route
                    path="/onboarding-video-redirect"
                    component={OnboardingVideoRedirect}
                  />
                  <Route path="/share-sheets/sale" component={SaleShareSheet} />
                  <Route path="/share-sheets/PDV" component={PDVShareSheet} />
                  <Route path="/share-sheets/NFCe" component={NFCeShareSheet} />
                  <Route
                    path="/share-sheets/quick-quote"
                    component={QuickQuoteShareSheet}
                  />
                  <Route path="/sheets" component={Sheets} />
                  <Route path="/quick-quote" component={QuickQuoteSheet} />
                  <Route
                    path="/report/close-cashier"
                    component={CashClosingReport}
                  />
                  <Route
                    path="/report/general-quotation-report"
                    component={GeneralQuotationReport}
                  />
                  <Route
                    path="/report/bill-to-receive-receipt"
                    component={BillsToReceiveReceiptReport}
                  />
                  <Route
                    path="/report/single-quotation-report"
                    component={SingleQuotationReport}
                  />
                  <Route
                    path="/report/cashier-bank-flow"
                    component={CashierBankFlow}
                  />
                  <Route
                    path="/report/commissions"
                    component={CommissionsReport}
                  />
                  <Route path="/checklist" component={SheetsChecklistPrint} />
                  <Route
                    path="/mechanical-annotation"
                    component={SheetsAnnotation}
                  />
                  <Route
                    path="/report/canceled-budgets"
                    component={CanceledBudgetsReport}
                  />
                  <Route
                    path="/report/customer-history"
                    component={SheetsCustomerHistoric}
                  />
                  <Route
                    path="/report/financial-statement"
                    component={FinancialStatement}
                  />
                  <Route
                    path="/report/product-history"
                    component={ProductHistoryReport}
                  />
                  <Route
                    path="/report/sale-returns"
                    component={SaleReturnReport}
                  />
                  <Route
                    path="/report/sale-returns-by-period"
                    component={SaleReturnsByPeriodReport}
                  />
                  <Route
                    path="/report/sales-by-type"
                    component={SalesByTypeReport}
                  />
                  <Route
                    path="/report/financial-statement-document"
                    component={FinancialStatementReport}
                  />
                  <Route
                    path="/report/purchase-suggestion"
                    component={PurchaseSuggestion}
                  />
                  <Route
                    path="/report/revenues-per-period"
                    component={RevenuesPerPeriodReport}
                  />
                  <Route
                    path="/quotation/reply"
                    exact
                    component={QuotationResponse}
                  />
                  <Route
                    path="/report/vehicle-history"
                    component={VehicleHistoryReport}
                  />
                  <Route path="/workshop-panel" component={WorkshopPanel} />
                  <Route
                    path="/external-workshop-panel/by-appointment"
                    component={WorkshopPanelExternal}
                  />
                  <Route
                    path="/external-workshop-panel"
                    component={ExternalWorkshopPanel}
                  />
                  <Route
                    path="/external-indicators"
                    component={ExternalIndicators}
                  />
                  <Route
                    path="/report/voucher-issued"
                    component={VourchersIssuedReport}
                  />
                  <Route
                    path="/report/invoices-synthetic"
                    component={InvoicesSynthetic}
                  />
                  <Route
                    path="/report/return-listing"
                    component={ReturnDocument}
                  />
                  <Route
                    path="/report/invoices-analytical"
                    component={InvoicesAnalytical}
                  />
                  <Route
                    path="/report/quotes-supplier"
                    component={QuotesSupplierReport}
                  />
                  <Route
                    path="/report/list-purchases-supplier"
                    component={ReturnDocumentPurchasesSupplier}
                  />
                  <Route
                    path="/report/products-family-brand"
                    component={ProductsFamilyOrBrandReport}
                  />
                  <Route
                    path="/report/sales-family-product"
                    component={SalesPerFamilyProductReport}
                  />
                  <Route
                    path="/report/sales-family-service"
                    component={SalesPerFamilyServiceReport}
                  />
                  <Route
                    path="/report/inventory-movement"
                    component={InventoryMovementListingReport}
                  />
                  <Route
                    path="/report/product-service-reviews"
                    component={ProductServiceReviewsReport}
                  />
                  <Route
                    path="/report/sales-by-form-payment"
                    component={SalesByFormPaymentReport}
                  />
                  <Route
                    path="/report/values-by-status"
                    component={ValuesByStatusReport}
                  />
                  <Route
                    path="/report/pending-customers-suppliers"
                    component={PendingCustomerSuppliersReport}
                  />
                  <Route
                    path="/report/list-employees"
                    component={ReportsListEmployees}
                  />
                  <Route
                    path="/report/list-employees-analytical"
                    component={ReportsListEmployeesAnalytical}
                  />
                  <Redirect exact from="/" to="/client-login" />
                  <Route path="/workshop-panel" component={WorkshopPanel} />
                  <Route
                    path="/report/inventory-count"
                    component={InventoryCountReport}
                  />
                  <Route
                    path="/report/parts-per-application"
                    component={PartsPerApplication}
                  />
                  <Route
                    path="/report/expense-forecast"
                    component={ExpenseForecast}
                  />
                  <Route
                    path="/report/receipts-payment-method"
                    component={BillsReceivedsReport}
                  />
                  <Route path="/income-statement" component={IncomeStatement} />
                  <Route
                    path="/report/accounts-receivable-synthetic"
                    component={AccountsReceivableSyntheticReport}
                  />
                  <Route
                    path="/report/accounts-receivable-analytical"
                    component={AccountsReceivableAnalyticalReport}
                  />
                  <Route
                    path="/report/accounts-payable-synthetic"
                    component={AccountsPayableSyntheticReport}
                  />
                  <Route
                    path="/report/accounts-payable-analytical"
                    component={AccountsPayableAnalyticalReport}
                  />
                  <Route
                    path="/report/representative-comissions"
                    component={RepresentativeComissions}
                  />
                  <Route
                    path="/report/sales-by-attendance"
                    component={SalesByAttendanceReport}
                  />
                  <Route
                    path="/report/customers-list"
                    component={CustomersListReport}
                  />
                  <Route
                    path="/report/sales-passing"
                    component={SalesPassingReport}
                  />
                  <Route
                    path="/report/issued-slip"
                    component={IssuedSlipReport}
                  />
                  <Route path="/print/hangtag" component={HangTagPDF} />
                  <Route
                    path="/report/sales-per-day"
                    component={SalesPerDayReport}
                  />
                  <Route
                    path="/report/list-canceled-items"
                    component={ListCanceledItemsReport}
                  />
                </Switch>
              ) : (
                <Switch>
                  <Route path="/" exact component={BlockNavegador} />
                </Switch>
              )}
            </BrowserRouter>
          </ThemeProvider>
        </ContextProviders>
      </ContextProvidersV2>
    </RecoilRoot>
  </Provider>,
  document.getElementById('root')
);
