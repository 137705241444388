import customersRepository from 'repositories/Customers';

const getCustomerPromotionalPriceType = async (customerId) => {
  const customer = await customersRepository.getCustomer(customerId);
  return customer.sellingPriceType;
};

export const getProductPromotionalPrices = (
  saleRetailPrice,
  salePriceWholesale,
  allowPromotionalPrices,
  promotionalRetailPrice,
  promotionalWholesalePrice,
  type
) => {
  switch (type) {
    case 'Produtos - Atacado':
      return {
        Sale_Price:
          allowPromotionalPrices && promotionalWholesalePrice > 0
            ? promotionalWholesalePrice
            : salePriceWholesale,
        hasPromotionalPrice: promotionalWholesalePrice > 0,
        priceType: allowPromotionalPrices ? 'PROMOTIONAL' : 'WHOLESALE',
      };
    default:
      return {
        Sale_Price:
          allowPromotionalPrices && promotionalRetailPrice > 0
            ? promotionalRetailPrice
            : saleRetailPrice,
        hasPromotionalPrice: promotionalRetailPrice > 0,
        priceType: allowPromotionalPrices ? 'PROMOTIONAL' : 'RETAIL',
      };
  }
};

export const getPricesOptions = (
  sellingPriceType,
  hasKitSearch,
  hasServices = true
) => {
  const defaultOptions = [
    {
      label: 'Produtos',
      value: 'Produto',
    },
  ];
  const promotionalPricesOptions = [
    {
      label: 'Produtos - Varejo',
      value: 'Produtos - Varejo',
    },
    {
      label: 'Produtos - Atacado',
      value: 'Produtos - Atacado',
    },
  ];

  const options = [
    ...(sellingPriceType === 'Preço Atacado e Varejo'
      ? promotionalPricesOptions
      : defaultOptions),
    ...(hasServices
      ? [
          {
            label: 'Serviços',
            value: 'Serviço',
          },
        ]
      : []),
    ...(hasKitSearch ? [{ label: 'Kit', value: 'Kit' }] : []),
  ];

  return options;
};

export const getDefaultPriceOptions = async (sellingPriceType, customerId) => {
  const defaultOption = {
    label: 'Produtos',
    value: 'Produto',
  };
  const retailOption = {
    label: 'Produtos - Varejo',
    value: 'Produtos - Varejo',
  };
  const wholesaleOption = {
    label: 'Produtos - Atacado',
    value: 'Produtos - Atacado',
  };

  if (sellingPriceType === 'Preço Único') {
    return defaultOption;
  }

  if (customerId && sellingPriceType === 'Preço Atacado e Varejo') {
    const customerSellingPriceType = await getCustomerPromotionalPriceType(
      customerId
    );
    if (customerSellingPriceType === 'Varejo') {
      return retailOption;
    }
    if (customerSellingPriceType === 'Atacado') {
      return wholesaleOption;
    }
  }

  if (sellingPriceType === 'Preço Atacado e Varejo') {
    return retailOption;
  }
};

export const getSelectedPriceOption = async (
  type,
  sellingPriceType,
  customerId
) => {
  const retailOption = 'Produtos - Varejo';
  const wholesaleOption = 'Produtos - Atacado';

  if (customerId && sellingPriceType === 'Preço Atacado e Varejo') {
    const customerSellingPriceType = await getCustomerPromotionalPriceType(
      customerId
    );
    if (customerSellingPriceType === 'Varejo') {
      return retailOption;
    }
    if (customerSellingPriceType === 'Atacado') {
      return wholesaleOption;
    }
  }

  if (sellingPriceType === 'Preço Único') {
    return type;
  }

  if (sellingPriceType === 'Preço Atacado e Varejo') {
    return retailOption;
  }
};
