import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import { percentMask } from 'utils/masks';
import { currencyMask } from 'utils/masks';
import { Totals } from './Totals';

export const GeneralDiscount = ({
  updateTotalPdvTrigger,
  setUpdateTotalPdvTrigger,
}) => {
  const {
    discountType,
    step,
    totalSale,
    cart,
    generalDiscountPercentage,
    generalDiscount,
    freightValue,
  } = useSelector((state) => state.form.pdvOnline.values);

  const dispatch = useDispatch();

  function recalcWithCurrencyDiscount() {
    const totalCart = getTotalCart();

    const totalWithDiscount = totalCart - generalDiscount + freightValue;

    const discountInPercentage =
      (generalDiscount / (totalCart + freightValue)) * 100;

    dispatch(change('pdvOnline', 'generalDiscount', generalDiscount));
    dispatch(
      change('pdvOnline', 'generalDiscountPercentage', discountInPercentage)
    );
    dispatch(change('pdvOnline', 'totalSale', totalWithDiscount));
  }

  function recalcWithPercentageDiscount() {
    const totalCart = getTotalCart();

    const totalWithDiscount =
      totalCart * ((100 - generalDiscountPercentage) / 100) + freightValue;

    const discountInCurrency = totalCart + freightValue - totalWithDiscount;

    dispatch(
      change(
        'pdvOnline',
        'generalDiscountPercentage',
        generalDiscountPercentage
      )
    );
    dispatch(change('pdvOnline', 'totalSale', totalWithDiscount));
    dispatch(change('pdvOnline', 'generalDiscount', discountInCurrency));
  }

  useEffect(() => {
    if (discountType === 'real') {
      recalcWithCurrencyDiscount();
    } else {
      recalcWithPercentageDiscount();
    }

    setUpdateTotalPdvTrigger(false);
  }, [
    discountType,
    generalDiscount,
    generalDiscountPercentage,
    freightValue,
    updateTotalPdvTrigger,
  ]);

  useEffect(() => {
    dispatch(change('pdvOnline', 'paymentConditionId', ''));
  }, [generalDiscount, generalDiscountPercentage]);

  function getTotalCart() {
    const totalCart = !cart.length
      ? 0
      : cart.length === 1
      ? cart[0].Total
      : cart.map((item) => item.Total).reduce((prev, curr) => prev + curr);

    return totalCart;
  }

  const handleChangeComment = (comment) => {
    dispatch(change('pdvOnline', 'Comments', comment));
  };

  return (
    <div className="footer-pdv" style={{ width: '100%' }}>
      {step === 1 && (
        <div className="obs-pdv" style={{ width: '98%', display: 'flex' }}>
          <span>Observações: </span>
          <textarea
            maxLength="500"
            onChange={(e) => handleChangeComment(e.target.value)}
            style={{ marginLeft: '5px' }}
          />
        </div>
      )}
      <div className="general-discount">
        <div className="discount-div">
          <span>Frete:</span>
          <Field
            style={{
              width: '10rem',
              fontSize: '14px',
              height: '35px',
              borderColor: '#c0c0c0',
              borderWidth: '2px',
              borderStyle: 'solid',
              borderRadius: '2px',
              marginLeft: '5px',
            }}
            name="freightValue"
            component="input"
            className="form-control foco-input"
            {...currencyMask}
            onChange={(event) =>
              dispatch(change('freightValue', event.target.value))
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '5px',
          }}
        >
          <span>Desconto Geral:</span>
          <div className="discount-inputs">
            <select
              style={{
                width: '7rem',
                fontSize: '14px',
                height: '35px',
                borderColor: '#c0c0c0',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderRadius: '2px',
                marginRight: '5px',
              }}
              value={discountType}
              onChange={(event) => {
                dispatch(
                  change('pdvOnline', 'discountType', event.target.value)
                );
              }}
              className="form-control foco-input"
            >
              <option value="real">R$</option>
              <option value="percentage">%</option>
            </select>

            {discountType === 'real' ? (
              <Field
                style={{
                  width: '13rem',
                  fontSize: '14px',
                  height: '35px',
                  borderColor: '#c0c0c0',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderRadius: '2px',
                }}
                name="generalDiscount"
                component="input"
                className="form-control foco-input"
                {...currencyMask}
              />
            ) : (
              <Field
                style={{
                  width: '13rem',
                  fontSize: '14px',
                  height: '35px',
                  borderColor: '#c0c0c0',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderRadius: '2px',
                }}
                name="generalDiscountPercentage"
                component="input"
                className="form-control foco-input"
                maxLength={8}
                {...percentMask}
              />
            )}
          </div>
          {step === 1 && <Totals />}
        </div>
      </div>
    </div>
  );
};
