import config from '../config'
import api from "../services/api"

async function create(values) {
  const response = await api.post(config.endpoint + 'financial-movements', values)
  return response.data
}

const getAllByCompany = async (companyId) => {
  const response = await api.get(config.endpoint + `financial-movements?companyId=${companyId}`)
  return response.data
}

const cancel = async (id) => {
  const response = await api.put(config.endpoint + `financial-movements/${id}/cancel`)
  return response.data
}

const getById = async (id) => {
  const response = await api.get(config.endpoint + `financial-movements/${id}/by-id`)
  return response.data
}

async function update(values, id) {
  const response = await api.put(config.endpoint + `financial-movements/${id}`, values)
  return response.data
}

export const financialMovementsRepository = {
  create,
  getAllByCompany,
  cancel,
  getById,
  update
}