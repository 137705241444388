import React from 'react'
import './styles.css'
import Button from 'client/components/CustomButton/CustomButton.jsx'

const FormSubmitButtons = ({ onCancel, onSubmit, hasId, loading, disabledSubmit, backLabel }) => {
  return(
    <div className='form-submit-buttons'>
      <Button bsStyle="danger" fill disabled={loading} onClick={onCancel}>{backLabel ? 'Voltar' : 'Cancelar'}</Button>
      <Button bsStyle="info" fill onClick={onSubmit} disabled={loading || disabledSubmit}>
        <span className={loading ? "fa fa-spinner fa-pulse fa-1x" : ''} style={{ marginRight: loading ? "5px" : '' }} />
        {hasId ? 'Atualizar' : 'Salvar'}
      </Button>
    </div>
  )
}

export default FormSubmitButtons