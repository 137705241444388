import CollapsibleSection from 'components/CollapsibleSection'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import TypeUnities from 'repositories/TypeUnities'
import brandsRepository from 'repositories/Brands'
import CurrencyInput from 'react-currency-input'
import NumberFormat from 'react-number-format'
import FormSubmitButtons from 'client/components/FormSubmitButtons'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { toastr } from 'react-redux-toastr'
import productsRepository from 'repositories/Products'
import inventoryMovementRepository from 'repositories/InventoryMovement'

export default function NewProductModal({ companyId, handleClose, onInclude }) {
  const [typeUnities, setTypeUnities] = useState()
  const [brands, setBrands] = useState()
  const [loading, setLoading] = useState(false)

  const [editSalePrice, setEditSalePrice] = useState(false)

  const {
    register,
    setValue,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm()

  const loadTypeUnityItem = async () => {
    try {
      const types = await TypeUnities.getAll()
      setTypeUnities(types)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar as unidades de medida. Por favor, tente novamente'
      )
    }
  }

  const loadBrands = async () => {
    try {
      const brands = await brandsRepository.getAllByCompany(companyId)
      setBrands(brands)
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      )
    }
  }

  const purchasePrice = useWatch({
    name: 'Purchase_Price',
    control,
  })

  const profitMargin = useWatch({
    name: 'Profit_Margin',
    control,
  })

  let salePrice = useWatch({
    name: 'Sale_Price',
    control,
  })

  const onSubmit = (values) => {
    setLoading(true)
    if (
      !values.Description ||
      !values.TypeUnityItem ||
      !values.Purchase_Price ||
      !values.Profit_Margin ||
      !values.Sale_Price ||
      !values.Brand
    ) {
      setLoading(false)
      toastr.warning(
        'Não foi possível cadastrar o produto!',
        'Preencha todos os campos obrigatórios e tente novamente.'
      )
    }
    create(values)
  }

  const calculateSalePrice = async () => {
    let formatedPurchasePrice
    let formatedSalePrice
    let formatedProfitMargin

    if (purchasePrice) {
      formatedPurchasePrice = purchasePrice
        .replace('R$ ', '')
        .replace('.', '')
        .replace(',', '.')
    }

    if (salePrice) {
      formatedSalePrice = salePrice
        .replace('R$ ', '')
        .replace('.', '')
        .replace(',', '.')
    }

    if (profitMargin) {
      formatedProfitMargin = profitMargin.replace('%', '').replace(',', '.')
    }

    let newSalePrice =
      parseFloat(formatedPurchasePrice) *
        (parseFloat(formatedProfitMargin) / 100) +
      parseFloat(formatedPurchasePrice)

    const salePriceFixed = newSalePrice.toFixed(2)
    setValue('Sale_Price', salePriceFixed.toString().replace('.', ','))
  }

  const calculateProfitMargin = async () => {
    setEditSalePrice(true)

    let formatedPurchasePrice
    let formatedSalePrice
    let formatedProfitMargin

    if (purchasePrice) {
      formatedPurchasePrice = purchasePrice
        .replace('R$ ', '')
        .replace('.', '')
        .replace(',', '.')
    }

    if (salePrice) {
      formatedSalePrice = salePrice
        .replace('R$ ', '')
        .replace('.', '')
        .replace(',', '.')
    }

    if (profitMargin) {
      formatedProfitMargin = profitMargin.replace('%', '').replace(',', '.')
    }

    let newProfitMargin =
      ((parseFloat(formatedSalePrice) - parseFloat(formatedPurchasePrice)) /
        parseFloat(formatedPurchasePrice)) *
      100

    const profitMarginFixed = newProfitMargin.toFixed(2)

    if (profitMarginFixed > 0) {
      setValue('Profit_Margin', profitMarginFixed.toString().replace('.', ','))
    } else {
      setValue('Profit_Margin', '0,00')
    }
  }

  const create = async (values) => {
    const formatedPurchasePrice = values.Purchase_Price.replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.')

    const formatedSalePrice = values.Sale_Price.replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.')

    const formatedProfitMargin = values.Profit_Margin.replace('%', '').replace(
      ',',
      '.'
    )

    try {
      const data = await productsRepository.createProduct({
        Company_id: companyId,
        Description: values.Description.toUpperCase(),
        Brand_id: parseInt(values.Brand),
        Profit_Margin: parseFloat(formatedProfitMargin),
        Purchase_Price: parseFloat(formatedPurchasePrice),
        Sale_Price: parseFloat(formatedSalePrice),
        Unit_Type: values.TypeUnityItem,
        Type: 'Produto',
        IsActive: 1,
      })

      if (parseFloat(values.Stock_Quantity) != 0) {
        try {
          const code = await inventoryMovementRepository.getCode(companyId)
          await inventoryMovementRepository.create({
            movement: {
              movementType: 'Entrada',
              code: code,
              registerDate: new Date(),
              finishedDate: new Date(),
              companyId,
              status: 'Finalizada',
            },
            items: [
              {
                code: data.product.Code,
                description: values.Description.toUpperCase(),
                productId: data.product.id,
                quantity: parseFloat(values.Stock_Quantity),
                unitValue: parseFloat(formatedSalePrice),
              },
            ],
          })
        } catch (err) {
          toastr.warning('Erro ao cadastrar movimentação de estoque.')
          console.err(err)
        }
      }

      toastr.success('Produto cadastrado com sucesso!')
      handleClose()
      onInclude({ ...data.product, Type: 'Produto' })
    } catch (err) {
      console.log(err)
      toastr.error(
        'Ocorreu um erro ao cadastrar o produto',
        'Por favor, tente novamente.'
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (editSalePrice === false) {
      calculateSalePrice()
    }
  }, [profitMargin, purchasePrice])

  useEffect(() => {
    calculateProfitMargin()
  }, [salePrice])

  useEffect(() => {
    setEditSalePrice(false)
  }, [profitMargin])

  useEffect(() => {
    loadTypeUnityItem()
    loadBrands()
  }, [])

  return (
    <Modal show={true} onHide={handleClose} dialogClassName="modal-60w">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>OS Digital</strong>
          <span
            style={{
              color: 'red',
              position: 'absolute',
              right: 10,
              fontSize: '14px',
              marginTop: '15px',
            }}
          >
            * Campos Obrigatórios
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CollapsibleSection title="Dados do Produto" isExpanded={true}>
          <div className="div-products-form">
            <div className="div-products-form-row">
              <div className="div-products-form-inputs">
                <span>
                  Descrição:<span style={{ color: 'red' }}>*</span>
                </span>
                <input
                  required
                  style={{
                    width: '430px',
                  }}
                  className="form-control foco-input"
                  type="text"
                  {...register('Description')}
                  id="Description"
                />
              </div>

              <div className="div-products-form-inputs">
                <span>
                  Unidade de Medida:<span style={{ color: 'red' }}>*</span>
                </span>
                <select
                  required
                  style={{
                    width: '130px',
                  }}
                  className="form-control foco-input"
                  type="text"
                  {...register('TypeUnityItem')}
                  id="TypeUnityItem"
                >
                  <option value="">Selecione</option>
                  {typeUnities &&
                    typeUnities.map((r) => {
                      return (
                        <option key={r.id} value={r.Initials}>
                          {r.Description}
                        </option>
                      )
                    })}
                </select>
              </div>

              <div className="div-products-form-inputs">
                <span>Estoque:</span>
                <input
                  required
                  style={{
                    width: '90px',
                  }}
                  className="form-control foco-input"
                  {...register('Stock_Quantity')}
                  name="Stock_Quantity"
                />
              </div>
            </div>

            <div className="div-products-form-row">
              <div className="div-products-form-inputs">
                <span>
                  Preço de Compra:<span style={{ color: 'red' }}>*</span>
                </span>
                <Controller
                  name="Purchase_Price"
                  control={control}
                  render={({ field }) => {
                    return (
                      <CurrencyInput
                        style={{
                          width: '120px',
                        }}
                        prefix="R$ "
                        thousandSeparator="."
                        decimalSeparator=","
                        className="form-control foco-input"
                        {...field}
                      />
                    )
                  }}
                />
              </div>

              <div className="div-products-form-inputs">
                <span>
                  Margem de Lucro:<span style={{ color: 'red' }}>*</span>
                </span>
                <Controller
                  name="Profit_Margin"
                  control={control}
                  render={({ field }) => {
                    return (
                      <NumberFormat
                        style={{
                          width: '120px',
                        }}
                        suffix="%"
                        defaultValue="0,00"
                        allowEmptyFormatting
                        decimalScale={2}
                        decimalSeparator=","
                        className="form-control foco-input"
                        {...field}
                      />
                    )
                  }}
                />
              </div>

              <div className="div-products-form-inputs">
                <span>
                  Preço de Venda:<span style={{ color: 'red' }}>*</span>
                </span>
                <Controller
                  name="Sale_Price"
                  control={control}
                  render={({ field }) => {
                    return (
                      <CurrencyInput
                        style={{
                          width: '120px',
                        }}
                        prefix="R$ "
                        thousandSeparator="."
                        decimalSeparator=","
                        className="form-control foco-input"
                        {...field}
                      />
                    )
                  }}
                />
              </div>

              <div className="div-products-form-inputs">
                <span>
                  Marca:<span style={{ color: 'red' }}>*</span>
                </span>
                <select
                  required
                  style={{
                    width: '170px',
                  }}
                  className="form-control foco-input"
                  type="text"
                  id="TypeUnityItem"
                  {...register('Brand')}
                >
                  <option value="">Selecione</option>
                  {brands &&
                    brands.map((r) => {
                      return (
                        <option key={r.id} value={r.id}>
                          {r.Description}
                        </option>
                      )
                    })}
                </select>
              </div>
            </div>
          </div>
          <center>
            <span
              style={{
                fontSize: '11px',
                color: '#e61610',
              }}
            >
              O cadastro rápido de produto agiliza na venda mas está incompleto
              para emitir nota.
              <br />
              Antes de qualquer emissão, acesse o cadastro do produto e ajuste
              os dados fiscais necessários.
            </span>
          </center>
        </CollapsibleSection>

        <div
          style={{
            marginTop: '10px',
          }}
        >
          <FormSubmitButtons
            onCancel={handleClose}
            onSubmit={handleSubmit(onSubmit)}
            loading={loading}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}
