export function getColorClassByBankSlipStatus(bank_slip_status_description) {
  switch (true) {
    case bank_slip_status_description?.includes('Processamento'):
      return 'bank-slip__processing';
    case bank_slip_status_description?.includes('Pago'):
      return 'bank-slip__paid';
    case bank_slip_status_description?.includes('Registrado'):
      return 'bank-slip__registered';
    case bank_slip_status_description?.includes('Rejeitado'):
      return 'bank-slip__rejected';
    default:
      return '';
  }
}

export function getBankSlipTooltip(bank_slip_status_description) {
  switch (true) {
    case bank_slip_status_description?.includes('Processamento'):
      return 'Boleto em processamento. Aguarde ser aprovado ou rejeitado';
    case bank_slip_status_description?.includes('Pago'):
      return 'Boleto Pago';
    case bank_slip_status_description?.includes('Registrado'):
      return 'Boleto aprovado pelo banco.';
    case bank_slip_status_description?.includes('Rejeitado'):
      return 'Boleto rejeitado pelo banco. Acesse o título para verificar o motivo.';
    default:
      return 'Ações do Boleto';
  }
}
