import { onlyLetters } from 'client/components/ToNormalize/ToNormalize';
import { onlyNumbersLetters } from 'client/components/ToNormalize/ToNormalize';
import { milhar, onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import React from 'react';
import { Field } from 'redux-form';
import { yearMask } from 'utils/masks';
import '../styles.css';

const CarMotobikeTruckForm = ({
  RenderField,
  handleModelChange,
  manufacturers,
  handleOpenModal,
}) => {
  return (
    <>
      <div className="form-new-vehicle__row">
        <div>
          <label>
            Obj. de Manutenção: <span style={{ color: 'red' }}>*</span>
          </label>
          <Field name="Maintenance_Object" component={RenderField} required />
        </div>

        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <label>
              Marca: <span style={{ color: 'red' }}>*</span>{' '}
            </label>

            <label>
              <a
                style={{
                  cursor: 'pointer',
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: '----',
                  textDecoration: 'none',
                }}
                onClick={() =>
                  handleOpenModal('Nova Marca', { marginBottom: '65px' })
                }
              >
                <u>Adicionar Marca</u>
              </a>
            </label>
          </div>

          <Field name="Brand" required as="select" component={RenderField}>
            <option value="">Selecione</option>
            {manufacturers?.map((child) => (
              <option key={child.Description} value={child.Description}>
                {child?.Description?.toUpperCase()}
              </option>
            ))}
          </Field>
        </div>

        <div style={{ width: 400 }}>
          <label>
            Modelo: <span style={{ color: 'red' }}>*</span>
          </label>
          <Field
            name="Model"
            component={RenderField}
            onChange={(model) => handleModelChange(model.value)}
            required
          />
        </div>
      </div>

      <div className="form-new-vehicle__row">
        <div style={{ maxWidth: '120px' }}>
          <label htmlFor="year">
            Ano <strong>Fab.</strong>:<span style={{ color: 'red' }}>*</span>
          </label>
          <Field
            name="Year_Manufacture"
            component={RenderField}
            {...yearMask}
          />
        </div>

        <div style={{ maxWidth: '130px' }}>
          <label htmlFor="modelYear">
            Ano <strong>Mod.</strong>:<span style={{ color: 'red' }}>*</span>
          </label>
          <Field name="Year_Model" component={RenderField} {...yearMask} />
        </div>

        <div>
          <label>
            KM:<span style={{ color: 'red' }}>*</span>
          </label>
          <Field
            name="Kilometers"
            required
            component={RenderField}
            normalize={(value) => milhar(onlyNumbers(value))}
            maxLength={10}
          />
        </div>

        <div>
          <label>Cor:</label>
          <Field
            name="Color"
            component={RenderField}
            normalize={(value) => onlyLetters(value.toUpperCase())}
          />
        </div>

        <div>
          <label>Chassi/Série:</label>
          <Field
            name="NChassi"
            component={RenderField}
            normalize={(value) => onlyNumbersLetters(value.toUpperCase())}
          />
        </div>

        <div>
          <label>Tipo de Combustível</label>
          <Field
            label="Tipo de Combustível"
            name="Fuel_Type"
            component="select"
            placeholder="Selecione"
            className="form-control foco-input"
          >
            <option value="">Selecione</option>
            <option value="Álcool">Álcool</option>
            <option value="Diesel">Diesel</option>
            <option value="Elétrico">Elétrico</option>
            <option value="Gás">Gás</option>
            <option value="Gasolina">Gasolina</option>
            <option value="Gasolina/Álcool">Gasolina/Álcool</option>
            <option value="Gasolina/Álcool/Gás">Gasolina/Álcool/Gás</option>
            <option value="Gasolina/Gás">Gasolina/Gás</option>
            <option value="Gás/Álcool">Gás/Álcool</option>
            <option value="Gasolina/Elétrico">
              Híbrido (Gasolina/Elétrico)
            </option>
            <option value="Outro">Outro</option>
          </Field>
        </div>
      </div>
    </>
  );
};

export default CarMotobikeTruckForm;
