export const themeOSD = {
  colors: {
    bannerText: '#251F4D',
    buttonGradient100: '#21749c',
    buttonGradient200: '#1279ab',
    buttonGradient300: '#1a5d7d',
    branding100: '#E8F1F4',
    branding200: '#D1E3E9',
    branding300: '#BBD5DE',
    branding400: '#A4C7D3',
    branding500: '#8EB9C8',
    branding600: '#77ABBD',
    branding700: '#609DB2',
    branding800: '#4A8FA7',
    branding900: '#33819C',
    branding1000: '#1d7391',
  },
};
