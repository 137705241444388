import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AlertModal from 'components/AlertModal/AlertModal'
import { useAdminAuth } from '../../../contexts/adminAuth'
import logo from '../../../assets/img/logo-painel-admin.png'

import './styles.css'

const HeaderNav = ({ history }) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)

  const { adminName, signOut } = useAdminAuth()

  function handleLogout() {
    signOut()
    history.push('/admin-login')
  }

  return (
    <div className='principal' style={{ zIndex: 100 }}>
      <div className='logo-container'>
        <img src={logo} id='logo-os' />
      </div>
      <div className='admin-header-sub-menu'>
        <span className='admin-name'>
          Olá, <span>{adminName}</span>
        </span>
        <div className='admin-header-sub-menu-content'>
          <section onClick={() => setIsLogoutModalOpen(true)}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span>Sair</span>
          </section>
        </div>
      </div>

      <AlertModal
        show={isLogoutModalOpen}
        message='Deseja realmente sair do OS Digital ?'
        onCancel={() => setIsLogoutModalOpen(false)}
        onSubmit={handleLogout}
        onHide={() => setIsLogoutModalOpen(false)}
      />
    </div>
  )
}

export default withRouter(HeaderNav)
