import React from 'react';
import { withRouter } from 'react-router-dom';
import '../style.css';
import { useBranding } from 'hooks/useBranding';

const HeaderPdv = () => {
  const {
    isWorkmotor,
    isTecnomotor,
    isBarros,
    isPO360,
    getMainLogoByBranding,
  } = useBranding();

  return (
    <div
      className={`header-pdv
        ${isWorkmotor ? 'wm-bg-primary' : ''}
        ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
        ${isBarros ? 'barros-bg-primary' : ''}
        ${isPO360 ? 'po360-bg-primary' : ''}
      `}
    >
      <img src={getMainLogoByBranding(true)} alt="" />
    </div>
  );
};

export default withRouter(HeaderPdv);
