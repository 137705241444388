import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';

import CardForm from '../../../../components/CardForm'
import FormSubmitButtons from '../../../../components/FormSubmitButtons';
import AlertModal from '../../../../../components/AlertModal';
import constants from '../../../../../utils/constants'

import MainData from './MainData'
import CloseFinancialMovementModal from './CloseFinancialMovementModal';

import './styles.css'

const FinancialMovementForm = ({ financialMovementId, handleSubmit, pristine, onCancel }) => {
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] = useState(false)
  const [isCloseFinancialMovementModalOpen, setIsCloseFinancialMovementModalOpen] = useState(false)

  const { financialMovementTypeId, description, financialMovementStatusId, destinationCashierBankId,
    finality, value, originCashierBankId, isCanceled, isClosed, cashierBankId } = useSelector(state => state.form.FinancialMovement.values)

  const isTransfer = Number(financialMovementTypeId) === constants.FINANCIAL_MOVEMENT_TYPES_ID.TRANSFER


  useEffect(() => {
    if (financialMovementStatusId) {
      handleSubmit()
    }
  }, [financialMovementStatusId])

  function onSubmit() {
    if (!isFormValid()) {
      return toastr.warning('Preencha todos os campos e selecione as opções obrigatórias para salvar a movimentação.')
    }
    setIsCloseFinancialMovementModalOpen(true)
  }

  function isFormValid() {
    if (isTransfer) {
      return validTransfer
    } else {
      return validMovement
    }
  }

  const validTransfer = (description && originCashierBankId && destinationCashierBankId && value)
  const validMovement = (description && finality && cashierBankId && value)

  return (
    <form id='Financial-movement-form'>
      <CardForm
        show
        title='Movimento de Saldo'
      >
        <MainData />
      </CardForm>


      <FormSubmitButtons
        hasId={financialMovementId}
        onCancel={() => pristine || !validTransfer || !!isClosed || !!isCanceled ? onCancel() : setIsConfirmCancelModalOpen(true)}
        onSubmit={() => !!isClosed || !!isCanceled ? onCancel() : onSubmit()}
        loading={false}
      />

      <AlertModal
        show={isConfirmCancelModalOpen}
        message='Deseja cancelar a movimentação? Nenhuma informação será salva e o cadastro poderá ser efetuado posteriormente'
        onHide={() => setIsConfirmCancelModalOpen(false)}
        onCancel={() => setIsConfirmCancelModalOpen(false)}
        onSubmit={onCancel}
      />


      <CloseFinancialMovementModal
        show={isCloseFinancialMovementModalOpen}
        onHide={() => setIsCloseFinancialMovementModalOpen(false)}
        isTransfer={isTransfer}
      />
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'FinancialMovement'
})(FinancialMovementForm)