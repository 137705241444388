import React from 'react';
import { Collapse, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './styles.css';
import { useBranding } from 'hooks/useBranding';

const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();

const CardForm = ({
  style,
  children,
  show,
  title,
  onClick,
  proceedButton,
  onProceed,
}) => (
  <div
    style={style}
    className={`
      card-form-container col-xs-12 col-sm-12 col-md-12 col-lg-12
      ${isWorkmotor ? 'wm-bg-primary-border-color' : ''}
      ${isTecnomotor ? 'tecnomotor-bg-primary-border-color' : ''}
      ${isBarros ? 'barros-bg-primary-border-color' : ''}
      ${isPO360 ? 'po360-bg-primary-border-color' : ''}
    `}
  >
    <section
      className={`card-form-container-header
        ${isWorkmotor ? 'wm-bg-primary-border-color' : ''}
        ${isTecnomotor ? 'tecnomotor-bg-primary-border-color' : ''}
        ${isBarros ? 'barros-bg-primary-border-color' : ''}
        ${isPO360 ? 'po360-bg-primary-border-color' : ''}
      `}
    >
      <div
        onClick={onClick}
        className={`
          ${isWorkmotor ? 'wm-bg-primary' : ''}
          ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
          ${isBarros ? 'barros-bg-primary' : ''}
          ${isPO360 ? 'po360-bg-primary' : ''}
        `}
      >
        <span
          className={`fa fa-angle-${show ? 'down' : 'right'}`}
          aria-hidden="true"
        />
        <span>{title}</span>
      </div>
    </section>
    <Collapse in={show}>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        {children}
        {proceedButton && (
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: '2px',
            }}
          >
            <button className="btn-green" type="button" onClick={onProceed}>
              Prosseguir
            </button>
          </Col>
        )}
      </div>
    </Collapse>
  </div>
);

CardForm.defaultProps = {
  style: {},
  show: true,
  title: '',
  onClick: () => {},
  onClickProceedButton: () => {},
};

CardForm.propTypes = {
  style: PropTypes.object,
  show: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  onClickProceedButton: PropTypes.func,
};

export default CardForm;
