import React from 'react'
import { reduxForm } from 'redux-form'

import FormSubmitButtons from '../../FormSubmitButtons'
import CardForm from '../../CardForm'
import MainData from './MainData'

const FormVehicle = ({
  onCancel,
  handleSubmit,
  vehicleId,
  loading,
  selectedModel,
  setSelectedModel,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <span
        style={{
          color: 'red',
          fontSize: 13,
          marginBottom: 5,
          alignSelf: 'flex-end',
        }}
      >
        Veículos poderão ser excluídos quando não houver vínculo com Venda/OS.
      </span>
      <span
        style={{
          color: 'red',
          fontSize: 13,
          marginBottom: 5,
          alignSelf: 'flex-end',
        }}
      >
        * Campos Obrigatórios
      </span>
      <CardForm show={true} title="Dados do Veículo">
        <MainData
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
        />
      </CardForm>

      <FormSubmitButtons
        onCancel={onCancel}
        hasId={vehicleId}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </div>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'vehicle',
})(FormVehicle)
