import React, { useEffect, useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import {
  faMoneyCheckAlt,
  faLink,
  faClipboard,
  faUser,
  faEdit,
  faIdCard,
  faSignOutAlt,
  faUsers,
  faUserShield,
  faAddressBook,
  faPiggyBank,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import conpass from '../../../services/conpass';
import CONPASS_FLOWS from '../../../utils/conpassFlows';
import { useAuth } from '../../../contexts/auth';
import api from 'services/api';
import config from 'config';
import segmentsRepository from '../../../repositories/Segments';
import usersRepository from '../../../repositories/Users';
import materaCompanyRepository from '../../../repositories/MateraCompany';
import Button from '../../components/CustomButton/CustomButton.jsx';
import AlertModal from 'components/AlertModal/AlertModal.js';
import Segmento from '../Segmentos/Segmento';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import SupportButton from '../SupportButton';
import MateraAccountBlockModal from '../../components/MateraAccountBlockModal';
import { MinStockAlertButton } from '../MinStockAlertButton';
import constants from '../../../utils/constants';

import './styles.css';

import AlertPopover from '../AlertPopover';
import { useBranding } from 'hooks/useBranding';
import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';
import { headerNavMenuItemsFeatures } from './headerNav';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { BankSlipAlertButton } from 'v2/components/Button/BankSlipAlertButton';
import { ManagementIndicatorButton } from 'v2/components/Button/ManagementIndicatorButton';

const HeaderNav = ({ history }) => {
  const { validateFeatureAvailability } = useSidebar();

  const [isSegmentsModalOpen, setIsSegmentsModalOpen] = useState(false);
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();
  const isFree = isPlanFree || isPlanStart;
  const [isNoCompanyModalOpen, setIsNoCompanyModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const { isWorkmotor, isTecnomotor, isBarros, isPO360, getNavIconByBranding } =
    useBranding();
  const [hasMateraAccountModal, setHasMateraAccountModal] = useState(false);
  const { userId, userName, companyId, signOut, clearToken } = useAuth();
  const {
    Carros,
    Motos,
    Pesados,
    Outros,
    Trator,
    Escavadeira,
    Van,
    Bicicleta,
    Colheitadeira,
  } = useSelector((state) => state.segmentoReducer);

  useEffect(() => {
    getSegmentModal();
  }, []);

  const isItemAllowedToUse = (feature, showNotAvailableReason) => {
    const isAvailable = validateFeatureAvailability(
      {
        ...feature,
      },
      showNotAvailableReason
    );

    return isAvailable;
  };

  const getSegmentModal = async () => {
    if (!companyId && userId) {
      const branchId = await usersRepository.getBranchIdByUserId(userId);
      const { data } = await segmentsRepository.getByUser(userId);

      if (
        (data.length === 0 &&
          parseInt(branchId) !== constants.BRANCHES.Outros) ||
        (data.length === 0 && parseInt(branchId) !== 5)
      ) {
        setIsSegmentsModalOpen(true);
      }
    }
  };

  const validateCompanyRegister = async () => {
    if (!isItemAllowedToUse(headerNavMenuItemsFeatures[106], true)) return;

    if (!!companyId) {
      history.push('/client/' + constants.ROUTES.COMPANIES);
    } else {
      history.push('/client/' + constants.ROUTES.COMPANY_DATA);
    }
  };

  const handleSubmit = async () => {
    const obj = {};

    if (!!Carros) obj.Carros = Carros;
    if (!!Motos) obj.Motos = Motos;
    if (!!Pesados) obj.Pesados = Pesados;
    if (!!Outros) obj.Outros = Outros;
    if (!!Trator) obj.Trator = Trator;
    if (!!Escavadeira) obj.Escavadeira = Escavadeira;
    if (!!Van) obj.Van = Van;
    if (!!Bicicleta) obj.Bicicleta = Bicicleta;
    if (!!Colheitadeira) obj.Colheitadeira = Colheitadeira;

    loopSegments(obj);
  };

  const getSegments = async () => {
    const response = await api.get(config.endpoint + 'segment');
    return response.data;
  };

  const loopSegments = async (values) => {
    const segmentsDb = await getSegments();
    let segments = values;
    if (segments) {
      segments = Object.keys(segments);
    }

    for (let i = 0; i < segmentsDb.length; i++) {
      for (let j = 0; j < segments.length; j++) {
        if (segments[j] === segmentsDb[i].Description) {
          postSegments(segmentsDb[i].id);
        }
      }
    }

    setIsSegmentsModalOpen(false);
    conpass.startFlow(CONPASS_FLOWS.INITIAL);
  };

  const postSegments = async (segmentId) => {
    await api
      .post(config.endpoint + `companySegment`, {
        Segment_id: segmentId,
        User_id: userId,
      })
      .catch(console.log);
  };

  const handleNavigateToUsers = () => {
    if (!isItemAllowedToUse(headerNavMenuItemsFeatures[105], true)) return;

    history.push('/client/' + constants.ROUTES.USERS);
  };

  const handleLogout = async () => {
    await clearToken(userId);
    signOut();
  };

  const handleNavigateToCompany = () => {
    if (!isItemAllowedToUse(headerNavMenuItemsFeatures[106], true)) return;

    setIsNoCompanyModalOpen(false);
    history.push('/client/' + constants.ROUTES.COMPANY_DATA);
  };

  const handleMyPositionPath = () => {
    if (!isItemAllowedToUse(headerNavMenuItemsFeatures[108], true)) return;

    history.push('/client/' + constants.ROUTES.POSITIONS);
  };

  const handleNavigateToMyPlan = () => {
    if (!isItemAllowedToUse(headerNavMenuItemsFeatures[107], true)) return;

    history.push('/client/' + constants.ROUTES.PLAN);
  };

  const handleMyAccessProfilesPath = () => {
    if (!isItemAllowedToUse(headerNavMenuItemsFeatures[110], true)) return;

    history.push('/client/' + constants.ROUTES.PROFILES);
  };

  const handleMyEmployeesPath = () => {
    if (!isItemAllowedToUse(headerNavMenuItemsFeatures[109], true)) return;

    history.push('/client/' + constants.ROUTES.EMPLOYEES);
  };

  const handleMyFinancialAreaPath = () => {
    if (!isItemAllowedToUse(headerNavMenuItemsFeatures[111], true)) return;

    history.push('/client/' + constants.ROUTES.MY_FINANCIAL_AREA);
  };

  const handleOpenIntegrations = () => {
    if (!isItemAllowedToUse(headerNavMenuItemsFeatures[112], true)) return;

    history.push('/client/' + constants.ROUTES.INTEGRATIONS);
  };

  return (
    <div
      className={`principal
        ${isWorkmotor ? 'wm-bg-primary' : ''}
        ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
        ${isBarros ? 'barros-bg-primary' : ''}
        ${isPO360 ? 'po360-bg-primary' : ''}
      `}
      style={{ zIndex: 2 }}
    >
      <div
        className={`logo-container
        ${isWorkmotor ? 'wm-bg-logo-container' : ''}
        ${isTecnomotor ? 'tecnomotor-bg-logo-container' : ''}
        ${isBarros ? 'barros-bg-logo-container' : ''}
        ${isPO360 ? 'po360-bg-logo-container' : ''}

        `}
      >
        <img src={getNavIconByBranding()} id="logo-os" alt="" />
      </div>
      <div className="support-buttonin-header">
        <SupportButton />
      </div>
      <div style={{ marginLeft: 10 }}>
        <MinStockAlertButton />
      </div>
      {!(isPlanFree || isPO360 || isTecnomotor) && (
        <div style={{ marginLeft: 10 }}>
          <BankSlipAlertButton />
        </div>
      )}
      <div style={{ marginLeft: 10 }}>
        <ManagementIndicatorButton />
      </div>

      <ModalAlertCompany
        show={isNoCompanyModalOpen}
        onHide={() => setIsNoCompanyModalOpen(false)}
        onClick={handleNavigateToCompany}
      />

      <AlertPopover />
      <div className="header-sub-menu">
        <span className="user-name">
          Olá, <span>{userName}</span>
        </span>
        <div
          className={`header-sub-menu-content
            ${isWorkmotor ? `wm-bg-primary` : ''}
            ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
            ${isBarros ? 'barros-bg-primary' : ''}
            ${isPO360 ? 'po360-bg-primary' : ''}
          `}
        >
          <section onClick={handleNavigateToUsers}>
            <FontAwesomeIcon
              icon={faUser}
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[105])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            />
            <span
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[105])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            >
              Meus Usuários
            </span>
          </section>

          <section onClick={validateCompanyRegister}>
            <FontAwesomeIcon
              icon={faEdit}
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[106])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            />
            <span
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[106])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            >
              Minha Empresa
            </span>
          </section>

          <section onClick={handleNavigateToMyPlan}>
            <FontAwesomeIcon
              icon={faIdCard}
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[107])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            />
            <span
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[107])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            >
              Meu Plano
            </span>
          </section>

          <section onClick={handleMyPositionPath}>
            <FontAwesomeIcon
              icon={faUsers}
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[108])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            />
            <span
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[108])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            >
              Meus Cargos
            </span>
          </section>

          <section onClick={handleMyEmployeesPath}>
            <FontAwesomeIcon
              icon={faAddressBook}
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[109])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            />
            <span
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[109])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            >
              Meus Funcionários
            </span>
          </section>

          <section onClick={handleMyAccessProfilesPath}>
            <FontAwesomeIcon
              icon={faUserShield}
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[110])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            />
            <span
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[110])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            >
              Meus Perfis de Acesso
            </span>
          </section>

          {!isPO360 && (
            <section onClick={handleMyFinancialAreaPath}>
              <FontAwesomeIcon
                icon={faMoneyCheckAlt}
                style={
                  !isItemAllowedToUse(headerNavMenuItemsFeatures[111])
                    ? { color: '#aaaaaa' }
                    : {}
                }
              />
              <span
                style={
                  !isItemAllowedToUse(headerNavMenuItemsFeatures[111])
                    ? { color: '#aaaaaa' }
                    : {}
                }
              >
                Minha Área Financeira
              </span>
            </section>
          )}

          <section onClick={handleOpenIntegrations}>
            <FontAwesomeIcon
              icon={faLink}
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[112])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            />
            <span
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[112])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            >
              Integrações
            </span>
          </section>

          {/* { <section onClick={handleOpenDigitalAccount}>
            <FontAwesomeIcon
              icon={faPiggyBank}
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[113])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            />
            <span
              style={
                !isItemAllowedToUse(headerNavMenuItemsFeatures[113])
                  ? { color: '#aaaaaa' }
                  : {}
              }
            >
              Conta Digital
            </span>
          </section> */}
          {isFree && !isPO360 && (
            <section>
              <a
                href={`${window.location.origin}/Termos`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faClipboard} />
                <span>Contrato de Licença de Uso</span>
              </a>
            </section>
          )}

          <section onClick={() => setIsLogoutModalOpen(true)}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span>Sair</span>
          </section>
        </div>
      </div>

      <Modal show={isSegmentsModalOpen} animation={true}>
        <Modal.Header>
          <Modal.Title>
            <strong style={{ fontSize: '19px' }} id="titulo-modal">
              Antes de iniciarmos, informe os veículos que trabalha
            </strong>
            <p id="subtitulo-modal">
              Clique em todos os veículos que você trabalha, pode escolher mais
              de 1 modelo
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#f8fafc' }}>
          <Segmento isGridded={true} />
          <Col style={{ padding: '10px 100px 10px 100px' }}>
            <Button
              onClick={handleSubmit}
              style={{ width: '100%' }}
              disabled={
                !Carros &&
                !Motos &&
                !Pesados &&
                !Outros &&
                !Trator &&
                !Escavadeira &&
                !Van &&
                !Bicicleta &&
                !Colheitadeira
              }
              id="btn-prosseguir"
              type="submit"
              bsStyle="info"
            >
              Concluir
            </Button>
          </Col>
        </Modal.Body>
      </Modal>

      <AlertModal
        show={isLogoutModalOpen}
        animation={false}
        message="Deseja realmente sair?"
        onHide={() => setIsLogoutModalOpen(false)}
        onCancel={() => setIsLogoutModalOpen(false)}
        onSubmit={handleLogout}
      />

      {hasMateraAccountModal && (
        <MateraAccountBlockModal
          show={hasMateraAccountModal}
          onCancel={() => setHasMateraAccountModal(false)}
        />
      )}
    </div>
  );
};

export default withRouter(HeaderNav);
