import React, { useEffect, useState } from 'react';
import { Range } from 'react-range';
import { toastr } from 'react-redux-toastr';

import {
  AddCategoryButton,
  Card,
  CardContent,
  CardTable,
  CardTitle,
  CardTableBody,
  ProfitPanelCard,
  ProfitPanelPage,
  ProfitPanelContainer,
  ProfitProjection,
  ProfitTable,
} from './ProfitPanel.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faBoxOpen,
  faMoneyBill,
  faTools,
} from '@fortawesome/free-solid-svg-icons';

import { ProfitPanelRepository } from 'v2/repositories/ProfitPanelRepository';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';
import { InputCurrency } from 'v2/components/Input';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';
import { InputText } from 'v2/components/Input';

import { CostCard } from './components/CostCard';
import { ProfitCard } from './components/ProfitCard';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';

import { useAuth } from 'contexts/auth';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';

export function ProfitPanel() {
  const [isLoading, setIsLoading] = useState(true);

  const [costsCategories, setCostsCategories] = useState([]);
  const [taxesCategories, setTaxesCategories] = useState([]);
  const [productsFamilies, setProductsFamilies] = useState([]);
  const [servicesFamilies, setServicesFamilies] = useState([]);

  const [maxMargin, setMaxMargin] = useState(100);
  const [maxQuantity, setMaxQuantity] = useState(9999);
  const [inputRoundType, setInputRoundType] = useState(false);

  const handleMarginLimits = (items) => {
    const allPositiveMargins = items
      .map((item) => item.profitMargin)
      .filter((item) => item > 0);

    const maxMargin = Math.max(...allPositiveMargins);
    setMaxMargin((maxMargin === -Infinity ? 0 : maxMargin) + 300);
  };

  const handleQuantityLimits = (items) => {
    const allQuantities = items
      .map((item) => item.quantity)
      .filter((item) => item > 0);

    const maxQuantity = Math.max(...allQuantities);
    setMaxQuantity((maxQuantity === -Infinity ? 0 : maxQuantity) + 1000);
  };

  const serializeRequestItems = (
    requestItems,
    multiplyTotalPrice,
    defaultMargin
  ) => {
    return requestItems.map((item) => {
      return {
        ...item,
        totalCost: Number(item.totalCost.toFixed(2)),
        totalPrice:
          Number(item.totalPrice.toFixed(2)) * (multiplyTotalPrice ? 2 : 1),
        profitMargin: defaultMargin || parseInt(item.profitMargin),
        profit: defaultMargin ? Number(item.totalCost.toFixed(2)) : item.profit,
        originalProfitMargin: parseInt(item.profitMargin),
        originalAveragePrice: Number(
          (item.totalPrice / item.quantity).toFixed(2)
        ),
        averagePrice: Number((item.totalPrice / item.quantity).toFixed(2)),
        averageUnitCost: (item.totalCost / item.quantity).toFixed(2),
      };
    });
  };

  const loadProfitPanelData = async () => {
    setIsLoading(true);
    try {
      const companyId = localStorage.getItem('ID_EMPRESA');

      const res = await ProfitPanelRepository.getProfitPanelInformation(
        companyId
      );

      const serializedProducts = serializeRequestItems(
        res.productsFamilies,
        false
      );
      const serializedServices = serializeRequestItems(
        res.servicesFamilies,
        false,
        100
      );

      setCostsCategories(res.costsCategories);
      setTaxesCategories(res.taxesCategories);
      setProductsFamilies(serializedProducts);
      setServicesFamilies(serializedServices);

      const allItems = [...serializedProducts, ...serializedServices];

      handleMarginLimits(allItems);
      handleQuantityLimits(allItems);
    } catch (err) {
      console.log(err);
      toastr.error(
        currentBrandingName,
        'Ocorreu um erro ao buscar as informações. Tente novamente!'
      );
    }
    setIsLoading(false);
  };

  const getMinMargin = (originalProfitMargin) => {
    if (originalProfitMargin <= 0) return originalProfitMargin;
    return 1;
  };

  const calculateProfitProjection = () => {
    const costsTotal = costsCategories.reduce(
      (acc, curr) => acc + (curr.averageValue || 0),
      0
    );

    const productsTotal = productsFamilies.reduce(
      (acc, curr) => acc + (curr.profit || 0),
      0
    );
    const servicesTotal = servicesFamilies.reduce(
      (acc, curr) => acc + (curr.profit || 0),
      0
    );

    return productsTotal + servicesTotal - costsTotal;
  };

  const handleAddCostCategory = () => {
    setCostsCategories((curr) => [
      ...curr,
      {
        id: Date.now(),
        description: '',
        averageValue: 0,
        manual: true,
      },
    ]);
  };

  const handleAddTaxCategory = () => {
    setTaxesCategories((curr) => [
      ...curr,
      {
        id: Date.now(),
        description: '',
        averageValue: 0,
        manual: true,
      },
    ]);
  };

  const updateItemAttribute = (items, index, attribute, value) => {
    return items.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...item,
          [attribute]: value,
        };
      }
      return item;
    });
  };

  const handleRemoveItem = ({ object, index }) => {
    if (object === 'costsCategories') {
      setCostsCategories((prevState) =>
        prevState.filter((_, i) => i !== index)
      );
    }

    if (object === 'taxesCategories') {
      setTaxesCategories((prevState) =>
        prevState.filter((_, i) => i !== index)
      );
      return;
    }

    return;
  };

  const handleChangeValue = ({ object, attribute, index, value }) => {
    switch (object) {
      case 'costsCategories':
        setCostsCategories((curr) =>
          updateItemAttribute(curr, index, attribute, value)
        );
        break;
      case 'taxesCategories':
        setTaxesCategories((curr) =>
          updateItemAttribute(curr, index, attribute, value)
        );
        break;
      case 'servicesFamilies':
        setServicesFamilies((curr) =>
          updateItemAttribute(curr, index, attribute, value)
        );
        break;
      case 'productsFamilies':
        setProductsFamilies((curr) =>
          updateItemAttribute(curr, index, attribute, value)
        );
        break;
      default:
        break;
    }
  };

  const updateFullItem = (items, index, itemUpdated) => {
    return items.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...item,
          ...itemUpdated,
        };
      }
      return item;
    });
  };

  const handleUpdateFullItem = (itemUpdated, index, object) => {
    switch (object) {
      case 'productsFamilies':
        setProductsFamilies((prevState) =>
          updateFullItem(prevState, index, itemUpdated)
        );
        break;
      case 'servicesFamilies':
        setServicesFamilies((prevState) =>
          updateFullItem(prevState, index, itemUpdated)
        );
        break;
      default:
        break;
    }
  };
  const handleUpdateTotalizators = ({ object, index, currentValues }) => {
    const totalPrice = Number(
      (currentValues.averagePrice * currentValues.quantity).toFixed(2)
    );
    const totalCost = currentValues.averageUnitCost * currentValues.quantity;
    const profit =
      object === 'servicesFamilies'
        ? totalPrice
        : Number((totalPrice - totalCost).toFixed(2));

    handleUpdateFullItem(
      { ...currentValues, totalPrice, totalCost, profit },
      index,
      object
    );
  };

  const handleChangeQuantity = ({ item, object, value, index }) => {
    const newValues = {
      ...item,
      quantity: value,
    };

    handleUpdateTotalizators({ object, index, currentValues: newValues });
  };

  const handleChangeMargin = ({ item, object, value, index }) => {
    const newValues = {
      ...item,
      profitMargin: value,
    };

    const profitProduct = item.totalCost * (value / 100);
    const profitProductWithCost = profitProduct + item.totalCost;

    const averagePrice =
      object === 'servicesFamilies'
        ? Number((profitProduct / item.quantity).toFixed(2))
        : Number((profitProductWithCost / item.quantity).toFixed(2));

    newValues.averagePrice = averagePrice;

    handleUpdateTotalizators({ object, index, currentValues: newValues });
  };

  useEffect(() => {
    document.title = `Simulador de Lucratividade - ${currentBrandingName}`;
    loadProfitPanelData();
  }, []);

  if (isLoading) {
    return (
      <ProfitPanelPage className="flex center">
        <LoadingSpinnerFullHeight />
      </ProfitPanelPage>
    );
  }

  return (
    <ProfitPanelPage>
      <ProfitPanelCard>
        <ProfitPanelContainer>
          <CostCard
            icon={faMoneyBill}
            title={'Custos Fixos'}
            handleAddItem={handleAddCostCategory}
            handleChangeValue={handleChangeValue}
            tableItems={costsCategories}
            objectName={'costsCategories'}
            handleRemoveItem={handleRemoveItem}
          />

          <CostCard
            icon={faMoneyBill}
            title={'Impostos'}
            handleAddItem={handleAddTaxCategory}
            handleChangeValue={handleChangeValue}
            tableItems={taxesCategories}
            objectName={'taxesCategories'}
            handleRemoveItem={handleRemoveItem}
          />
        </ProfitPanelContainer>
        <ProfitPanelContainer>
          <ProfitCard
            setInputRoundType={setInputRoundType}
            inputRoundType={inputRoundType}
            icon={faBoxOpen}
            title={'Produtos'}
            handleChangeValue={handleChangeValue}
            tableItems={productsFamilies}
            objectName={'productsFamilies'}
            calculateProfitProjection={calculateProfitProjection}
            handleChangeQuantity={handleChangeQuantity}
            handleChangeMargin={handleChangeMargin}
            maxQuantity={maxQuantity}
            getMinMargin={getMinMargin}
            maxMargin={maxMargin}
            showProfitProjection
          />

          <ProfitCard
            setInputRoundType={setInputRoundType}
            inputRoundType={inputRoundType}
            icon={faTools}
            title={'Serviços'}
            handleChangeValue={handleChangeValue}
            tableItems={servicesFamilies}
            objectName={'servicesFamilies'}
            calculateProfitProjection={calculateProfitProjection}
            handleChangeQuantity={handleChangeQuantity}
            handleChangeMargin={handleChangeMargin}
            maxQuantity={maxQuantity}
            getMinMargin={getMinMargin}
            maxMargin={maxMargin}
            hideCostColumn
          />
        </ProfitPanelContainer>
      </ProfitPanelCard>
    </ProfitPanelPage>
  );
}
