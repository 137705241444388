import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import crypto from 'crypto'
import ImageUploadDropzone from '../../../../components/ImagesUploadDropzone'
import { useDispatch, useSelector } from 'react-redux'
import { handleChange } from './redux/actions'
import { format } from 'date-fns'
import { alfanumeric } from '../../../../components/ToNormalize/ToNormalize'

export const SalePhotosAdd = () => {
  const dispatch = useDispatch()
  const { saleReducer: { photoToImport } } = useSelector(state => state)

  const handleUploadPhoto = async files => {
    let validateFiles = await handleValidateFiles(files, 1)

    if (!validateFiles) return

    const currentFile = files[0]
    const id = crypto.randomBytes(16).toString('hex')
    const file = {
      ...photoToImport,
      file: currentFile,
      id,
      name: `${id}${currentFile.name}`,
      preview: URL.createObjectURL(currentFile),
      size: currentFile.size,
      uploaded: false,
      createdAt: format(new Date(), 'yyyy-MM-dd')
    }

    dispatch(handleChange(file, 'photoToImport'))
  }

  const handleValidateFiles = async (files, quantity) => {

    if (files.length > quantity) {
      toastr.warning(`Selecione somente ${quantity} ${quantity === 1 ? 'foto' : 'fotos'}`)
      return false
    }

    for (let i = 0; i < quantity; i++) {

      if (files[i].size > 5000000) {
        toastr.warning('O tamanho máximo da foto é 5 MB. Por favor, verifique e tente novamente.')

        return false
      }

      URL.revokeObjectURL(files[i])

      return true
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ width: '50%' }}>
          <label>Descrição:<span style={{ color: 'red' }}>*</span></label>
          <input
            value={photoToImport.description}
            onChange={e => {
              dispatch(handleChange({ ...photoToImport, description: alfanumeric(e.target.value) }, 'photoToImport'))
            }}
            className="form-control foco-input"
          />
        </div>
        <span style={{ color: 'red' }}>*Campos Obrigatórios</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <span>Deverá ser importada uma foto por vez. <strong>Tamanho máximo: 5 MB</strong></span>
      </div>
      <div className="import-photo-sale">
        <ImageUploadDropzone
          image={photoToImport}
          onRemove={() => dispatch(handleChange({ description: photoToImport.description }, 'photoToImport'))}
          onUpload={handleUploadPhoto}
          label="Adicionar Foto"
        />
      </div>
    </div>
  )
}