import React from 'react';

import { Collapse } from 'react-bootstrap';

import './index.css';
import { useBranding } from 'hooks/useBranding';

export default function CollapsibleSection({
  title,
  children,
  isExpanded,
  handleExpand,
}) {
  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();

  return (
    <section className="collapsible-section-container">
      <div
        className={`
          collapsible-section-header
          ${isWorkmotor ? 'wm-bg-primary wm-bg-primary-border-color' : ''}
          ${
            isTecnomotor
              ? 'tecnomotor-bg-primary tecnomotor-bg-primary-border-color'
              : ''
          }
          ${isBarros ? 'barros-bg-primary barros-bg-primary-border-color' : ''}
          ${isPO360 ? 'po360-bg-primary barros-bg-primary-border-color' : ''}
        `}
        onClick={handleExpand}
      >
        <span
          className={`fa fa-angle-${isExpanded ? 'down' : 'right'}`}
          aria-hidden="true"
        />
        <span>{title}</span>
      </div>
      <Collapse in={isExpanded}>
        <div className="collapsible-section-content">{children}</div>
      </Collapse>
    </section>
  );
}
