import React from 'react';

import { faUsers, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import sobreRodasLogo from 'assets/img/sobre-rodas-logo.png';

import LoadingSpinnerFullHeight from './LoadingSpinnerFullHeight';
import { useWorkshopPanel } from 'contexts/workshopPanel';
import { useBranding } from 'hooks/useBranding';

const EmployeeCard = ({ name }) => {
  const splitedName = name?.split(' ');

  return (
    <div className="workshop-panel-employee-card">
      <FontAwesomeIcon icon={faUser} size={'lg'} />
      <span>
        {splitedName.length > 1
          ? `${splitedName[0]} ${splitedName[1][0]}.`
          : name}
      </span>
    </div>
  );
};

export default function AvaliableEmployees() {
  const { employees, isLoadingEmployees, isLoadingPanelInformation } =
    useWorkshopPanel();

  const { isWorkmotor, getMainLogoByBranding } = useBranding();

  return (
    <div className="workshop-panel-frame">
      <p className="workshop-panel-frame-title">
        <FontAwesomeIcon icon={faUsers} size={'lg'} />
        &nbsp; Mecânicos Disponíveis
      </p>
      <div className="workshop-panel-frame-content">
        <div className="workshop-panel-employees">
          {isLoadingEmployees || isLoadingPanelInformation ? (
            <LoadingSpinnerFullHeight />
          ) : (
            employees
              .filter((employee) => employee.show)
              ?.map((employee) => (
                <EmployeeCard key={employee.id} name={employee.name} />
              ))
          )}
        </div>

        <hr />

        <div className="sobre-rodas-logo">
          <img
            src={sobreRodasLogo}
            className="sobre-rodas-logo"
            alt="Logo do Sobre Rodas"
          />
        </div>
      </div>

      <div className="osd-logo">
        <img
          src={getMainLogoByBranding()}
          alt="Logo do OS Digital"
          className="osd-logo"
        />
      </div>
    </div>
  );
}
