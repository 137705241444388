import React, { createContext, useState, useContext } from 'react';

const ConfigurationsContext = createContext({
  updateServiceDescriptionOnSale: false,
  averageTicketPeriodInMonths: 3,
  showSheetSignatureField: 0,
  sendSmsAndEmailToRememberSchedule: false,
  sendEmailToRememberSchedule: false,
  printSaleWhenCreate: false,
  allowOrderOfServiceWithoutVehicle: false,
  closeBudgetAutomatically: false,
  daysToCloseBudgetAutomatically: 0,
  generateTitleDiff: false,
  saleItemApprovalCancellation: false,
  budgetPaymentSuggestions: false,
  lossReasonId: null,
  advertisementId: null,
  isGlobalAdActive: true,
  monthlyInterest: false,
  monthlyInterestValue: 0,
  billModel: 'A4',
  lastSaleCode: 0,
  advancedSearchType: 'Busca Geral',
  stockWriteOff: 'Fechamento da Venda',
  monthRangeOfCustomerReturnCalc: 3,
  kitRegistration: false,
  showCompanyInSobreRodas: false,
  AutoAddSingleItemToPDV: true,
  manageInternalCredit: false,
  advancedPricing: false,
  automaticFreightApportionment: false,
  automaticApportionmentOfOtherExpenses: false,
  automaticInsurancePooling: false,
  sellingPriceTypeActive: 'Preço Único',
  comissionProrate: true,
  activateWorkshopPanel: false,
  pointingByThePanel: false,
});

export default function ConfigurationsProvider({ children }) {
  const [sellingPriceType, setSellingPriceType] = useState('Preço Único');
  const [updateServiceDescriptionOnSale, setUpdateServiceDescriptionOnSale] =
    useState(false);
  const [averageTicketPeriodInMonths, setAverageTicketPeriodInMonths] =
    useState(3);
  const [showSheetSignatureField, setShowSheetSignatureField] = useState(0);
  const [
    sendSmsAndEmailToRememberSchedule,
    setSendSmsAndEmailToRememberSchedule,
  ] = useState(false);
  const [sendEmailToRememberSchedule, setSendEmailToRememberSchedule] =
    useState(false);
  const [printSaleWhenCreate, setPrintSaleWhenCreate] = useState(false);
  const [
    allowOrderOfServiceWithoutVehicle,
    setAllowOrderOfServiceWithoutVehicle,
  ] = useState(false);
  const [closeBudgetAutomatically, setCloseBudgetAutomatically] =
    useState(false);
  const [daysToCloseBudgetAutomatically, setDaysToCloseBudgetAutomatically] =
    useState(0);
  const [generateTitleDiff, setGenerateTitleDiff] = useState(false);
  const [saleItemApprovalCancellation, setSaleItemApprovalCancellation] =
    useState(false);
  const [budgetPaymentSuggestions, setBudgetPaymentSuggestions] =
    useState(false);
  const [lossReasonId, setLossReasonId] = useState(null);
  const [advertisementId, setAdvertisementId] = useState(null);
  const [isGlobalAdActive, setIsGlobalAdActive] = useState(true);
  const [monthlyInterest, setMonthlyInterest] = useState(false);
  const [monthlyInterestValue, setMonthlyInterestValue] = useState(0);
  const [billModel, setBillModel] = useState('A4');
  const [lastSaleCode, setLastSaleCode] = useState(0);
  const [advancedSearchType, setAdvancedSearchType] = useState('Busca Geral');
  const [stockWriteOff, setStockWriteOff] = useState('Fechamento da Venda');
  const [monthRangeOfCustomerReturnCalc, setMonthRangeOfCustomerReturnCalc] =
    useState(3);
  const [kitRegistration, setKitRegistration] = useState(false);
  const [showCompanyInSobreRodas, setShowCompanyInSobreRodas] = useState(false);
  const [AutoAddSingleItemToPDV, setAutoAddSingleItemToPDV] = useState(true);
  const [manageInternalCredit, setManageInternalCredit] = useState(false);
  const [advancedPricing, setAdvancedPricing] = useState(false);
  const [automaticFreightApportionment, setAutomaticFreightApportionment] =
    useState(false);
  const [
    automaticApportionmentOfOtherExpenses,
    setAutomaticApportionmentOfOtherExpenses,
  ] = useState(false);
  const [automaticInsurancePooling, setAutomaticInsurancePooling] =
    useState(false);
  const [comissionProrate, setComissionProrate] = useState(true);
  const [activateWorkshopPanel, setActivateWorkshopPanel] = useState(false);
  const [pointingByThePanel, setPointingByThePanel] = useState(false);

  const getConfigurationsData = (company) => {
    const {
      AutoAddSingleItemToPDV,
      activateWorkshopPanel,
      advancedPricing,
      advancedSearchType,
      advertisementId,
      allowOrderOfServiceWithoutVehicle,
      automaticApportionmentOfOtherExpenses,
      automaticFreightApportionment,
      automaticInsurancePooling,
      averageTicketPeriodInMonths,
      billModel,
      budgetPaymentSuggestions,
      closeBudgetAutomatically,
      comissionProrate,
      daysToCloseBudgetAutomatically,
      generateTitleDiff,
      isGlobalAdActive,
      kitRegistration,
      lastSaleCode,
      lossReasonId,
      manageInternalCredit,
      monthRangeOfCustomerReturnCalc,
      monthlyInterest,
      monthlyInterestValue,
      pointingByThePanel,
      printSaleWhenCreate,
      saleItemApprovalCancellation,
      sellingPriceType,
      sendEmailToRememberSchedule,
      sendSmsAndEmailToRememberSchedule,
      showCompanyInSobreRodas,
      showSheetSignatureField,
      stockWriteOff,
      updateServiceDescriptionOnSale,
    } = company?.companyConfig;
    setSellingPriceType(sellingPriceType);
    setUpdateServiceDescriptionOnSale(updateServiceDescriptionOnSale);
    setAverageTicketPeriodInMonths(averageTicketPeriodInMonths);
    setShowSheetSignatureField(showSheetSignatureField);
    setSendSmsAndEmailToRememberSchedule(sendSmsAndEmailToRememberSchedule);
    setSendEmailToRememberSchedule(sendEmailToRememberSchedule);
    setPrintSaleWhenCreate(printSaleWhenCreate);
    setAllowOrderOfServiceWithoutVehicle(allowOrderOfServiceWithoutVehicle);
    setCloseBudgetAutomatically(closeBudgetAutomatically);
    setDaysToCloseBudgetAutomatically(daysToCloseBudgetAutomatically);
    setGenerateTitleDiff(generateTitleDiff);
    setSaleItemApprovalCancellation(saleItemApprovalCancellation);
    setBudgetPaymentSuggestions(budgetPaymentSuggestions);
    setLossReasonId(lossReasonId);
    setAdvertisementId(advertisementId);
    setIsGlobalAdActive(isGlobalAdActive);
    setMonthlyInterest(monthlyInterest);
    setMonthlyInterestValue(monthlyInterestValue);
    setBillModel(billModel);
    setLastSaleCode(lastSaleCode);
    setAdvancedSearchType(advancedSearchType);
    setStockWriteOff(stockWriteOff);
    setMonthRangeOfCustomerReturnCalc(monthRangeOfCustomerReturnCalc);
    setKitRegistration(kitRegistration);
    setShowCompanyInSobreRodas(showCompanyInSobreRodas);
    setAutoAddSingleItemToPDV(AutoAddSingleItemToPDV);
    setManageInternalCredit(manageInternalCredit);
    setAdvancedPricing(advancedPricing);
    setAutomaticFreightApportionment(automaticFreightApportionment);
    setAutomaticApportionmentOfOtherExpenses(
      automaticApportionmentOfOtherExpenses
    );
    setAutomaticInsurancePooling(automaticInsurancePooling);
    setComissionProrate(comissionProrate);
    setActivateWorkshopPanel(activateWorkshopPanel);
    setPointingByThePanel(pointingByThePanel);
  };

  return (
    <ConfigurationsContext.Provider
      value={{
        sellingPriceType,
        updateServiceDescriptionOnSale,
        averageTicketPeriodInMonths,
        showSheetSignatureField,
        sendSmsAndEmailToRememberSchedule,
        sendEmailToRememberSchedule,
        printSaleWhenCreate,
        allowOrderOfServiceWithoutVehicle,
        closeBudgetAutomatically,
        daysToCloseBudgetAutomatically,
        generateTitleDiff,
        saleItemApprovalCancellation,
        budgetPaymentSuggestions,
        lossReasonId,
        advertisementId,
        isGlobalAdActive,
        monthlyInterest,
        monthlyInterestValue,
        billModel,
        lastSaleCode,
        advancedSearchType,
        stockWriteOff,
        monthRangeOfCustomerReturnCalc,
        kitRegistration,
        showCompanyInSobreRodas,
        AutoAddSingleItemToPDV,
        manageInternalCredit,
        advancedPricing,
        automaticFreightApportionment,
        automaticApportionmentOfOtherExpenses,
        automaticInsurancePooling,
        sellingPriceType,
        comissionProrate,
        activateWorkshopPanel,
        pointingByThePanel,

        getConfigurationsData,
      }}
    >
      {children}
    </ConfigurationsContext.Provider>
  );
}

export function useConfigurationsContext() {
  return useContext(ConfigurationsContext);
}
