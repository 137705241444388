import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import Logo from 'assets/img/OsDigitalLogo.png';
import RenderQuickQuote from './RenderQuickQuote';
import RenderQuickQuoteMobile from './RenderQuickQuoteMobile';
import quickQuoteRepository from '../../../repositories/QuickQuotes';

import './styles.css';
import { useBranding } from 'hooks/useBranding';

const QuickQuotePrint = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [quickQuote, setQuickQuote] = useState(null);
  const [width, setWindowWidth] = useState(0);

  const {
    isWorkmotor,
    isTecnomotor,
    isBarros,
    isPO360,
    getMainLogoByBranding,
  } = useBranding();

  useEffect(() => {
    const getSaleIdFromPathName = () =>
      location.pathname
        .split('Vend=v723dha5tHSSpS83711')[1]
        ?.split('5422hsGsdAQLk8423oPL31')[0];
    const quickQuoteId = getSaleIdFromPathName();
    if (!!quickQuoteId) {
      loadQuickQuote(quickQuoteId);
    }
  }, []);
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const responsive = {
    showMobileVersion: width < 700,
  };

  const loadQuickQuote = async (quickQuoteId) => {
    try {
      const { data } = await quickQuoteRepository.getById(quickQuoteId, {
        ['x-force-auth']: true,
      });
      setQuickQuote(data);
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    document.title = `OS Digital - Orçamento Rápido #${quickQuote?.code}`;
    window.print();
    document.title = 'OS Digital';
  };

  if (loading) {
    return (
      <LoadingOverlay
        active={true}
        spinner
        text="Aguarde..."
        classNamePrefix="MyLoader_"
        fadeSpeed={100}
      />
    );
  }

  if (!loading && error) {
    return <div id="impLoad">Falha ao carregar dados</div>;
  }

  return (
    <div id="content">
      <div
        className={`blueBar  ${isWorkmotor ? 'wm-bg-primary' : ''}
      ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
      ${isBarros ? 'barros-bg-primary' : ''}
      ${isPO360 ? 'po360-bg-primary' : ''}`}
      />
      <div className="impHeader">
        <button onClick={handlePrint} style={{ marginTop: '10px' }}>
          <span className="fa fa-print" />
          Imprimir
        </button>
      </div>
      <div id="toprint">
        {responsive.showMobileVersion ? (
          <RenderQuickQuoteMobile quickQuote={quickQuote} />
        ) : (
          <RenderQuickQuote quickQuote={quickQuote} />
        )}
      </div>
      <div id="impFooter">
        <img
          style={{
            width: '200px',
            height: 'auto',
            borderRadius: '10px',
            padding: '5px',
          }}
          src={getMainLogoByBranding()}
          alt="Logo"
        />
      </div>
      <div
        className={`blueBar  ${isWorkmotor ? 'wm-bg-primary' : ''}
      ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
      ${isBarros ? 'barros-bg-primary' : ''}
      ${isPO360 ? 'po360-bg-primary' : ''}`}
      />{' '}
    </div>
  );
};

export default QuickQuotePrint;
