import config from '../config';
import api from '../services/api';
import { currentBrandingId } from 'v2/helpers/brandingHelpers';

const index = async () => {
  try {
    const response = await api.get(config.endpoint + 'videos-links');
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getAll = async () => {
  try {
    const response = await api.get(
      config.endpoint + 'videos-links/' + currentBrandingId
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getById = async (videosLinksId) => {
  try {
    const response = await api.get(
      config.endpoint + `videos-links/single/${videosLinksId}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
const create = (values) => api.post(config.endpoint + 'videos-links', values);

const update = (videosLinksId, values) =>
  api.put(config.endpoint + `videos-links/${videosLinksId}`, values);

const deleteVideosLinks = async (videosLinksId) => {
  return api.delete(config.endpoint + `videos-links/${videosLinksId}`);
};

export default { getAll, create, update, deleteVideosLinks, index, getById };
