import React, { useState } from 'react';
import { format, isValid, subDays } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { useAuth } from 'contexts/auth';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { SelectMulti } from 'v2/components/Select/Multi';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PDFButton from 'client/components/PDFButton';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { useEffect } from 'react';
import SalesPerDayReportRepository from 'v2/repositories/SalesPerDayReportRepository';

const HomeSalesPerDay = () => {
  const { companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [typeOfDate, setTypeOfDate] = useState('byCreation');
  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [isReportReady, setIsReportReady] = useState(false);
  const [reportData, setReportData] = useState({});

  useEffect(() => {
    if (typeOfDate && initialDate && finalDate) {
      handleSubmit();
      setIsReportReady(true);
    } else {
      setIsReportReady(false);
    }
  }, [typeOfDate, initialDate, finalDate]);

  useEffect(() => {
    if (reportData?.report?.length) {
      setIsReportReady(true);
    }
  }, [reportData]);

  const handleExportPDF = () => {
    handlePrint();
  };

  const handleSubmit = async () => {
    if (validations()) handleProcess();
  };

  const validations = () => {
    if (!isValid(new Date(initialDate)) || !isValid(new Date(finalDate))) {
      toastr.warning(
        'Não foi possível gerar o relatório.',
        ' Preencha todos os campos e tente novamente'
      );
      return false;
    }
    return true;
  };

  const handleProcess = async () => {
    setLoading(true);
    try {
      const data = await SalesPerDayReportRepository.generateSalesPerDayReport({
        initialDate,
        finalDate,
        typeOfDate,
        companyId,
      });

      if (data?.report.length) {
        setReportData(data);
      } else {
        return toastr.warning(
          'Nenhuma informação para o relatório foi encontrada.'
        );
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        err?.response?.data?.message ||
          'Ocorreu um erro com a buscar. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    window.open(
      window.location.origin +
        `/report/sales-per-day?companyId=${companyId}&typeOfDate=${typeOfDate}&initialDate=${initialDate}&finalDate=${finalDate}`,
      '_blank'
    );
  };

  return (
    <div className="content-invoices-report">
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Tipo de Data:</InputLabel>
            <select
              className="form-control foco-input"
              value={typeOfDate}
              onChange={(e) => setTypeOfDate(e.target.value)}
              style={{ width: '200px' }}
            >
              <option value="byCreation">Criação da Venda</option>
              <option value="byClosing">Fechamento da Venda</option>
            </select>
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Inicial:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div className="flex flex-start gap-025">
          <InputContainer variant="column">
            <InputLabel>Data Final:</InputLabel>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
            />
          </InputContainer>
        </div>
        <div
          className="gap-025"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <InputContainer variant="column">
            <PDFButton
              className="component__pdf-button"
              onClick={handleExportPDF}
              disabled={loading || !isReportReady}
              isLoading={loading}
            />
          </InputContainer>
        </div>
        <div
          className="gap-025"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <InputContainer variant="column">
            <DownloadXlsButton
              archiveName="vendas_por_dia"
              data={reportData?.report}
              className="btn btn-export"
              style={{ height: '35px' }}
              disabled={loading || !isReportReady}
              columns={[
                {
                  name: 'DATA',
                  acessor: `date`,
                },
                {
                  name: 'VALOR BRUTO',
                  acessor: 'grossValue',
                },
                {
                  name: 'DESCONTOS GERAIS',
                  acessor: 'generalDiscounts',
                },
                {
                  name: 'PRODUTO',
                  acessor: 'productValue',
                },
                {
                  name: 'CUSTO',
                  acessor: 'productCost',
                },
                {
                  name: 'MARGEM',
                  acessor: 'margin',
                },
                {
                  name: 'VALOR SERVIÇO',
                  acessor: 'ServiceValue',
                },
                {
                  name: 'VALOR LÍQUIDO',
                  acessor: 'liquidValue',
                },
              ]}
            >
              {loading ? (
                <>
                  <span
                    className="fa fa-spinner fa-pulse fa-1x"
                    style={{ marginRight: '5px' }}
                  ></span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                </>
              )}
              Exportar .xls
            </DownloadXlsButton>
          </InputContainer>
        </div>
      </div>
    </div>
  );
};

export default HomeSalesPerDay;
