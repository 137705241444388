import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { change, initialize } from 'redux-form'
import { format, addHours } from 'date-fns'

import constants from '../../../../../utils/constants'
import { useAuth } from '../../../../../contexts/auth'
import FinancialMovementForm from './FinancialMovementForm'
import { financialMovementsRepository } from 'repositories/FinancialMovements'

const FinancialMovementBundle = ({
  financialMovementId,
  onCancel,
  onSubmit,
}) => {
  const { companyId } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!financialMovementId) loadMovement()
  }, [financialMovementId])

  async function loadMovement() {
    const res = await financialMovementsRepository.getById(financialMovementId)

    const createdAt = format(new Date(res.createdAt), 'dd/MM/yyyy HH:mm')
    const endedIn = format(new Date(res.endedIn), 'dd/MM/yyyy HH:mm')
    const canceledIn = format(new Date(res.canceledIn), 'dd/MM/yyyy HH:mm')

    const isExit = res.finality === constants.FINANCIAL_MOVEMENTS_FINALITY.EXIT
    const isTransfer =
      res.financialMovementTypeId ===
      constants.FINANCIAL_MOVEMENT_TYPES_ID.TRANSFER

    const initialValues = {
      financialMovementTypeId: String(res.financialMovementTypeId),
      description: res.description,
      finality: res.finality,
      value: res.value,
      observation: res.observation,
      code: res.code,
      createdAt: createdAt,
      endedIn: endedIn,
      canceledIn: canceledIn,
      isClosed:
        res.financialMovementStatusId ===
        constants.FINANCIAL_MOVEMENT_STATUS_ID.CLOSED,
      isCanceled:
        res.financialMovementStatusId ===
        constants.FINANCIAL_MOVEMENT_STATUS_ID.CANCELED,
      disabled:
        res.financialMovementStatusId ===
          constants.FINANCIAL_MOVEMENT_STATUS_ID.CLOSED ||
        res.financialMovementStatusId ===
          constants.FINANCIAL_MOVEMENT_STATUS_ID.CANCELED,
    }
    if (isTransfer) {
      initialValues.originCashierBankId = res.originCashierBankId
      initialValues.destinationCashierBankId = res.destinationCashierBankId
    } else if (isExit) {
      initialValues.cashierBankId = res.originCashierBankId
    } else {
      initialValues.cashierBankId = res.destinationCashierBankId
    }

    dispatch(initialize('FinancialMovement', initialValues))
  }

  function handleSubmit(values) {
    if (!financialMovementId) {
      return create(values)
    } else {
      return update(values)
    }
  }

  async function create(values) {
    const {
      description,
      observation,
      originCashierBankId,
      cashierBankId,
      destinationCashierBankId,
      finality,
      financialMovementStatusId,
      financialMovementTypeId,
      value,
      isDevolution,
    } = values

    const isTransfer =
      financialMovementTypeId ===
      String(constants.FINANCIAL_MOVEMENT_TYPES_ID.TRANSFER)
    const isOpen =
      financialMovementStatusId === constants.FINANCIAL_MOVEMENT_STATUS_ID.OPEN
    const isExit =
      Number(finality) === constants.FINANCIAL_MOVEMENTS_FINALITY.EXIT

    try {
      const values = {
        financialMovementTypeId,
        createdAt: new Date().getTime(),
        description,
        finality,
        value,
        observation,
        financialMovementStatusId,
        companyId,
      }

      if (isTransfer) {
        values.originCashierBankId = originCashierBankId
        values.destinationCashierBankId = destinationCashierBankId
      } else if (isExit) {
        values.originCashierBankId = cashierBankId
      } else {
        values.destinationCashierBankId = cashierBankId
      }

      const financialMovement = await financialMovementsRepository.create(
        values
      )

      if (!isDevolution) {
        toastr.success(
          `${
            isOpen
              ? 'Movimentação em aberta criada com sucesso'
              : `Movimentação finalizada e saldo ${
                  isTransfer ? 'transferido' : 'movimentado'
                } com sucesso`
          }`
        )
      }

      onSubmit(financialMovement)
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao salvar a Movimentação de Saldo. Por favor, tente novamente'
      )
    }
  }

  async function update(values) {
    const {
      description,
      observation,
      originCashierBankId,
      destinationCashierBankId,
      finality,
      financialMovementStatusId,
      financialMovementTypeId,
      value,
      isClosed,
      isDevolution,
    } = values

    const isTransfer =
      financialMovementTypeId ===
      String(constants.FINANCIAL_MOVEMENT_TYPES_ID.TRANSFER)
    const isOpen =
      financialMovementStatusId === constants.FINANCIAL_MOVEMENT_STATUS_ID.OPEN

    let endedIn = ''
    if (
      !isClosed &&
      financialMovementStatusId ===
        constants.FINANCIAL_MOVEMENT_STATUS_ID.CLOSED
    ) {
      endedIn = new Date().getTime()
    }

    try {
      await financialMovementsRepository.update(
        {
          financialMovementTypeId,
          endedIn,
          description,
          originCashierBankId,
          destinationCashierBankId,
          finality,
          value,
          observation,
          financialMovementStatusId,
          companyId,
        },
        financialMovementId
      )

      if (!isDevolution) {
        toastr.success(
          `${
            isOpen
              ? 'Movimentação em aberta atualizada com sucesso'
              : `Movimentação finalizada e saldo ${
                  isTransfer ? 'transferido' : 'movimentado'
                } com sucesso`
          }`
        )
      }

      onSubmit()
    } catch (err) {
      console.error(err)
      toastr.warning(
        'Ocorreu um erro ao atualizar a Movimentação de Saldo. Por favor, tente novamente'
      )
    }
  }

  const initialValues = {
    financialMovementTypeId: String(
      constants.FINANCIAL_MOVEMENT_TYPES_ID.MOVEMENT
    ),
    disabled: false,
    isClosed: false,
    isCanceled: false,
  }

  return (
    <FinancialMovementForm
      onSubmit={handleSubmit}
      financialMovementId={financialMovementId}
      initialValues={initialValues}
      onCancel={onCancel}
    />
  )
}

export default FinancialMovementBundle
