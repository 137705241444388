/* REACT */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Toggle from 'react-toggle';
import { useForm } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

/* COMPONENTS */
import AlertModal from 'components/AlertModal/AlertModal';
import CardForm from 'client/components/CardForm';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import {
  cpfOrCnpjMask,
  phoneMask,
} from 'client/components/ToNormalize/ToNormalize';

/* REPOSITORIES */
import cashierBankRepository from 'repositories/CashierBank';
import bankBilletAccountRepository from 'repositories/BankBilletAccount';

import './styles.css';
import SelectPaidCompanies from 'admin/v2/components/Input/Select/SelectPaidCompanies';

const BankBilletAccountForm = () => {
  const [bankOptions, setBankOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [alreadyRegisteredBilletIds, setAlereadyRegisteredBilletIds] = useState(
    []
  );
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] =
    useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { id: accountId } = useParams();

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      type: '',
      cpfCnpj: '',
      companyName: '',
      tradingName: '',
      email: '',
      phone: '',
      contact: '',
      bankBilletId: '',
      bankId: '',
      status: true,
    },
  });

  const history = useHistory();

  function handleCancelConfirmation() {
    if (isDirty) {
      setIsCancelConfirmationModalOpen(true);
    } else {
      onCancel();
    }
  }

  function handleStatusChange() {
    const currStatus = watch('status');
    setValue('status', !currStatus);
  }

  function handleCompanySelect(item) {
    fillFormWithCompanyData(item.value);
    setSelectedCompany(item.value);
  }

  function handleResetCompanySelect() {
    setSelectedCompany(null);
    reset({
      type: '',
      cpfCnpj: '',
      companyName: '',
      tradingName: '',
      email: '',
      phone: '',
      contact: '',
      bankBilletId: '',
      bankId: '',
      status: true,
    });
  }

  function fillFormWithCompanyData(company) {
    setValue('type', company.Type);
    setValue('cpfCnpj', cpfOrCnpjMask(company.Cpf_Cnpj));
    setValue('companyName', company.Company_Name);
    setValue('tradingName', company.Trading_Name);
    setValue('email', company.Email);
    setValue('phone', phoneMask(company.Phones[0].Number_Phone1));
    setValue('contact', company.Phones[0].Contact);
    setValue('companyId', company.id);
  }

  function onCancel() {
    return history.push('/admin/ticket-issuance');
  }

  function bankBilletIdAlreadyRegistered(id) {
    for (let item of alreadyRegisteredBilletIds) {
      if (item.id === id) {
        console.log(item.companyId);
        console.log(selectedCompany);
        if (accountId && item.companyId === selectedCompany.id) return false;
        return true;
      }
    }
  }

  async function onSubmit(data) {
    console.log({ data });
    if (bankBilletIdAlreadyRegistered(data.bankBilletId)) {
      return toastr.warning(
        'Já existe uma carteira cadastrada com esse ID. Verifique as carteiras cadastradas.'
      );
    }

    if (!selectedCompany) {
      return toastr.warning('Selecione um cliente para cadastrar a carteira');
    }

    if (data.bankBilletId.trim().length === 0) {
      return toastr.warning('Preencha o ID da carteira');
    }

    if (!data.bankId) {
      return toastr.warning('Selecione o banco que a carteira é vinculada');
    }

    try {
      setIsSubmiting(true);
      if (!accountId) {
        await bankBilletAccountRepository.create({
          companyId: data.companyId,
          status: data.status,
          bankBilletId: data.bankBilletId.trim(),
          bankId: data.bankId,
        });
        toastr.success('Carteira cadastrada com sucesso!');
      } else {
        await bankBilletAccountRepository.update(accountId, {
          status: data.status,
          bankBilletId: data.bankBilletId,
          bankId: data.bankId,
        });
        toastr.success('Carteira atualizada com sucesso!');
      }
      history.push('/admin/ticket-issuance');
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar os cadatrar a carteira. Tente novamente!'
      );
    } finally {
      setIsSubmiting(false);
    }
  }

  useEffect(() => {
    async function getCompanyWithAccount() {
      try {
        const { data: companiesWithAccount } =
          await bankBilletAccountRepository.index();

        setAlereadyRegisteredBilletIds(
          companiesWithAccount.rows.map((item) => {
            return { id: item.bankBilletId, companyId: item.CompanyId };
          })
        );
      } catch (err) {
        console.log(err);
        toastr.error(
          'Ocorreu um erro ao carregar os clientes. Tente novamente!'
        );
        history.push('/admin/ticket-issuance');
      }
    }

    async function getBanks() {
      try {
        const res = await cashierBankRepository.getBanks();
        setBankOptions(res);
      } catch (err) {
        console.log(err);
        toastr.error('Ocorreu um erro ao carregar os bancos. Tente novamente!');
        history.push('/admin/ticket-issuance');
      }
    }
    getCompanyWithAccount();
    getBanks();
  }, []);

  useEffect(() => {
    async function getAccountToEdit() {
      try {
        const res = await bankBilletAccountRepository.show(accountId);
        const option = {
          value: {
            ...res.data.Company,
            id: res.data.companyId,
          },
          label: `${res.data.Company.Company_Name} - ${cpfOrCnpjMask(
            res.data.Company.Cpf_Cnpj
          )}`,
        };
        handleCompanySelect(option);
        setValue('bankBilletId', res.data.bankBilletId);
        setValue('bankId', res.data.bankId);
        setValue('status', res.data.status);
      } catch (err) {
        console.log(err);
        toastr.error(
          'Ocorreu um erro ao buscar os dados da carteira. Tenta novamente!'
        );
        history.push('/admin/ticket-issuance');
      }
    }

    if (accountId) getAccountToEdit();
  }, [accountId]);

  return (
    <main className="bank-billet-acc-form">
      <section className="search-bar">
        <SelectPaidCompanies
          onChange={handleCompanySelect}
          value={selectedCompany}
          isLoading={isSubmiting}
          noOptionsMessage={() => `Nenhum cliente encontrado`}
          disabled={!!accountId}
        />
      </section>
      <section className="form">
        <span
          style={{
            color: 'red',
            fontSize: 13,
            marginBottom: 5,
            alignSelf: 'flex-end',
          }}
        >
          * Campos Obrigatórios
        </span>
        <CardForm show={!!selectedCompany} title="Dados do Cliente">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Col xs={12} sm={6} md={2} lg={2}>
                <label
                  htmlFor="type"
                  className="label-form"
                  style={{ width: '100%' }}
                >
                  Tipo de Pessoa:
                </label>
                <br />
                <input
                  id="type"
                  className="form-control foco-input"
                  disabled="disabled"
                  type="text"
                  {...register('type')}
                />
              </Col>
              <Col xs={12} sm={6} md={2} lg={2}>
                <div>
                  <label>Status:</label>
                  <br />
                  <Toggle
                    id="status"
                    checked={watch('status')}
                    name="status"
                    style={{ position: 'fixed' }}
                    readOnly
                    onClick={() => handleStatusChange()}
                  />
                </div>
              </Col>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Col xs={12} sm={6} md={2} lg={2}>
                <label
                  htmlFor="type"
                  className="label-form"
                  style={{ width: '100%' }}
                >
                  CNPJ:
                </label>
                <br />
                <input
                  id="cpfCnpj"
                  name="cpfCnpj"
                  className="form-control foco-input"
                  disabled="disabled"
                  type="text"
                  {...register('cpfCnpj', { required: true })}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <label
                  htmlFor="companyName"
                  className="label-form"
                  style={{ width: '100%' }}
                >
                  Razão Social:
                </label>
                <br />
                <input
                  id="companyName"
                  name="companyName"
                  className="form-control foco-input"
                  disabled="disabled"
                  type="text"
                  {...register('companyName')}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <label
                  htmlFor="tradingName"
                  className="label-form"
                  style={{ width: '100%' }}
                >
                  Nome Fantasia:
                </label>
                <br />
                <input
                  id="tradingName"
                  name="tradingName"
                  className="form-control foco-input"
                  disabled="disabled"
                  type="text"
                  {...register('tradingName')}
                />
              </Col>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Col xs={12} sm={6} md={3} lg={3}>
                <label
                  htmlFor="email"
                  className="label-form"
                  style={{ width: '100%' }}
                >
                  E-mail:
                </label>
                <br />
                <input
                  id="email"
                  name="email"
                  className="form-control foco-input"
                  disabled="disabled"
                  type="email"
                  {...register('email')}
                />
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <label
                  htmlFor="phone"
                  className="label-form"
                  style={{ width: '100%' }}
                >
                  Telefone:
                </label>
                <br />
                <input
                  id="phone"
                  name="phone"
                  className="form-control foco-input"
                  disabled="disabled"
                  type="text"
                  {...register('phone')}
                />
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <label
                  htmlFor="contact"
                  className="label-form"
                  style={{ width: '100%' }}
                >
                  Responsável:
                </label>
                <br />
                <input
                  id="contact"
                  name="contact"
                  className="form-control foco-input"
                  disabled="disabled"
                  type="text"
                  {...register('contact')}
                />
              </Col>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Col xs={12} sm={6} md={2} lg={2}>
                <label
                  htmlFor="type"
                  className="label-form"
                  style={{ width: '100%' }}
                >
                  ID da Carteira:
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <input
                  id="bankBilletId"
                  name="bankBilletId"
                  className="form-control foco-input"
                  type="text"
                  {...register('bankBilletId', { required: true })}
                />
              </Col>
              <Col xs={12} sm={6} md={2} lg={2}>
                <label
                  htmlFor="type"
                  className="label-form"
                  style={{ width: '100%' }}
                >
                  {/* TO DO: label dinâmica de acordo com type */}
                  Banco:
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <br />
                <select
                  id="bankId"
                  name="bankId"
                  className="form-control foco-input"
                  {...register('bankId', { required: true })}
                >
                  <option value={''} disabled>
                    Selecione
                  </option>
                  {bankOptions.map(({ id, code, name }) => (
                    <option key={id} value={id}>
                      {code} - {name}
                    </option>
                  ))}
                </select>
              </Col>
            </Col>
          </form>
        </CardForm>
      </section>
      <section className="submit-buttons">
        <FormSubmitButtons
          hasId={!!accountId}
          loading={isSubmiting}
          onCancel={() => handleCancelConfirmation()}
          onSubmit={handleSubmit(onSubmit)}
          disabledSubmit={isSubmiting}
          backLabel={!!accountId}
        />
      </section>
      <AlertModal
        show={isCancelConfirmationModalOpen}
        title="OS Digital"
        onHide={() => setIsCancelConfirmationModalOpen(false)}
        onCancel={() => setIsCancelConfirmationModalOpen(false)}
        message={
          <>
            <p>
              <strong>
                Você está cancelando{' '}
                {accountId ? 'a atualização' : 'o cadastro'} da carteira
              </strong>
            </p>
            <p>
              Você tem certeza que deseja cancelar{' '}
              {accountId ? 'a atualização' : 'o cadastro'}? &nbsp;
              {accountId
                ? ''
                : 'O cliente não poderá emitir boletos com essa carteira. '}
              Ela poderá ser {accountId ? 'atualizada' : 'cadastrada'} novamente
              mais tarde.
            </p>
          </>
        }
        onSubmit={onCancel}
      />
    </main>
  );
};

export default BankBilletAccountForm;
