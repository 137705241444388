import { atom } from 'recoil';

export const FEATURE_NOT_AVAILABLE_MODAL_STATE = atom({
  key: 'isFeatureNotAvailableModalOpen',
  default: false,
});

export const INACTIVE_MODULE_MODAL_STATE = atom({
  key: 'isModuleInactiveModalOpen',
  default: false,
});

export const CURRENT_INACTIVE_MODULE = atom({
  key: 'currentInactiveModule',
  default: {},
});

export const CURRENT_INACTIVE_FISCAL_MODULE = atom({
  key: 'currentInactiveFiscalModule',
  default: {},
});

export const INACTIVE_FISCAL_MODULE_MODAL_STATE = atom({
  key: 'isFiscalModuleInactiveModalOpen',
  default: false,
});

export const INACTIVE_FISCAL_MODULE_PLAN_VARIATION_MODAL_STATE = atom({
  key: 'isFiscalModuleInactivePlanVariationModalOpen',
  default: false,
});

export const BLOCK_SEGMENTS_MODAL_OPEN = atom({
  key: 'isSegmentsBlockedModalOpen',
  default: false,
});
