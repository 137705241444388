import { currentBranding } from 'v2/helpers/brandingHelpers';

import { themeOSD } from './themeOSD';
import { themeWM } from './themeWM';
import { themeTecnomotor } from './themeTecnomotor';
import { themeBarros } from './themeBarros';
import { themePO360 } from './themePO360';

function getBrandingTheme() {
  switch (currentBranding) {
    case 'OSD':
      return themeOSD;
    case 'WM':
      return themeWM;
    case 'TECNOMOTOR':
      return themeTecnomotor;
    case 'BARROS':
      return themeBarros;
    case 'PO360':
      return themePO360;
    default:
      return themeOSD;
  }
}

export const themeBranding = getBrandingTheme();
