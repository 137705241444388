import React from 'react';
import { useAuth } from '../../contexts/auth';
import ClientInitialRoutes from './initial.routes';
import ClientLoggedRoutes from './logged.routes';
import { useBranding } from 'hooks/useBranding';
import { useEffect } from 'react';

export const AppLoading = ({ children }) => {
  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();

  return (
    <div
      id={`app-loading`}
      className={`
        ${isWorkmotor ? 'wm-bg-primary' : ''}
        ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
        ${isBarros ? 'barros-bg-primary' : ''}
        ${isPO360 ? 'po360-bg-primary' : ''}
      `}
    >
      {children || 'Carregando ...'}
    </div>
  );
};
const ClientRoutes = () => {
  const { isSigned, loading } = useAuth();
  const { setFavicons } = useBranding();
  const { setDocumentTitleByBranding } = useBranding();

  useEffect(() => {
    setFavicons();
  }, [setFavicons]);

  useEffect(() => {
    setDocumentTitleByBranding();
  }, [setDocumentTitleByBranding]);

  if (loading) return <AppLoading />;

  return !isSigned ? <ClientInitialRoutes /> : <ClientLoggedRoutes />;
};

export default ClientRoutes;
