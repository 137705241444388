import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTable from 'react-table'
import { Modal } from 'react-bootstrap'
import SearchInput from 'components/SearchInput'
import { handleChange } from './redux/actions'
import { bytesToSize } from '../../../../components/ToNormalize/ToNormalize'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faImages } from '@fortawesome/free-solid-svg-icons'
import useFilters from '../../../../../hooks/useFilters'
import AlertModal from 'components/AlertModal/AlertModal'
import Carousel from 'react-elastic-carousel'
import { toastr } from 'react-redux-toastr'

export const SalesPhotosList = () => {
  const dispatch = useDispatch()
  const { saleReducer: { photos, saleStatusId } } = useSelector(state => state)

  const { queryFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter

  const handleFilters = photo => {
    const querySearch = [photo['description']]
    return filterByQuery(querySearch)
  }

  const handleAddNewPhoto = () => {
    if (saleStatusId === 5 || saleStatusId === 2) return toastr.warning('Não é possível importar foto para com status finalizado ou cancelado.')
    dispatch(handleChange(true, 'isAddPhotosModalOpen'))
  }

  return (
    <div>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          width: '100%'
        }}
      >
        <button
          className="btn btn-sucesso"
          onClick={handleAddNewPhoto}
          style={{
            marginRight: 10
          }}
        >
          + Nova Foto
      </button>
        <SearchInput
          placeholder="Pesquisa por Descrição"
          onChange={e => setQuery(e.target.value)}
          value={query}
          containerStyle={{
            width: '100%'
          }}
        />
      </div>
      <ReactTable
        style={{
          fontWeight: "bold",
          textAlign: "center"
        }}
        data={photos.filter(handleFilters)}
        columns={[
          {
            Header: 'Data',
            accessor: 'createdAt',
            width: 100,
            Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
          },
          {
            Header: 'Descrição',
            accessor: 'description'
          },
          {
            Header: 'Tamanho',
            accessor: 'size',
            width: 100,
            Cell: ({ value }) => bytesToSize(value)
          },
          {
            Header: 'Ações',
            width: 70,
            Cell: props => <TableActions row={props.original} />
          }
        ]}
        defaultPageSize={10}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma venda encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  )
}

const TableActions = ({ row }) => {
  const [isConfirmDeleteImageModalOpen, setIsConfirmDeleteImageModalOpen] = useState(false)
  const [isImageCarouselOpen, setIsImageCarouselOpen] = useState(false)

  const dispatch = useDispatch()
  const { saleReducer: { photosToDelete, photos, saleStatusId } } = useSelector(state => state)

  const handleImages = () => {
    setIsImageCarouselOpen(true)
  }

  const handleDeleteImage = () => {
    if (row.uploaded) {
      const newArray = [...photosToDelete]
      newArray.push(row)
      dispatch(handleChange(newArray, 'photosToDelete'))
    }
    dispatch(handleChange(photos.filter(photo => photo.id !== row.id), 'photos'))
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around'
        }}
      >
        <FontAwesomeIcon
          icon={faImages}
          style={{ color: '#444', cursor: 'pointer' }}
          onClick={handleImages}
          size="12px"
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          style={{ color: 'red', cursor: (saleStatusId === 5 || saleStatusId === 2) ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            if (!(saleStatusId === 5 || saleStatusId === 2)) setIsConfirmDeleteImageModalOpen(true)
          }}
          size="12px"
        />
      </div>

      <AlertModal
        show={isConfirmDeleteImageModalOpen}
        message="Deseja excluir essa foto? O processo é irreversível e a foto deverá ser importada novamente mais tarde."
        onCancel={() => setIsConfirmDeleteImageModalOpen(false)}
        onSubmit={handleDeleteImage}
        onHide={() => setIsConfirmDeleteImageModalOpen(false)}
      />

      {
        isImageCarouselOpen &&
        <Modal
          show
          animation
          onHide={() => setIsImageCarouselOpen(false)}
        >
          <Modal.Body>
            <Carousel
              itemsToShow={1}
              className="modal-add-items-carousel"
              showArrows={false}
              renderPagination={() => <div />}
            >
              <img src={row.preview} alt={row.name} />
            </Carousel>
          </Modal.Body>
        </Modal>
      }
    </>
  )
}