import React from 'react';

import '../index.css';
import { useBranding } from 'hooks/useBranding';

export const HeaderExternalWorkshop = () => {
  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();
  return (
    <div
      className={`external-workshop-panel__header ${
        isWorkmotor ? 'wm-bg-primary wm-bg-primary-border-color' : ''
      }
          ${
            isTecnomotor
              ? 'tecnomotor-bg-primary tecnomotor-bg-primary-border-color'
              : ''
          }
          ${isBarros ? 'barros-bg-primary barros-bg-primary-border-color' : ''}
          ${isPO360 ? 'po360-bg-primary barros-bg-primary-border-color' : ''}`}
    ></div>
  );
};
