import React, { useEffect, useState } from 'react';
import { change, initialize } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MyCompanyData from './CompanyForm';
import api from 'services/api';
import config from 'config';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import {
  validateCnpj,
  validateCpf,
  validadeEmail,
  validadePhone,
} from 'client/components/Validations/Validations';
import {
  onlyNumbers,
  maxLength,
  cepMask,
} from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from '../../../../contexts/auth';
import constants from '../../../../utils/constants';
import companiesRepository from '../../../../repositories/Companies';
import s3Repository from '../../../../repositories/S3';
import superlogicaRepository from '../../../../repositories/Superlogica';
import mySuiteRepository from '../../../../repositories/MySuite';
import emailRepository from '../../../../repositories/Emails';
import { usePlanSignatureContext } from '../../../../contexts/plan-signature';
import { currentBrandingId } from 'v2/helpers/brandingHelpers';

const BundleMyCompanyData = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { loadUser, companyId, userId, company } = useAuth();

  const { isPlanFree } = usePlanSignatureContext();

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (companyId) {
      loadCompany();
    }
  }, [companyId]);

  async function loadCompany() {
    setLoading(true);

    try {
      const company = await companiesRepository.getById(companyId);
      const {
        Address,
        Company_Name,
        Cpf_Cnpj,
        Email,
        Phones,
        Trading_Name,
        id,
        Type,
        Url_Logo,
        isCompanyRegisteredInAPIFiscal,
      } = company;
      const companyAddress = Address[0];
      const companyPhones = Phones[0];

      const initialValues = {
        id: companyId,
        typeOriginal: Type,
        Type,
        Company_Name,
        Trading_Name,
        Cpf_Cnpj,
        Cpf_CnpjOriginal: Cpf_Cnpj,
        Email,
        registerApiFiscal: isCompanyRegisteredInAPIFiscal,
        preventSearchCep: true,
        Zipcode: maxLength(cepMask(companyAddress.Zipcode), 10),
        Address: companyAddress.Address,
        Address_Number: companyAddress.Address_Number,
        Complement: companyAddress.Complement,
        Neighborhood: companyAddress.Neighborhood,
        Reference_Point: companyAddress.Reference_Point,
        State: companyAddress.State,
        cityIbgeCode: companyAddress.cityIbgeCode,
        Address_id: companyAddress.id,
        Url_Logo,
        logo: {
          name: Url_Logo?.split('amazonaws.com/')[1],
          preview: Url_Logo,
          uploaded: true,
        },
      };

      const n = companyAddress.City.indexOf("'");
      if (n > 0) {
        let City = companyAddress.City.replace("'", '`');
        City = City.replace(
          City.charAt([n - 1]),
          City.charAt([n - 1]).toLowerCase()
        );
        companyAddress.City = City;
      }
      initialValues.City = companyAddress.City;

      if (companyPhones) {
        initialValues.Phone = phoneMask(companyPhones.Number_Phone1);
        initialValues.Phone_id = companyPhones.id;
        initialValues.Contato = companyPhones.Contact;
      }

      dispatch(initialize('meusDados', initialValues));

      setIsDataLoaded(true);
    } catch (err) {
      toastr.error(
        'Ocorreu um erro ao carregar a empresa. Por favor, tente novamente'
      );
      history.goBack();
    }
    setLoading(false);
  }

  async function handleSubmit(values) {
    setLoading(true);

    if (!isFromValuesValid(values)) return;

    if (!!companyId) {
      return update(values);
    }

    if (await alreadyHaveCompanyWithThisCnpj(values.Cpf_Cnpj)) {
      setLoading(false);
      return toastr.warning(
        `${values.Type === 'Física' ? 'CPF' : 'CNPJ'} informado já existente`
      );
    }
    return create(values);
  }

  function isFromValuesValid(values) {
    if (values.Type === 'Física') {
      if (
        !values.Company_Name ||
        !values.Cpf_Cnpj ||
        !values.Email ||
        !values.Phone ||
        !values.Zipcode ||
        !values.Address ||
        !values.Address_Number ||
        !values.Neighborhood ||
        !values.State ||
        !values.City
      ) {
        setLoading(false);
        toastr.warning('Por favor, preencha todos os campos obrigatórios');
        return false;
      }
      if (!validateCpf(values.Cpf_Cnpj)) {
        setLoading(false);
        toastr.warning('CPF informado inválido');
        return false;
      }
    } else {
      if (
        !values.Company_Name ||
        !values.Cpf_Cnpj ||
        !values.Email ||
        !values.Phone ||
        !values.Zipcode ||
        !values.Address ||
        !values.Trading_Name ||
        !values.Address_Number ||
        !values.Neighborhood ||
        !values.State ||
        !values.City ||
        !values.Contato
      ) {
        setLoading(false);
        toastr.warning('Por favor, preencha todos os campos obrigatórios');
        return false;
      }
      if (!validateCnpj(values.Cpf_Cnpj)) {
        setLoading(false);
        toastr.warning('CNPJ informado inválido');
        return false;
      }
    }

    if (!validadeEmail(values.Email)) {
      setLoading(false);
      toastr.warning('E-mail inválido');
      return false;
    }

    if (!validadePhone(values.Phone)) {
      setLoading(false);
      toastr.warning('Telefone inválido');
      return false;
    }

    return true;
  }

  async function create(values) {
    try {
      const Url_Logo = await verifyLogoUpdate(values);

      const valuesToCreate = {
        userId: userId,
        companyName: values.Company_Name,
        tradingName: values.Trading_Name || values.Company_Name,
        cpfCnpj: onlyNumbers(values.Cpf_Cnpj),
        type: values.Type,
        email: values.Email,
        Url_Logo,
        phones: {
          contact: values.Contato ? values.Contato : values.Contato,
          typePhone1: 'Telefone/Celular',
          number: values.Phone.replace(/\D/g, ''),
        },
        address: {
          street: values.Address,
          zipcode: onlyNumbers(values.Zipcode),
          complement: !values.Complement ? '' : values.Complement,
          neighborhood: values.Neighborhood,
          referencePoint: values.Reference_Point,
          city: values.City,
          state: values.State,
          country: 'Brasil',
          addressNumber: values.Address_Number,
          cityIbgeCode: values.cityIbgeCode,
        },
      };

      const company = await companiesRepository.create(valuesToCreate);

      superlogicaRepository.createClientSuperlogica(
        company.id,
        currentBrandingId
      );

      setLoading(false);
      toastr.success('Dados cadastrados com sucesso!');
      loadUser();
      dispatch({
        type: 'HANDLE_CHANGE',
        payloadType: 'companyId',
        payload: company.id,
      });
      history.push(constants.ROUTES.COMPANY_DETAILS);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toastr.warning(
        'Ocorreu um erro ao salvar os dados da empresa. Por favor, tente novamente'
      );
    }
  }

  const alreadyHaveCompanyWithThisCnpj = async (cnpj) => {
    const response = await api.get(
      config.endpoint + `company/searchcnpj/${cnpj}`
    );

    if (response.data.status) return true;

    return false;
  };

  async function update(values) {
    const Url_Logo = await verifyLogoUpdate(values);

    const {
      Company_Name,
      Trading_Name,
      Cpf_Cnpj,
      Email,
      Address,
      Address_Number,
      Neighborhood,
      City,
      State,
      Zipcode,
      Reference_Point,
      Address_id,
      Phone,
      Phone_id,
      Contato,
      Complement,
      Type,
      registerApiFiscal,
      cityIbgeCode,
    } = values;

    try {
      await api.put(
        config.endpoint + `integration/company-my-data/${companyId}`,
        {
          Company_Name: Company_Name.trim(),
          Trading_Name:
            Type === 'Jurídica' ? Trading_Name.trim() : Company_Name.trim(),
          Cpf_Cnpj,
          Email,
          Type,
          Address: Address.trim(),
          Address_Number,
          Neighborhood,
          Reference_Point,
          City,
          State,
          Zipcode: onlyNumbers(Zipcode),
          cityIbgeCode,
          idAddress: Address_id,
          Number_Phone1: onlyNumbers(Phone),
          phone_id: Phone_id,
          Contact: Contato,
          Complement: !Complement ? '' : Complement,
          Url_Logo,
        }
      );

      if (registerApiFiscal) {
        await api.put(
          config.endpoint + `company/update-tax-data-company/${companyId}`
        );
      }

      superlogicaRepository.updateClientSuperlogica(companyId);

      // if (!isPlanFree) {
      //   mySuiteRepository.createUpdateCompanyMySuite(companyId);
      // }

      setLoading(false);
      loadUser();
      toastr.success('Dados atualizados com sucesso!');

      const cpfOriginal = company.Cpf_Cnpj;

      if (Cpf_Cnpj !== cpfOriginal) {
        emailRepository.updateCpfToCnpj({
          userId: userId,
          cpfOriginal: cpfOriginal,
        });
      }
      history.push(constants.ROUTES.COMPANIES);
    } catch (err) {
      console.log(err);

      if (err.response) {
        if (err.response.status === 409) {
          setLoading(false);
          return toastr.warning(
            'O CNPJ já está cadastrado na base, por favor, revise o CNPJ para prosseguir.'
          );
        }
      }

      setLoading(false);
      toastr.warning(
        'Ocorreu um erro ao atualizar as informações. Por favor, tente novamente'
      );
    }
  }

  async function verifyLogoUpdate(values) {
    const { logo, imagesToDelete, Cpf_Cnpj } = values;

    if (imagesToDelete?.length > 0) {
      await s3Repository.deleteImages(imagesToDelete);
    }

    if (logo) {
      /// SE O LOGO NÃO ESTÁ UPADO E EXISTE O ARQUIVO FILE
      if (!logo?.uploaded && !!logo?.file) {
        try {
          const imageURL = await s3Repository.uploadImage(
            {
              file: logo.file,
              name: logo.name,
            },
            Cpf_Cnpj
          );
          return imageURL;
        } catch (err) {
          toastr.warning(
            'Ocorreu um erro ao salvar o logo',
            'Por favor, tente novamente'
          );
        }
      } else if (!logo?.uploaded) {
        dispatch(change('meusDados', 'logo', null));
        dispatch(change('meusDados', 'Url_Logo', null));
        return null;
      }
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (isDataLoaded) {
      setTimeout(() => {
        dispatch(change('meusDados', 'preventSearchCep', false));
      }, 500);
    }
  }, [isDataLoaded]);

  return (
    <MyCompanyData
      onSubmit={handleSubmit}
      loading={loading}
      loadCompany={loadCompany}
      initialValues={{
        Type: 'Jurídica',
      }}
    />
  );
};

export default withRouter(BundleMyCompanyData);
