import React, { useState, useEffect } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import {
  onlyNumbers,
  kmMask,
  description as normalizeDescription,
} from 'client/components/ToNormalize/ToNormalize';
import api from 'services/api';
import config from 'config';
import { change } from 'redux-form';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import { useAuth } from '../../../../contexts/auth';
import constants from '../../../../utils/constants';
import { useBranding } from 'hooks/useBranding';
import Button from '../../../components/CustomButton/CustomButton.jsx';

const ModalFormWarranty = ({
  modal,
  warrantyId,
  history,
  Calling,
  dispatch,
  handleModal,
}) => {
  const [code, setCode] = useState(0);
  const [description, setDescription] = useState('');
  const [km, setKm] = useState('');
  const [months, setMonths] = useState('');
  const [loading, setLoading] = useState(false);
  const { isWorkmotor, isTecnomotor } = useBranding();
  const { companyId } = useAuth();

  useEffect(() => {
    if (!modal) {
      !warrantyId ? loadLastCode() : loadWarranty();
    } else {
      loadLastCode();
    }
  }, []);

  const loadLastCode = async () => {
    const response = await api.get(
      config.endpoint + `warranty/LastOne/${companyId}`
    );

    if (response.data.status) {
      setCode(parseInt(response.data.data) + 1);
    } else {
      setCode(1);
    }
  };

  const loadWarranty = async () => {
    setLoading(true);
    const response = await api.get(
      config.endpoint + `warranty/search/ById/${companyId}/${warrantyId}`
    );

    if (!response.data.status) {
      history.push(constants.ROUTES.WARRANTIES);
    }

    const { Code, Description, Kilometers, Months } = response.data.data;

    setCode(Code);
    setDescription(Description);
    setKm(kmMask(Kilometers?.toString()));
    setMonths(Months);

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!description || !km || !months) {
      return toastr.warning('Por favor, informe todos os dados para salvar');
    }

    if (months > 99) {
      return toastr.warning("Número inválido no campo 'Meses'");
    }

    if (description.length <= 3) {
      return toastr.warning(
        'Por favor, informe a descrição correta da garantia.'
      );
    }

    if (!warrantyId) {
      create();
    } else {
      update();
    }
  };

  const create = async () => {
    setLoading(true);
    const response = await api.get(config.endpoint + 'warranty');

    const warranty = response.data.find(
      (warranty) =>
        warranty.Company_id.toString() === companyId.toString() &&
        warranty.Description === description.trim().toUpperCase()
    );

    if (warranty) {
      loadLastCode();
      setLoading(false);
      return toastr.warning('Garantia já existente');
    }

    try {
      const resp = await api.post(config.endpoint + `warranty/${companyId}`, {
        Description: description.trim().toUpperCase(),
        Kilometers: km.replace(/[^\d]/g, ''),
        Months: months,
        Code: code,
      });

      if (!resp.data.status) {
        setLoading(false);
        return toastr.error(
          'Ocorreu algum problema',
          'Por favor, tente novamente'
        );
      }

      toastr.success('Sucesso', `Garantia cadastrada com sucesso`);
      if (!modal) {
        history.push(constants.ROUTES.WARRANTIES);
        return cleanForm();
      }

      switch (Calling) {
        case 'Cadastro Produto':
          dispatch(change('formProduto', 'Warranty', resp.data.data.id));
          handleModal('warranty');
          break;
        case 'Cadastro Familia':
          handleModal('warranty', resp.data.data.id);
          break;
        case 'Cadastro Serviço':
          handleModal('garantia', resp.data.data.id);
          break;
      }
    } catch (err) {
      console.log(err);
      return toastr.error(
        'Ocorreu algum problema',
        'Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const cleanForm = () => {
    setDescription('');
    setKm('');
    setMonths('');

    loadLastCode();
  };

  const update = async () => {
    setLoading(true);

    const resp = await api.get(config.endpoint + 'warranty');

    const warranty = resp.data.find(
      (warranty) =>
        warranty.Description === description.trim().toUpperCase() &&
        warranty.Company_id.toString() === companyId.toString() &&
        parseInt(warranty.id) !== parseInt(warrantyId)
    );

    if (warranty) {
      setLoading(false);
      return toastr.warning('Garantia já existente');
    }

    try {
      const response = await api.put(
        config.endpoint + `warranty/${warrantyId}`,
        {
          Description: description.trim().toUpperCase(),
          Kilometers: km.toString().replace(/[^\d]/g, ''),
          Months: months,
        }
      );

      if (!response.status) {
        return toastr.error(
          'Erro',
          'Ocorreu um erro durante a atualização. Por favor, repita o procedimento'
        );
      }

      toastr.success('Sucesso', `Garantia atualizada!`);
      history.push(constants.ROUTES.WARRANTIES);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro',
        'Ocorreu um erro durante a atualização. Por favor, repita o procedimento'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={true} dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            margin: '0',
            height: '50px',
          }}
        >
          <h1 style={{ margin: '0' }}>Nova Garantia</h1>
          <span style={{ color: 'red', margin: '0' }}>
            *Campos Obrigatórios
          </span>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '98%' }}>
          <div
            className={`cardComponent row ${
              isWorkmotor ? 'wm-bg-primary-border-color' : ''
            } ${isTecnomotor ? 'texnodux-bg-primary-border-color' : ''}`}
          >
            <div className="div-card">
              <Button
                id="btn-components"
                className={`${isWorkmotor ? 'wm-bg-primary' : ''} ${
                  isTecnomotor ? 'tecnomotor-bg-primary' : ''
                }`}
                onClick={(e) => e.preventDefault()}
              >
                <a id="btn-a">Dados da Garantia</a>
              </Button>
            </div>

            <div>
              <Col xs={12} style={{ marginBottom: '20px' }}>
                <Col xs={2} sm={2} md={2} lg={2}>
                  <label>Código</label>
                  <input
                    disabled
                    onChange={(event) => event.preventDefault()}
                    style={{ textAlign: 'right' }}
                    className="form-control foco-input"
                    value={code}
                  />
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <label>
                    Descrição<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    maxLength="40"
                    className="form-control foco-input"
                    onChange={(e) =>
                      setDescription(
                        normalizeDescription(e.target.value).toUpperCase()
                      )
                    }
                    name="description"
                    value={description}
                  />
                </Col>
                <Col xs={2} sm={2} md={2} lg={2}>
                  <label>
                    KM<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    className="form-control foco-input"
                    onChange={(e) => setKm(kmMask(onlyNumbers(e.target.value)))}
                    value={km}
                    maxLength="7"
                  />
                </Col>
                <Col xs={2} sm={2} md={2} lg={2}>
                  <label>
                    Meses<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    maxLength="2"
                    className="form-control foco-input"
                    onChange={(e) => setMonths(onlyNumbers(e.target.value))}
                    value={months}
                  />
                </Col>
              </Col>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FormSubmitButtons
          onCancel={() =>
            modal
              ? handleModal('warranty')
              : history.push(constants.ROUTES.WARRANTIES)
          }
          hasId={!!warrantyId}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(ModalFormWarranty);
