import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Headerbar from '../components/Headerbar';
import Sidebar from '../components/Sidebar';
import { adminRoutes } from './routes';

import './styles.css';
import { useBranding } from 'hooks/useBranding';

const Template = ({ children, active, setActive }) => {
  return (
    <>
      <Headerbar />
      <Sidebar active={active} setActive={setActive} />
      {children}
    </>
  );
};

const AdminLoggedRoutes = () => {
  const [active, setActive] = useState('home');
  const { isWorkmotor } = useBranding();

  return (
    <Template active={active} setActive={setActive}>
      <div id="admin-logged-wrapper">
        <div
          id="main-panel"
          className="main-panel admin-panel"
          style={{ padding: 10 }}
        >
          <Switch>
            {adminRoutes.map((route, key) => (
              <Route
                path={route.layout + route.path}
                key={key}
                render={(props) => {
                  setActive(route.active);

                  window.document.title = isWorkmotor
                    ? `WorkMotor - ${route.name}`
                    : `OS Digital - ${route.name}`;
                  const Component = route.Component;
                  return <Component {...props} />;
                }}
              />
            ))}
            <Redirect path="/admin-login" to="/admin/home" />
          </Switch>
        </div>
      </div>
    </Template>
  );
};

export default AdminLoggedRoutes;
