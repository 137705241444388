export const themePO360 = {
  colors: {
    bannerText: '#15377f',
    buttonGradient100: '#1C4A9C',
    buttonGradient200: '#1A4287',
    buttonGradient300: '#0E2F66',
    branding100: '#DCE6F2',
    branding200: '#B4C8E3',
    branding300: '#8CAAD4',
    branding400: '#649CC5',
    branding500: '#3C7EB6',
    branding600: '#1560A7',
    branding700: '#125499',
    branding800: '#103D8B',
    branding900: '#0E2F7D',
    branding1000: '#15377f',
  },
};
