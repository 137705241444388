import React, { useState, useEffect, memo } from 'react';
import './styles.css';
import Select from './components/Select';
import Input from './components/Input';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import {
  cepMask,
  onlyNumbers,
} from 'client/components/ToNormalize/ToNormalize';
import api from 'services/api';
import config from 'config';

function Endereco({
  form,
  edit,
  required,
  multi,
  preventSearchCep = false,
  duplicateAddress,
  duplicateZipCode,
  duplicateState,
  duplicateCity,
}) {
  const { Zipcode } = useSelector((state) => state.form[form].values);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [disabled, setDisabled] = useState(edit);

  const dispatch = useDispatch();

  useEffect(() => {
    loadStates();
    loadCities();
  }, []);

  useEffect(() => {
    setDisabled(edit);
  }, [edit]);

  async function loadStates() {
    const response = await api.get(config.endpoint + `address/states`);
    setStates(response.data || []);
  }

  async function loadCities() {
    const response = await api.get(config.endpoint + `address/cities`);
    setCities(response.data || []);
  }

  async function citiesByState(State_id) {
    const response = await api.get(
      config.endpoint + `address/cities/ByStateId/${State_id}`
    );
    setCities(response.data || []);
  }

  function handleCity(city) {
    switch (city) {
      case 'Birigüi':
        return 'Birigui';
      default:
        return city;
    }
  }

  function handleCep(obj) {
    const n = obj.City.indexOf("'");
    if (n > 0) {
      let City = obj.City.replace("'", '`');
      City = City.replace(
        City.charAt([n - 1]),
        City.charAt([n - 1]).toLowerCase()
      );
      obj.City = City;
    }

    dispatch([
      change(form, `${multi ? multi + '.' : ''}Address`, obj.Address),
      change(form, `${multi ? multi + '.' : ''}Complement`, obj.Complement),
      change(form, `${multi ? multi + '.' : ''}Neighborhood`, obj.Neighborhood),
      change(form, `${multi ? multi + '.' : ''}State`, obj.State),
      change(form, `${multi ? multi + '.' : ''}cityIbgeCode`, obj.cityIbgeCode),
    ]);

    setTimeout(() => {
      dispatch(
        change(form, `${multi ? multi + '.' : ''}City`, handleCity(obj.City))
      );
    }, 500);
  }

  function handleZipCode(value) {
    setDisabled(false);
    const cep = onlyNumbers(value);
    if (cep.length === 8) {
      fetch('https://viacep.com.br/ws/' + cep + '/json/')
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const obj = {
            Address: data.logradouro,
            Complement: data.complemento,
            Neighborhood: data.bairro,
            City: data.localidade,
            State: data.uf,
            cityIbgeCode: data.ibge,
          };

          handleState(data.uf);
          if (!data.erro) {
            setDisabled(true);
            handleCep(obj);
          }
        });
    }
  }

  function handleState(UF) {
    if (!UF) return loadCities();
    for (let i = 0; i < states.length; i++) {
      if (UF === states[i].Initials) {
        return citiesByState(states[i].id);
      }
    }
  }

  useEffect(() => {
    if (Zipcode?.length === 10 && !preventSearchCep) {
      handleZipCode(Zipcode);
    }
  }, [Zipcode]);

  return (
    <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
      <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <Col lg={2} md={2} sm={3} xs={12}>
          <span
            className="field-hiperlink"
            onClick={() =>
              window.open(
                'https://buscacepinter.correios.com.br/app/endereco/index.php'
              )
            }
          >
            Não sei meu CEP
          </span>
          <Field
            label="CEP:"
            name="Zipcode"
            component={Input}
            required={required}
            normalize={cepMask}
            placeholder="57073-342"
            onChange={({ target: { value } }) => {
              handleZipCode(value);
            }}
          />
        </Col>
        <Col lg={4} md={7} sm={6} xs={12}>
          <Field
            label="Endereço:"
            name="Address"
            required={required}
            component={Input}
            placeholder="Rua Nilson Urias"
          />
        </Col>
        <Col lg={1} md={3} sm={3} xs={12}>
          <Field
            label="Número:"
            name="Address_Number"
            component={Input}
            required={required}
            normalize={onlyNumbers}
            placeholder="298"
          />
        </Col>
        <Col lg={3} md={6} sm={6} xs={12}>
          <Field
            label="Bairro:"
            name="Neighborhood"
            component={Input}
            required={required}
            placeholder="Cidade Universitária"
          />
        </Col>
        <Col lg={2} md={6} sm={6} xs={12}>
          <Field label="Complemento:" name="Complement" component={Input} />
        </Col>
      </Col>

      <Col lg={6} md={6} sm={6} xs={12}>
        <Field
          label="Ponto de Referência:"
          name="Reference_Point"
          component={Input}
          maxLength="75"
          placeholder="Ao lado da escola"
        />
      </Col>

      <Col lg={3} md={6} sm={6} xs={12}>
        <label>
          Estado: {required && <span style={{ color: 'red' }}>*</span>}
        </label>
        <Field
          name="State"
          component={Select}
          disabled={disabled || !states.length}
          onChange={({ target: { value } }) => {
            handleState(value);
            dispatch(change(form, `${multi ? multi + '.' : ''}City`, ''));
          }}
        >
          <option name="" value="">
            Selecione
          </option>
          {states.map((e) => {
            return (
              <option key={e.id} value={e.Initials}>
                {e.Name}
              </option>
            );
          })}
        </Field>
      </Col>

      <Col lg={3} md={6} sm={6} xs={12}>
        <label>
          Cidade: {required && <span style={{ color: 'red' }}>*</span>}
        </label>
        <Field
          name="City"
          component={Select}
          disabled={disabled || !cities.length}
        >
          <option value="">Selecione</option>
          {cities.map((c) => {
            return (
              <option key={c.id} value={c.Name}>
                {c.Name}
              </option>
            );
          })}
        </Field>
      </Col>
    </Col>
  );
}

export default memo(Endereco);
