import React from 'react';

import {
  SidebarSubMenuContainer,
  SidebarSubMenuItem,
  SidebarSubMenuItemsContainer,
  SidebarSubMenuTitle,
} from './SidebarSubMenu.styles';

import { SidebarSubMenuDegree } from '../SidebarSubMenuDegree/SidebarSubMenuDegree';
import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';

export const SidebarSubMenu = ({ title, items }) => {
  const { handleMenuItemClick, validateFeatureAvailability } = useSidebar();

  return (
    <SidebarSubMenuContainer key={title} title={title}>
      <SidebarSubMenuTitle>{title}</SidebarSubMenuTitle>
      <SidebarSubMenuItemsContainer>
        {items.map((item) => {
          const hasSubMenuDegree = !!item.subMenuDegree?.length;
          const isAvailable = validateFeatureAvailability(item);

          return (
            <SidebarSubMenuItem
              title={item.title}
              onClick={() =>
                item.path ? handleMenuItemClick(item.path, item) : null
              }
              disabledStyle={!isAvailable}
            >
              {item.title}
              {hasSubMenuDegree && (
                <SidebarSubMenuDegree
                  subMenuTitle={title}
                  subMenuDegreeTitle={item.title}
                  items={item.subMenuDegree}
                />
              )}
            </SidebarSubMenuItem>
          );
        })}
      </SidebarSubMenuItemsContainer>
    </SidebarSubMenuContainer>
  );
};
