import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import { toastr } from 'react-redux-toastr';
import RenderField from 'components/RenderField';
import cashierBankRepository from '../../../../repositories/CashierBank';
import accountPlansRepository from 'repositories/AccountPlans';
import constants from '../../../../utils/constants';
import { useAuth } from 'contexts/auth';
import SelectCustomers from 'v2/client/components/SelectCustomers';
import { isBefore } from 'date-fns';
import format from 'date-fns/format';

const Title = ({ titleId, setLoading }) => {
  const [disabledVencimento, setDisabledVencimento] = useState(false);
  const [cashierBank, setcashierBank] = useState([]);
  const [accountPlans, setAccountPlans] = useState([]);
  const {
    saleCode,
    billStatusId,
    issueDate,
    dueDate,
    isGrouped,
    selectedCustomer,
  } = useSelector((state) => state.form.billToReceive.values);
  const { companyId } = useAuth();
  const dispatch = useDispatch();

  const currencyMask = createNumberMask({
    prefix: 'R$ ',
    decimalPlaces: 2,
  });

  const percentMask = createNumberMask({
    suffix: ' %',
    decimalPlaces: 2,
  });

  useEffect(() => {
    loadActiveCreditAccounts();
    cashierBankRepository
      .getAllByCompanyActiveSortedByDescription(companyId)
      .then((resp) => setcashierBank(resp));
  }, []);

  const handleDueDateChange = (e) => {
    e.preventDefault();
    const newDueDate = e.target.value;

    if (titleId) {
      const isDueDateBeforeIssueDate = isBefore(
        new Date(newDueDate),
        new Date(issueDate)
      );

      if (isDueDateBeforeIssueDate) {
        toastr.warning(
          'Data inválida',
          'Aplicado a data de hoje no campo de vencimento.'
        );

        dispatch(
          change('billToReceive', 'dueDate', format(new Date(), 'yyyy-MM-dd'))
        );
        return;
      }
      dispatch(change('billToReceive', 'dueDate', newDueDate));
    } else {
      dispatch(change('billToReceive', 'dueDate', e.target.value));
    }
  };

  async function loadActiveCreditAccounts() {
    try {
      const { data } = await accountPlansRepository.index({ companyId });
      const accountPlansCredit = data.filter(
        (accountPlan) => accountPlan.type === 'Crédito'
      );

      setAccountPlans(accountPlansCredit);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar o Plano de Contas. Por favor, tente novamente.'
      );
    }
  }

  function setClient(client) {
    dispatch([change('billToReceive', 'clientId', client.value.customer_id)]);
    dispatch([change('billToReceive', 'selectedCustomer', client.value)]);
  }

  const isClosedOrCanceled =
    billStatusId === constants.BILLS_STATUS.CLOSED ||
    billStatusId === constants.BILLS_STATUS.CANCELED;
  const isOpen = billStatusId === constants.BILLS_STATUS.OPEN;

  return (
    <div className="bills-to-receive basic-data__container">
      <div className="row-1">
        <div>
          <Field
            name="dueDate"
            component={RenderField}
            label="Vencimento:"
            required
            type="date"
            disabled={
              (isOpen && saleCode && disabledVencimento) || isClosedOrCanceled
            }
            onChange={handleDueDateChange}
          />
        </div>
        <div>
          <Field
            name="amount"
            component={RenderField}
            label="Total:"
            required
            {...currencyMask}
            disabled={(isOpen && saleCode) || isClosedOrCanceled || isGrouped}
          />
        </div>
        <div>
          <label>
            Cliente:<span style={{ color: 'red' }}>*</span>
          </label>
          <SelectCustomers
            onChange={(client) => setClient(client)}
            placeholder="Pesquisar Cliente"
            value={selectedCustomer}
            showDefaultLabel={false}
            showVehicles={false}
            isDisabled={(isOpen && saleCode) || isClosedOrCanceled || isGrouped}
            styles={{
              control: (base) => ({
                ...base,
                height: '35px',
                minHeight: '35px',
                width: '268px',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              valueContainer: (provided) => ({
                ...provided,
                marginTop: '-7px',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                marginTop: '-7px',
              }),
            }}
          />
        </div>
        <div>
          <Field
            name="issueDate"
            component={RenderField}
            label="Dt.Emissão:"
            type="date"
            disabled
          />
        </div>
        {!isGrouped && (
          <>
            <div>
              <Field
                name="linkedSales"
                component={RenderField}
                label="OS/Venda:"
                disabled
              />
            </div>

            <div>
              <Field
                name="parcelNumber"
                component={RenderField}
                label="Parcela:"
                disabled
              />
            </div>
            <div>
              <Field
                name="saleDate"
                component={RenderField}
                label="Dt. Venda:"
                disabled
                type="date"
              />
            </div>
          </>
        )}
      </div>
      <div className="row-2">
        <div>
          <Field
            name="addition"
            component={RenderField}
            label="Acréscimo:"
            {...currencyMask}
            disabled={isClosedOrCanceled}
          />
        </div>
        <div>
          <Field
            name="discount"
            component={RenderField}
            label="Desconto:"
            {...currencyMask}
            disabled={isClosedOrCanceled}
          />
        </div>
        <div>
          <Field
            name="dischargeDate"
            component={RenderField}
            label="Data de Baixa:"
            disabled
            type="date"
          />
        </div>
        <div>
          <Field
            name="fee"
            component={RenderField}
            label="Taxa do Cartão:"
            disabled
            {...percentMask}
          />
        </div>
        <div>
          <Field
            name="feeValue"
            component={RenderField}
            label="Valor Taxa:"
            {...currencyMask}
            disabled
          />
        </div>
        <div>
          <Field
            name="observations"
            component={RenderField}
            label="Observações:"
            as="textarea"
            maxLength={100}
            disabled={isClosedOrCanceled}
            style={{ resize: 'vertical' }}
          />
        </div>
      </div>
      <div className="row-3">
        <div>
          <Field
            label="Caixa/Banco:"
            name="cashierBankId"
            component={RenderField}
            as="select"
            required
            disabled={isClosedOrCanceled}
          >
            <option value="">Selecione</option>
            {cashierBank.map(
              ({ id, description, CashierBankType: { initials } }) => (
                <option key={id} value={id}>
                  {initials} - {description}
                </option>
              )
            )}
          </Field>
        </div>
        <div>
          <Field
            label="Plano de Conta:"
            name="accountPlanId"
            component={RenderField}
            as="select"
            required
            disabled={isClosedOrCanceled}
          >
            <option value="">Selecione</option>
            {accountPlans.map(({ id, description }) => (
              <option key={id} value={id}>
                {description}
              </option>
            ))}
          </Field>
        </div>
        <div>
          <Field
            label="Juros (Mensal):"
            name="monthlyInterestValue"
            disabled={true}
            {...currencyMask}
            component={RenderField}
          ></Field>
        </div>
        {isGrouped && (
          <div>
            <Field
              label="OS/Venda"
              name="linkedSales"
              disabled={true}
              component={RenderField}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Title;
