import { useRecoilState } from 'recoil';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';

import { getPossiblePlansIdsByName } from 'utils/signatureHelpers';

import { sidebarItems } from './sidebarItems';
import { useBlockModals } from '../useBlockModals';
import { companySegments as companySegmentsAtom } from 'storage/companyDetailsStorage';

import { useModulesContext } from 'contexts/modules';
import { usePlanSignatureContext } from 'contexts/plan-signature';

export const useSidebar = () => {
  const history = useHistory();
  const { company, userPermissions } = useAuth();
  const [companySegments] = useRecoilState(companySegmentsAtom);

  const { plan } = usePlanSignatureContext();
  const {
    nfeModule,
    nfseModule,
    bankSlipModule,
    workshopPanelModule,
    isNFeModuleActive,
    isNFCeModuleActive,
    isNFSeModuleActive,
    isBankSlipModuleActive,
    isWorkshopPanelModuleActive,
    isWorkshopPanelConfigActive,
    isSaleItemApprovalCancellationActive,
    isAnyFiscalModuleActive,
  } = useModulesContext();

  const {
    setIsFeatureNotAvailableModalOpen,
    setIsModuleInactiveModalOpen,
    setCurrentInactiveModule,
    setIsFiscalModuleInactiveModalOpen,
    setCurrentInactiveFiscalModule,
    setIsFiscalModuleInactivePlanVariationModalOpen,
    setIsSegmentsBlockedModalOpen,
  } = useBlockModals();

  const handleFeatureNotAvailableAlert = (reason, show) => {
    if (!show) return;

    switch (reason) {
      case 'needNFeModulePlan':
        setIsFiscalModuleInactivePlanVariationModalOpen(true);
        return;
      case 'needNFeModule':
        setIsFiscalModuleInactiveModalOpen(true);
        setCurrentInactiveFiscalModule(nfeModule);
        return;
      case 'needFiscalModule':
        setIsFiscalModuleInactiveModalOpen(true);
        setCurrentInactiveFiscalModule(nfeModule);
        return;
      case 'needWorkshopPanel':
        setIsModuleInactiveModalOpen(true);
        setCurrentInactiveModule(workshopPanelModule);
        return;
      case 'needWorkshopPanelActive':
        return toastr.error(
          'Painel da Oficina',
          'A função está desativada nas configurações. Habilite para utilizar.'
        );
      case 'needSaleItemApprovalCancellationActive':
        return toastr.error(
          'Função bloqueada',
          'A função Aprovação/Cancelamento de Itens está desativada nas configurações. Ative-a para utilizar essa opção.'
        );
      case 'needCashierControl':
        return toastr.error(
          'Função bloqueada',
          'A função caixa está desativada nas configurações. Ative-a para utilizar essa opção.'
        );
      case 'needInternalCreditManagement':
        return toastr.error(
          'Função bloqueada',
          'É necessário ativar a opção de Gerenciar Crédito Interno para utilizar essa opção.'
        );
      case 'needPartsRequisition':
        return toastr.error(
          'Função bloqueada',
          'É necessário ativar a opção de Requisição de Peças para utilizar essa opção.'
        );
      case 'needSegment':
        setIsSegmentsBlockedModalOpen(true);
        return;
      case 'plan':
        setIsFeatureNotAvailableModalOpen(true);
        return;
      case 'userPermission':
        toastr.error(
          'Acesso não permitido',
          'Você não tem permissão para acessar essa tela.'
        );
        return;
    }
  };

  const getPermissionAttributeType = (feature) => {
    if (feature.subFeatureId) return 'subFeature';
    if (feature.mainFeatureId) return 'mainFeature';
    if (feature.groupFeatureId) return 'groupFeature';

    return undefined;
  };

  const validateUserPermission = (feature) => {
    const permissionAttributeType = getPermissionAttributeType(feature);

    if (!permissionAttributeType) return true;

    return userPermissions[`${permissionAttributeType}s`]?.some(
      (permission) =>
        permission.id === feature[`${permissionAttributeType}Id`] &&
        permission.status === true
    );
  };

  const validatePlanPermission = (plansNotAllowed) => {
    if (!plansNotAllowed) return true;

    const companyPlanId = plan?.id || 4;

    const notAllowedPlansIds = plansNotAllowed
      ?.map((plan) => getPossiblePlansIdsByName(plan))
      ?.reduce((acc, curr) => [...acc, ...curr], []);

    return !notAllowedPlansIds?.includes(companyPlanId);
  };

  const validateSegmentPermission = (segmentsAllowed) => {
    if (!segmentsAllowed?.length) return true;

    return companySegments?.some((segment) =>
      segmentsAllowed.includes(segment)
    );
  };

  const validateFeatureAvailability = (feature, showNotAvailableReason) => {
    const planPermission = validatePlanPermission(feature?.plansNotAllowed);

    if (!planPermission && !feature.needFiscalModule) {
      handleFeatureNotAvailableAlert('plan', showNotAvailableReason);
      return false;
    }

    if (!planPermission && feature.needFiscalModule) {
      handleFeatureNotAvailableAlert(
        'needNFeModulePlan',
        showNotAvailableReason
      );

      return false;
    }

    if (feature.needFiscalModule && !isAnyFiscalModuleActive) {
      handleFeatureNotAvailableAlert(
        'needFiscalModule',
        showNotAvailableReason
      );

      return false;
    }

    if (feature.needNFSeModule && !isNFSeModuleActive) {
      handleFeatureNotAvailableAlert('needNFeModule', showNotAvailableReason);
      return false;
    }

    if (feature.needNFeModule && !isNFeModuleActive && !isNFCeModuleActive) {
      handleFeatureNotAvailableAlert('needNFeModule', showNotAvailableReason);

      return false;
    }

    if (feature?.needCashierControl && !company?.hasCashierControl) {
      handleFeatureNotAvailableAlert(
        'needCashierControl',
        showNotAvailableReason
      );

      return false;
    }

    if (
      feature?.needInternalCreditManagement &&
      !company?.companyConfig.manageInternalCredit
    ) {
      handleFeatureNotAvailableAlert(
        'needInternalCreditManagement',
        showNotAvailableReason
      );

      return false;
    }

    if (
      feature?.needPartsRequisition &&
      company?.companyConfig.stockWriteOff === 'Fechamento da Venda'
    ) {
      handleFeatureNotAvailableAlert(
        'needPartsRequisition',
        showNotAvailableReason
      );

      return false;
    }

    if (
      feature?.needSaleItemApprovalCancellationActive &&
      !isSaleItemApprovalCancellationActive
    ) {
      handleFeatureNotAvailableAlert(
        'needSaleItemApprovalCancellationActive',
        showNotAvailableReason
      );

      return false;
    }

    if (
      feature?.needWorkshopPanel &&
      isWorkshopPanelModuleActive &&
      !isWorkshopPanelConfigActive
    ) {
      handleFeatureNotAvailableAlert(
        'needWorkshopPanelActive',
        showNotAvailableReason
      );

      return false;
    }

    if (feature?.needWorkshopPanel && !isWorkshopPanelModuleActive) {
      handleFeatureNotAvailableAlert(
        'needWorkshopPanel',
        showNotAvailableReason
      );

      return false;
    }

    const segmentPermission = validateSegmentPermission(
      feature?.segmentsAllowed
    );

    if (feature?.segmentsAllowed?.length > 0 && !segmentPermission) {
      handleFeatureNotAvailableAlert('needSegment', showNotAvailableReason);

      return false;
    }

    const userPermission = validateUserPermission(feature);

    if (!userPermission) {
      handleFeatureNotAvailableAlert('userPermission', showNotAvailableReason);
      return false;
    }

    return true;
  };

  const handleMenuItemClick = (path, feature) => {
    const isAvailable = validateFeatureAvailability(feature, true);
    if (!isAvailable) return;

    if (feature?.targetBlank) {
      window.open(window.location.origin + '/' + path, '_blank');
      return;
    }

    history.push('/client/' + path);
    return;
  };

  return {
    handleMenuItemClick,
    validateFeatureAvailability,
    sidebarItems,
  };
};
