import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #ccc;
`;

export const ProgressBarFill = styled.div`
  width: ${({ progress }) => (progress < 100 ? progress : 100)}%;
  height: 100%;
  background: #5cb85c;
  border-radius: 4px;
`;
