import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import XlsDropzone from './XlsDropzone';
import ImportTableModal from 'client/components/ImportTableModal';
import AlertModal from 'components/AlertModal';
import DownloadXlsButton from 'components/DownloadXlsButton';

import {
  getModelsColumnsByImportType,
  getModelsDataByImportType,
} from './importHelpers';

import './styles.css';
import { useEffect } from 'react';

const { Header, Title, Body, Footer } = Modal;

const ImportFromXlsModal = ({
  show,
  onCancel,
  importType,
  loadItems,
  loadItemsParams,
}) => {
  const [items, setItems] = useState([]);
  const [isImportTableModalOpen, setIsImportTableModalOpen] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);

  const closeModal = () => {
    if (items.length) setIsCloseModalOpen(true);
    else onCancel();
  };

  return (
    <>
      <Modal show={show} onHide={closeModal} dialogClassName="modal-60w">
        <Header>
          <Title>
            <strong>Importação de Cadastro</strong>
          </Title>
        </Header>
        <Body>
          <XlsDropzone
            importType={importType}
            items={items}
            setItems={setItems}
          />
        </Body>
        <Footer>
          <section className="import-xls-buttons-section">
            <button
              className="btn btn-delete"
              onClick={closeModal}
              title="Voltar"
            >
              Voltar
            </button>
            <DownloadXlsButton
              archiveName="planilha-modelo"
              className="btn btn-import"
              data={getModelsDataByImportType(importType)}
              columns={getModelsColumnsByImportType(importType)}
            >
              Baixar Planilha Modelo
            </DownloadXlsButton>
            <button
              className="btn btn-sucesso"
              onClick={() => setIsImportTableModalOpen(true)}
              title="Avançar"
              disabled={!items.length}
            >
              Avançar
            </button>
          </section>
        </Footer>
      </Modal>
      <ImportTableModal
        show={isImportTableModalOpen}
        onCancel={() => {
          setIsImportTableModalOpen(false);
          setItems([]);
        }}
        importType={importType}
        items={items}
        setItems={setItems}
        closeImportModal={onCancel}
        loadItems={loadItems}
        loadItemsParams={loadItemsParams}
      />
      <AlertModal
        show={isCloseModalOpen}
        message="Deseja sair da importação de cadastro?"
        onCancel={() => setIsCloseModalOpen(false)}
        onSubmit={() => {
          setIsCloseModalOpen(false);
          onCancel();
          setItems([]);
        }}
      />
    </>
  );
};

export default ImportFromXlsModal;
