import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { PRODUCT_ORIGIN } from '../../../../utils/constants';

const useProductTax = () => {
  const {
    NFeItems,
    typeUnities,
    inssuranceValue,
    subTotal,
    companyUF,
    freightValue,
    otherExpenses,
    discountValue,
    companyPIS,
    companyCOFINS,
    isCalculateICMSWithOtherExpenses,
  } = useSelector((state) => state.form.NFe.values);
  const dispatch = useDispatch();

  useEffect(() => {
    calculateFooterValues();
  }, [NFeItems, discountValue, inssuranceValue, otherExpenses, freightValue]);

  function calculateFooterValues() {
    const footer = {
      BCICMS: 0,
      ShowBCICMS: 0,
      BCICMSST: 0,
      PISValue: 0,
      IPIValue: 0,
      FCPValue: 0,
      total: 0,
      ICMSValue: 0,
      ShowICMSValue: 0,
      ICMSSTValue: 0,
      COFINSValue: 0,
      FCPSTValue: 0,
    };

    for (let item of NFeItems) {
      const {
        BCICMS,
        ShowBCICMS,
        ICMSValue,
        ShowICMSValue,
        BCICMSST,
        ICMSSTValue,
        IPIValue,
        FCPValue,
        FCPSTValue,
        totalValue,
        STPISValue,
        STCOFINSValue,
      } = getTaxInfo(item);

      footer.BCICMS += BCICMS;
      footer.ShowBCICMS += ShowBCICMS;
      footer.ICMSValue += ICMSValue;
      footer.ShowICMSValue += ShowICMSValue;
      footer.BCICMSST += BCICMSST;
      footer.ICMSSTValue += ICMSSTValue;
      footer.IPIValue += IPIValue;
      footer.FCPValue += FCPValue;
      footer.total += totalValue;
      footer.PISValue += STPISValue;
      footer.COFINSValue += STCOFINSValue;
      footer.FCPSTValue += FCPSTValue;
    }

    dispatch([
      change('NFe', 'BCICMS', footer.BCICMS),
      change('NFe', 'ShowBCICMS', footer.ShowBCICMS),
      change('NFe', 'BCICMSST', footer.BCICMSST),
      change('NFe', 'PISValue', footer.PISValue),
      change('NFe', 'IPIValue', footer.IPIValue),
      change('NFe', 'FCPValue', footer.FCPValue),
      change('NFe', 'total', footer.total),
      change('NFe', 'ICMSValue', footer.ICMSValue),
      change('NFe', 'ShowICMSValue', footer.ShowICMSValue),
      change('NFe', 'ICMSSTValue', footer.ICMSSTValue),
      change('NFe', 'COFINSValue', footer.COFINSValue),
      change('NFe', 'FCPSTValue', footer.FCPSTValue),
    ]);
  }

  function getTaxInfo(product) {
    const { unitValue, unitType, origin, quantity } = product;
    const productValue = quantity * unitValue;

    const individualFreightValue = prorateValues(
      productValue,
      freightValue,
      subTotal
    );
    const individualInssuranceValue = prorateValues(
      productValue,
      inssuranceValue,
      subTotal
    );
    const individualDiscountValue = prorateValues(
      productValue,
      discountValue,
      subTotal
    );
    const individualOtherExpenses = prorateValues(
      productValue,
      otherExpenses,
      subTotal
    );

    const originDescription = getOriginDescription(origin);
    const unitTypeDescription = getUnitTypeDescription(unitType);
    const STPISValue = getPIS(product);
    const STCOFINSValue = getCOFINS(product);
    const { BCICMS, ICMSValue, ShowBCICMS, ShowICMSValue } = getICMS(product);
    const { BCICMSST, ICMSSTValue } = getICMSST(product);
    const IPIValue = getIPI(product);
    const FCPValue = getFCP(product);
    const FCPSTValue = getFCPST(product);

    const totalValue =
      productValue +
      individualInssuranceValue +
      individualFreightValue +
      individualOtherExpenses +
      ICMSSTValue +
      FCPSTValue +
      IPIValue -
      // Desconsiderado valor do desconto a pedido do P.O.
      // STPISValue +
      // STCOFINSValue -
      individualDiscountValue;

    return {
      ...product,
      BCICMS,
      ShowBCICMS,
      ICMSValue,
      BCICMSST,
      ICMSSTValue,
      ShowICMSValue,
      IPIValue,
      FCPValue,
      FCPSTValue,
      totalValue,
      unitTypeDescription,
      originDescription,
      STPISValue,
      STCOFINSValue,
    };
  }

  function getICMS(product) {
    const { unitValue, ICMSRed, ICMS, quantity, CSTCSOSN } = product;
    let BCICMS = 0;
    let ICMSValue = 0;

    let ShowBCICMS = 0;
    let ShowICMSValue = 0;

    if (ICMS) {
      const productValue = quantity * unitValue;
      const individualFreightValue = prorateValues(
        productValue,
        freightValue,
        subTotal
      );
      const individualInssuranceValue = prorateValues(
        productValue,
        inssuranceValue,
        subTotal
      );
      const individualDiscountValue = prorateValues(
        productValue,
        discountValue,
        subTotal
      );
      const individualOtherExpenses = prorateValues(
        productValue,
        otherExpenses,
        subTotal
      );

      BCICMS = parseFloat(
        (productValue +
          individualInssuranceValue +
          individualFreightValue +
          (isCalculateICMSWithOtherExpenses ? individualOtherExpenses : 0) -
          individualDiscountValue) *
          (1 - percent(ICMSRed)).toFixed(4)
      );
      ICMSValue = parseFloat((BCICMS * percent(ICMS)).toFixed(2));

      // Calculo que será exibido
      if (CSTCSOSN !== '201' && CSTCSOSN !== '202') {
        ShowBCICMS = parseFloat(
          (productValue +
            individualInssuranceValue +
            individualFreightValue +
            (isCalculateICMSWithOtherExpenses ? individualOtherExpenses : 0) -
            individualDiscountValue) *
            (1 - percent(ICMSRed)).toFixed(4)
        );
        ShowICMSValue = parseFloat((BCICMS * percent(ICMS)).toFixed(2));
      }
    }
    return {
      BCICMS,
      ICMSValue,
      ShowBCICMS,
      ShowICMSValue,
    };
  }

  function getIPI(product) {
    const { unitValue, IPI, quantity } = product;

    const productValue = quantity * unitValue;
    const value = parseFloat((productValue * percent(IPI)).toFixed(2));
    return value;
  }

  function getICMSST(product) {
    const { ICMSRedST, ICMSInternal, MVA } = product;

    const IPIValue = getIPI(product);
    const { BCICMS, ICMSValue } = getICMS(product);
    const MVAICMSRedST = (1 + percent(MVA)) * (1 - percent(ICMSRedST));

    const BCICMSST =
      !MVA || MVA <= 0
        ? 0
        : parseFloat(((BCICMS + IPIValue) * MVAICMSRedST).toFixed(2));

    const ICMSSTValue =
      BCICMSST <= 0
        ? 0
        : parseFloat((BCICMSST * percent(ICMSInternal) - ICMSValue).toFixed(2));

    return {
      BCICMSST,
      ICMSSTValue,
    };
  }

  function getFCP(product) {
    const { FCP } = product;
    const { BCICMS } = getICMS(product);

    const value = parseFloat((BCICMS * percent(FCP)).toFixed(2));
    return value;
  }

  function getPIS(product) {
    const { unitValue, STPIS, quantity } = product;

    if (STPIS === '01' || STPIS === '02') {
      const value = quantity * unitValue * (companyPIS / 100);
      return value;
    }

    return 0;
  }

  function getCOFINS(product) {
    const { unitValue, STCOFINS, quantity } = product;

    if (STCOFINS === '01' || STCOFINS === '02') {
      const value = quantity * unitValue * (companyCOFINS / 100);
      return value;
    }

    return 0;
  }

  function getUnitTypeDescription(unitType) {
    const typeUnity = typeUnities
      ? typeUnities.find((type) => type.Initials === unitType)
      : [];

    const unitTypeDescription = !typeUnity ? '' : typeUnity.Description;
    return unitTypeDescription;
  }

  function getOriginDescription(origin) {
    const value = !origin ? '' : `${origin} - ${PRODUCT_ORIGIN[origin]}`;

    return value;
  }

  function getFCPST(product) {
    const { FCP } = product;
    const { BCICMSST } = getICMSST(product);

    if (companyUF === 'RJ' && BCICMSST > 0) {
      const FCPValue = getFCP(product);

      const value = parseFloat((BCICMSST * percent(FCP) - FCPValue).toFixed(2));
      return value;
    }

    return 0;
  }

  function percent(value = 0) {
    return value / 100;
  }

  function prorateValues(value, valueToProrate, subTotal) {
    if (!valueToProrate) return 0;

    const difference = value / subTotal;

    const finalValue = parseFloat(valueToProrate * difference);
    return finalValue;
  }

  return {
    getTaxInfo,
  };
};

export default useProductTax;
