import React, { useEffect, useState } from 'react';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import { useSelector, useDispatch } from 'react-redux';
import {
  currency,
  percentage,
} from 'client/components/ToNormalize/ToNormalize';
import { handleSubmit, handleChange, cancelSale } from './redux/actions';
import ShareSale from 'client/components/ShareSale/ShareSale.js';
import AlertModal from 'components/AlertModal/AlertModal';
import { withRouter } from 'react-router';
import constants from '../../../../../utils/constants';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { useBonus } from 'hooks/useBonus';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';

function SaleFooter({ history }) {
  const { company } = useAuth();
  const [saleTypeName, setSaleTypeName] = useState('');
  const [isFieldAltered, setIsFieldAltered] = useState(false);
  const [paymentFormsOptions, setPaymentFormsOptions] = useState([]);
  const [fieldsInitialValues, setFieldsInitialValues] = useState();
  const [itialInstallment, setInitialInstallment] = useState();
  const [itialItems, setInitialItems] = useState();
  const sidenav = localStorage.getItem('EXPANDED');
  const dispatch = useDispatch();
  const { saleReducer } = useSelector((state) => state);
  const {
    selectedClient,
    servicesData,
    productsData,
    generalDiscountCash,
    generalDiscountPercentage,
    saleId,
    saleCode,
    saleTypeId,
    shareModal,
    saleStatusId,
    cancelModal,
    loadingSpin,
    opened,
    totalReceived,
    total,
    change,
    items,
    selectedVehicle,
    clientVehicles,
    observations,
    malfunctions,
    currentKilometers,
    defects,
    installments,
    condOfPayments,
    installmentFees,
    redeemedBonus,
    showReCloseModal,
    sat_id,
    nfe_id,
    nfce_id,
    nfse_id,
    hasGroupedTitles,
  } = saleReducer;

  const { isPlanFree, isPlanBasic, isPlanStart } = usePlanSignatureContext();
  const isPlanPrime = !isPlanFree && !isPlanBasic && !isPlanStart;

  const { companyHasCRMBonus } = useBonus();

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(handleSubmit(saleReducer, isPlanPrime, company));
  };

  function handleOpenRecloseSaleModal(e) {
    e.preventDefault();

    if (!!nfce_id || !!nfe_id || !!nfse_id || !!sat_id) {
      toastr.warning(
        'Não foi possível refechar a venda com nota fiscal',
        'Vendas com nota e/ou devoluções vinculadas não permitem refazer o fechamento. Caso a nota esteja aberta, exclua e tente novamente. Devoluções não podem ser canceladas.'
      );
      return;
    }

    if (hasGroupedTitles) {
      toastr.warning(
        'Não foi possível refechar a venda com títulos agrupados',
        'Venda com financeiro agrupado com outras vendas não permitem refazer o refechamento. '
      );
      return;
    }

    dispatch(handleChange(true, 'showRecloseModal'));
  }

  useEffect(() => {
    const isEqual =
      JSON.stringify(fieldsInitialValues) ===
      JSON.stringify({
        totalReceived,
        total,
        change,
        items,
        selectedVehicle,
        clientVehicles,
        observations,
        malfunctions,
        currentKilometers,
        defects,
        condOfPayments,
        generalDiscountCash,
        generalDiscountPercentage,
        saleStatusId,
        installments,
      });

    if (saleId) {
      if (!itialInstallment) {
        setInitialInstallment(JSON.parse(JSON.stringify(installments)));
      }
      if (!itialItems) {
        setInitialItems(JSON.parse(JSON.stringify(items)));
      }
      if (!fieldsInitialValues) {
        return setFieldsInitialValues({
          totalReceived,
          total,
          change,
          items,
          selectedVehicle,
          clientVehicles,
          observations,
          malfunctions,
          currentKilometers,
          defects,
          condOfPayments,
          generalDiscountCash,
          generalDiscountPercentage,
          saleStatusId,
          installments,
        });
      } else if (isEqual === false) {
        setIsFieldAltered(true);
      }
    }
  }, [
    totalReceived,
    total,
    change,

    selectedVehicle,
    clientVehicles,
    observations,
    malfunctions,
    currentKilometers,
    defects,
    condOfPayments,
    generalDiscountCash,
    generalDiscountPercentage,
    saleStatusId,
  ]);

  useEffect(() => {
    if (saleId) {
      let isItemsEqual = JSON.stringify(itialItems) === JSON.stringify(items);
      if (!isItemsEqual) {
        setIsFieldAltered(true);
      }
    }
  }, [saleReducer]);

  useEffect(() => {
    if (saleId) {
      const isInstallmentsEqual =
        JSON.stringify(itialInstallment) === JSON.stringify(installments);

      if (!isInstallmentsEqual) {
        setIsFieldAltered(true);
      }
    }
  }, [saleReducer]);

  useEffect(() => {
    let sale_Type;
    switch (saleTypeId) {
      case 1:
        sale_Type = 'Orçamento';
        break;
      case 2:
        sale_Type = 'Venda';
        break;
      case 3:
        sale_Type = 'Ordem de Serviço';
        break;
      default:
        sale_Type = 'Orçamento';
    }

    setSaleTypeName(sale_Type);
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <div
        id="sale-footer"
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
            padding: '0 5% 0 5%',
          }}
        >
          <div id="resumo">
            <a className={!saleId ? 'hidden' : ''}>
              <strong>Nº Venda: </strong>
              {saleCode ? saleCode : 0}
            </a>
            <a>
              <strong>Resumo</strong>
            </a>
            {
              <span
                className={!saleId ? 'hidden' : ''}
                onClick={() =>
                  !!saleId ? dispatch(handleChange(true, 'shareModal')) : null
                }
              >
                Compartilhar Venda
              </span>
            }
          </div>
          <div id="division-footer" />
          <div id="prices-footer">
            <div>
              <a>
                <strong>Cliente: </strong>
                {selectedClient.Company_Name?.split(' ')[0]}{' '}
              </a>
              <a>
                <strong>Total:</strong>
                <span style={{ color: 'green' }}>
                  {currency(total)}{' '}
                  <span style={{ color: 'red', fontSize: '11px' }}>
                    {installmentFees
                      ? `(${percentage(installmentFees)} de Juros)`
                      : ''}
                  </span>
                </span>
              </a>
              <a>
                <strong>Desconto: </strong>
                <span style={{ color: 'red' }}>
                  {`${currency(generalDiscountCash)} (${percentage(
                    generalDiscountPercentage
                  )})`}
                </span>
              </a>
            </div>
            <div>
              <a>
                <strong>Total de Produtos: </strong>
                <span style={{ color: 'green' }}>
                  {currency(productsData.total)}
                </span>
              </a>
              <a>
                <strong>Desconto: </strong>
                <span style={{ color: 'red' }}>
                  {`${currency(productsData.discount.value)} (${percentage(
                    productsData.discount.percent
                  )})`}
                </span>
              </a>
            </div>
            <div>
              <a>
                <strong>Total de Serviços: </strong>
                <span style={{ color: 'green' }}>
                  {currency(servicesData.total)}
                </span>
              </a>
              <a>
                <strong>Desconto: </strong>
                <span style={{ color: 'red' }}>
                  {`${currency(servicesData.discount.value)} (${percentage(
                    servicesData.discount.percent
                  )})`}
                </span>
              </a>
            </div>
            {companyHasCRMBonus && (
              <div>
                <a>
                  <strong>Bônus Resgatado: </strong>
                  <span style={{ color: 'green' }}>
                    {currency(redeemedBonus)}
                  </span>
                </a>
              </div>
            )}
            <div>
              <a>
                <strong>Total Pago: </strong>
                <span>{`${currency(totalReceived)}`}</span>
              </a>
              <a>
                <strong>Troco: </strong>
                <span>{`${currency(change)}`}</span>
              </a>
              <a>
                <strong>Em Aberto: </strong>
                <span style={{ color: 'red' }}>{`${currency(opened)}`}</span>
              </a>
            </div>
          </div>
          <div id="division-footer" />
          <div className="flex column gap-050">
            <div className="flex row end gap-050">
              <Button
                id="save-btn"
                bsStyle="info"
                fill
                type="submit"
                style={{ width: '92px' }}
                disabled={
                  saleStatusId === 5 ||
                  saleStatusId === 2 ||
                  saleStatusId === 6 ||
                  saleStatusId === 9 ||
                  loadingSpin
                }
              >
                <span
                  className={loadingSpin ? 'fa fa-spinner fa-pulse fa-1x' : ''}
                ></span>
                Salvar
              </Button>
            </div>
            <div className="flex row end gap-050">
              {!isPlanFree && !isPlanStart && saleStatusId === 5 && (
                <button
                  type="button"
                  className="button button-blue"
                  onClick={handleOpenRecloseSaleModal}
                  disabled={loadingSpin}
                  style={{ width: '92px' }}
                >
                  Refechar
                </button>
              )}
              <Button
                id="cancel-btn"
                bsStyle="danger"
                fill
                name="cancelar"
                onClick={() =>
                  isFieldAltered
                    ? dispatch(handleChange(true, 'cancelModal'))
                    : history.push(`${constants.ROUTES.SALES}`)
                }
                disabled={saleStatusId === 2 || loadingSpin}
                style={{ width: '92px' }}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      </div>

      {shareModal && (
        <ShareSale
          show={shareModal}
          animation={true}
          onHide={() => dispatch(handleChange(false, 'shareModal'))}
          sale_id={saleId}
          sale_Type={saleTypeName}
          code={saleCode}
          customer_id={selectedClient.id}
          onSubmit={() => history.push(`${constants.ROUTES.SALES}`)}
        />
      )}
      {isFieldAltered && (
        <AlertModal
          show={cancelModal}
          subtitle={'Você deseja sair sem salvar as alterações ?'}
          message={
            'Nenhuma alteração realizada será salva, mas você poderá realizar as alterações posteriormente'
          }
          onHide={() => dispatch(handleChange(false, 'cancelModal'))}
          onCancel={() => dispatch(handleChange(false, 'cancelModal'))}
          onSubmit={() => history.push(`${constants.ROUTES.SALES}`)}
        />
      )}
    </form>
  );
}

export default withRouter(SaleFooter);
