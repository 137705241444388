import React from 'react';

import { useBranding } from 'hooks/useBranding';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

import '../styles.css';

const Footer = () => {
  const { isPO360 } = useBranding();
  const { getMainLogoByBranding } = useBranding();

  return (
    <>
      <div
        className={`income-statement__footer-rectangle ${
          isPO360 ? 'po360-bg-primary' : ''
        }`}
      >
        <center>
          <img
            style={{
              width: currentBrandingName === 'PO360' ? '70px' : '150px',
              height: '45px',
            }}
            src={getMainLogoByBranding(true)}
            alt={`Logo ${currentBrandingName}`}
          />
        </center>
      </div>
    </>
  );
};

export default Footer;
