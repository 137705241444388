export const themeWM = {
  colors: {
    bannerText: '#DCE6F2',
    buttonGradient100: '#142a3d',
    buttonGradient200: '#2c3f50',
    buttonGradient300: '#445464',
    branding100: '#E7E9EB',
    branding200: '#D0D4D8',
    branding300: '#B8BFC4',
    branding400: '#A1A9B1',
    branding500: '#89949E',
    branding600: '#727F8A',
    branding700: '#5A6977',
    branding800: '#445464',
    branding900: '#2B3F50',
    branding1000: '#142a3d',
  },
};
