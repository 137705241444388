import api from '../services/api'
import config from '../config'

const getAll = async () => {
  const response = await api.get(config.endpoint + 'segment')
  return response.data
}

const getByUser = async userId => {
  return await api.get(config.endpoint + `companySegment/${userId}/by-user`)
}

const segmentsRepository = {
  getAll,
  getByUser
}

export default segmentsRepository