import { toastr } from 'react-redux-toastr';
import plansRepository from 'v2/repositories/Plans';

import faviconOSD from '../assets/favicon/osd.ico';
import faviconWM from '../assets/favicon/wm.ico';
import faviconTecnomotor from '../assets/favicon/tecnomotor.ico';
import faviconBarros from '../assets/favicon/barros.ico';
import faviconPO360 from '../assets/favicon/po360.ico';

import logo from 'assets/img/osdigital.png';
import logoWhite from 'assets/img/os-digital-letra-branca.png';

import logoWM from 'assets/img/workmotor/logo.png';
import logoTecnomotor from 'assets/img/tecnomotor/logo.png';
import logoBarros from 'assets/img/barros/logo.png';
import logoPO360 from 'assets/img/po360/logo.png';

import logoPO360White from 'assets/img/po360/logo-white.png';

import icon from 'assets/img/logo-osdigital.png';
import iconWM from 'assets/img/workmotor/logo-no-text-white.png';
import iconTecnomotor from 'assets/img/tecnomotor/logo-no-text-white.png';
import iconPO360 from 'assets/img/po360/logo-no-text-white.png';

export const useBranding = () => {
  const branding = process.env.REACT_APP_BRANDING || 'OSD';
  const brandingName = branding === 'WM' ? 'WorkMotor' : 'OS Digital';

  const getBrandingAcronymById = (id) => {
    switch (id) {
      case 1:
        return 'OSD';
      case 2:
        return 'WM';
      case 3:
        return 'Tecnomotor';
      case 4:
        return 'Barros';
      case 5:
        return 'PO360';
      default:
        return '';
    }
  };

  const getMainLogoByBranding = (whiteVersion) => {
    switch (branding) {
      case 'WM':
        return logoWM;
      case 'TECNOMOTOR':
        return logoTecnomotor;
      case 'BARROS':
        return logoBarros;
      case 'PO360':
        return whiteVersion ? logoPO360White : logoPO360;
      default:
        return whiteVersion ? logoWhite : logo;
    }
  };

  const getNavIconByBranding = () => {
    switch (branding) {
      case 'WM':
        return iconWM;
      case 'TECNOMOTOR':
        return iconTecnomotor;
      case 'BARROS':
        return '';
      case 'PO360':
        return iconPO360;
      default:
        return icon;
    }
  };

  const loadPlansByBranding = async (brandingId) => {
    try {
      const plans = await plansRepository.getAll(brandingId);
      return plans;
    } catch (err) {
      console.log(err);
      toastr.error('Erro ao Carregar os Planos', 'Tente novamente');
    }
  };

  const setDocumentTitleByBranding = () => {
    switch (branding) {
      case 'OSD':
        document.title = 'OS Digital';
        break;
      case 'WM':
        document.title = 'Work Motor';
        break;
      case 'TECNOMOTOR':
        document.title = 'Tecnomotor';
        break;
      case 'BARROS':
        document.title = 'Barros';
        break;
      case 'PO360':
        document.title = 'PO360';
        break;
      default:
        document.title = 'WorkGroup | Undefined Branding';
    }
  };

  const setFavicons = () => {
    switch (branding) {
      case 'OSD':
        const headTitleOSD = document.querySelector('head');
        const brandingFaviconOSD = document.createElement('link');
        brandingFaviconOSD.setAttribute('rel', 'shortcut icon');
        brandingFaviconOSD.setAttribute('href', faviconOSD);
        headTitleOSD.appendChild(brandingFaviconOSD);
        break;
      case 'WM':
        const headTitleWM = document.querySelector('head');
        const brandingFaviconWM = document.createElement('link');
        brandingFaviconWM.setAttribute('rel', 'shortcut icon');
        brandingFaviconWM.setAttribute('href', faviconWM);
        headTitleWM.appendChild(brandingFaviconWM);
        break;
      case 'TECNOMOTOR':
        const headTitleTecnomotor = document.querySelector('head');
        const brandingFaviconTecnomotor = document.createElement('link');
        brandingFaviconTecnomotor.setAttribute('rel', 'shortcut icon');
        brandingFaviconTecnomotor.setAttribute('href', faviconTecnomotor);
        headTitleTecnomotor.appendChild(brandingFaviconTecnomotor);
        break;
      case 'BARROS':
        const headTitleBarros = document.querySelector('head');
        const brandingFaviconBarros = document.createElement('link');
        brandingFaviconBarros.setAttribute('rel', 'shortcut icon');
        brandingFaviconBarros.setAttribute('href', faviconBarros);
        headTitleBarros.appendChild(brandingFaviconBarros);
        break;
      case 'PO360':
        const headTitlePO360 = document.querySelector('head');
        const brandingFaviconPO360 = document.createElement('link');
        brandingFaviconPO360.setAttribute('rel', 'shortcut icon');
        brandingFaviconPO360.setAttribute('href', faviconPO360);
        headTitlePO360.appendChild(brandingFaviconPO360);
        break;
      default:
        const headTitleDefault = document.querySelector('head');
        const brandingFaviconDefault = document.createElement('link');
        brandingFaviconDefault.setAttribute('rel', 'shortcut icon');
        brandingFaviconDefault.setAttribute('href', faviconOSD);
        headTitleDefault.appendChild(brandingFaviconDefault);
        break;
    }
  };

  return {
    isBarros: branding === 'BARROS',
    isTecnomotor: branding === 'TECNOMOTOR',
    isWorkmotor: branding === 'WM',
    isPO360: branding === 'PO360',
    brandingName,
    getBrandingAcronymById,
    loadPlansByBranding,
    setFavicons,
    setDocumentTitleByBranding,
    getMainLogoByBranding,
    getNavIconByBranding,
  };
};
