import { useRecoilState } from 'recoil';
import { INACTIVE_FISCAL_MODULE_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { INACTIVE_FISCAL_MODULE_PLAN_VARIATION_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { BLOCK_SEGMENTS_MODAL_OPEN } from 'v2/store/GlobalBlockModalsStore';
import { CURRENT_INACTIVE_FISCAL_MODULE } from 'v2/store/GlobalBlockModalsStore';
import { CURRENT_INACTIVE_MODULE } from 'v2/store/GlobalBlockModalsStore';
import { INACTIVE_MODULE_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';
import { FEATURE_NOT_AVAILABLE_MODAL_STATE } from 'v2/store/GlobalBlockModalsStore';

export function useBlockModals() {
  const [isFeatureNotAvailableModalOpen, setIsFeatureNotAvailableModalOpen] =
    useRecoilState(FEATURE_NOT_AVAILABLE_MODAL_STATE);

  const [isModuleInactiveModalOpen, setIsModuleInactiveModalOpen] =
    useRecoilState(INACTIVE_MODULE_MODAL_STATE);

  const [currentInactiveModule, setCurrentInactiveModule] = useRecoilState(
    CURRENT_INACTIVE_MODULE
  );

  const [currentInactiveFiscalModule, setCurrentInactiveFiscalModule] =
    useRecoilState(CURRENT_INACTIVE_FISCAL_MODULE);

  const [isFiscalModuleInactiveModalOpen, setIsFiscalModuleInactiveModalOpen] =
    useRecoilState(INACTIVE_FISCAL_MODULE_MODAL_STATE);

  const [
    isFiscalModuleInactivePlanVariationModalOpen,
    setIsFiscalModuleInactivePlanVariationModalOpen,
  ] = useRecoilState(INACTIVE_FISCAL_MODULE_PLAN_VARIATION_MODAL_STATE);

  const [isSegmentsBlockedModalOpen, setIsSegmentsBlockedModalOpen] =
    useRecoilState(BLOCK_SEGMENTS_MODAL_OPEN);

  return {
    isFeatureNotAvailableModalOpen,
    setIsFeatureNotAvailableModalOpen,

    isModuleInactiveModalOpen,
    setIsModuleInactiveModalOpen,

    currentInactiveModule,
    setCurrentInactiveModule,

    currentInactiveFiscalModule,
    setCurrentInactiveFiscalModule,

    isFiscalModuleInactiveModalOpen,
    setIsFiscalModuleInactiveModalOpen,

    isFiscalModuleInactivePlanVariationModalOpen,
    setIsFiscalModuleInactivePlanVariationModalOpen,

    isSegmentsBlockedModalOpen,
    setIsSegmentsBlockedModalOpen,
  };
}
