import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { currentBrandingId } from 'v2/helpers/brandingHelpers';
import TermsOfUseRepository from 'v2/repositories/TermsOfUseRepository';

export default function Termos() {
  const [brandingId, setBrandingId] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const getCurrentBrandingId = currentBrandingId;
        const brandingId = await getCurrentBrandingId;
        setBrandingId(brandingId);
      } catch (err) {
        console.log(err);
        toastr.error('Erro ao carregar os Termos', 'Tente novamente!');
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function loadPdfUrl() {
      try {
        if (brandingId) {
          const link = await TermsOfUseRepository.findByBranding(brandingId);
          if (link && link.length > 0) {
            const filteredLink = link[0].link;
            setPdfUrl(filteredLink);
          }
        }
      } catch (err) {
        console.log(err);
        toastr.error('Erro ao carregar a URL dos Termos', 'Tente novamente!');
      }
    }

    loadPdfUrl();
  }, [brandingId]);

  useEffect(() => {
    async function fetchPdfData() {
      try {
        if (pdfUrl) {
          const response = await fetch(pdfUrl);
          const arrayBuffer = await response.arrayBuffer();
          const base64Data = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          setPdfData(`data:application/pdf;base64,${base64Data}`);
        }
      } catch (err) {
        console.log('Error fetching PDF data:', err);
        toastr.error('Erro ao carregar o PDF de Termos', 'Tente novamente!');
      }
    }

    fetchPdfData();
  }, [pdfUrl]);

  return (
    <div style={{ height: '100vh', width: '100vw', textAlign: 'center' }}>
      <h1>Termos de Uso - {brandingId === 1 ? 'OS Digital ' : 'WorkMotor '}</h1>
      {pdfData ? (
        <object
          data={pdfData}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <p>
            Seu navegador não suporta visualização de PDF. Você pode fazer o
            download do arquivo clicando no link abaixo:
          </p>
          <a
            href={pdfUrl}
            download={`termos_${
              brandingId === 1 ? 'OS_Digital ' : 'WorkMotor '
            }.pdf`}
            rel="noopener noreferrer"
          >
            Baixar PDF
          </a>
        </object>
      ) : (
        <p>Carregando o PDF...</p>
      )}
    </div>
  );
}
