import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { change, initialize } from 'redux-form';
import { useDispatch } from 'react-redux';

import providersRepository from '../../../../../repositories/Providers';
import { useAuth } from '../../../../../contexts/auth';
import {
  onlyNumbers,
  cepMask,
  cnpjMask,
  phoneMask,
  RgMask,
  cpfMask,
} from '../../../../components/ToNormalize/ToNormalize';
import {
  validadeEmail,
  validadePhone,
  validateCpf,
} from '../../../../components/Validations/Validations';

import Form from './FormNewProvider';

const FormNewProvider = ({ providerId, onCancel, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const dispatch = useDispatch();
  const { companyId } = useAuth();

  useEffect(() => {
    if (!!providerId) {
      loadProvider();
    }
  }, []);

  useEffect(() => {
    if (isDataLoaded) {
      setTimeout(() => {
        dispatch(change('formProvider', 'preventSearchCep', false));
      }, 500);
    }
  }, [isDataLoaded]);

  const loadProvider = async () => {
    setLoading(true);
    try {
      const provider = await providersRepository.getById(providerId);
      const providerAddress = provider.Address[0];
      const providerPhones = provider.Phones[0];

      // Realiza a formatação para cpf ou cnpj caso dado exista
      if (!!provider.cpfCnpj) {
        provider.type === 'Juridica'
          ? (provider.cpfCnpj = cnpjMask(provider.cpfCnpj))
          : (provider.cpfCnpj = cpfMask(provider.cpfCnpj));
      } else {
        provider.cpfCnpj = null;
      }

      const initialProvider = {
        providerId: provider.id,
        type: provider.type,
        companyName: provider.companyName,
        tradingName: provider.tradingName,
        isActive: provider.isActive,
        email: provider.email,
        cpfCnpj: provider.cpfCnpj,
        RG: provider.RG ? RgMask(provider.RG) : '',
        comments: provider.comments,
        date: new Date(provider.createdAt).toLocaleDateString(),
        statusIE: provider.statusIE,
        IE: provider.IE,
        commercialPhone: providerPhones.Number_Phone1
          ? phoneMask(providerPhones.Number_Phone1)
          : '',
        contact: providerPhones.Contact,
        cellPhone: phoneMask(providerPhones.Number_Phone2),
        phonesId: providerPhones.id,
        preventSearchCep: true,
      };

      if (!!providerAddress) {
        initialProvider.Zipcode = cepMask(providerAddress.Zipcode);
        initialProvider.Address = providerAddress.Address;
        initialProvider.Address_Number = providerAddress.Address_Number;
        initialProvider.Neighborhood = providerAddress.Neighborhood;
        initialProvider.Complement = providerAddress.Complement;
        initialProvider.Reference_Point = providerAddress.Reference_Point;
        initialProvider.State = providerAddress.State;
        initialProvider.City = providerAddress.City;
        initialProvider.Country = providerAddress.Country;
        initialProvider.Status = !!providerAddress.IsActive;
        initialProvider.addressId = providerAddress.id;
      }

      dispatch(initialize('formProvider', initialProvider));
      setIsDataLoaded(true);
      setEdit(true);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar o fornecedor. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const formValidation = (values) => {
    const {
      IE,
      cellPhone,
      commercialPhone,
      companyName,
      cpfCnpj,
      email,
      statusIE,
      tradingName,
      contact,
      type,
    } = values;

    if (!companyName || !email || !cellPhone) {
      return formValidationError('Por favor, preencha os campos obrigatórios.');
    }

    if (type === 'Juridica') {
      // if (!tradingName || !contact || !cpfCnpj) {
      //   return formValidationError(
      //     'Por favor, preencha os campos obrigatórios.'
      //   )
      // }

      if (IE && !statusIE) {
        return formValidationError(
          'Selecione o indicador da inscrição estadual'
        );
      }

      if (statusIE === '1' && !IE) {
        return formValidationError('Insira a  Inscrição Estadual');
      }

      if (commercialPhone && !validadePhone(commercialPhone)) {
        return formValidationError('Telefone Comercial inválido');
      }
    }

    if (type === 'Fisica' && cpfCnpj) {
      if (!validateCpf(onlyNumbers(cpfCnpj))) {
        return formValidationError('CPF inválido');
      }
    }

    if (!validadeEmail(email)) {
      return formValidationError('E-mail inválido');
    }

    if (!validadePhone(cellPhone)) {
      return formValidationError('Telefone Celular inválido');
    }

    return true;
  };

  const formValidationError = (error) => {
    toastr.warning(error);
    return false;
  };

  const handleSubmit = async (values) => {
    const isValid = await formValidation(values);
    if (!isValid) return;

    if (!providerId) {
      create(values);
    } else {
      update(values);
    }
  };

  const create = async (values) => {
    const {
      Address,
      Address_Number,
      City,
      Complement,
      IE,
      Neighborhood,
      Reference_Point,
      State,
      Zipcode,
      cellPhone,
      comments,
      commercialPhone,
      companyName,
      contact,
      cpfCnpj,
      RG,
      isActive,
      date,
      email,
      statusIE,
      tradingName,
      type,
    } = values;

    setLoading(true);

    try {
      if (cpfCnpj) {
        const providerWithCnpj = await providersRepository.search({
          companyId,
          cpfCnpj: onlyNumbers(cpfCnpj),
        });

        if (!!providerWithCnpj) {
          toastr.warning('Fornecedor com CNPJ já existente');
          return;
        }
      }

      const providerValues = {
        provider: {
          companyName,
          tradingName,
          cpfCnpj: cpfCnpj ? onlyNumbers(cpfCnpj) : '',
          RG: RG ? onlyNumbers(RG) : '',
          type,
          IE,
          statusIE,
          email,
          companyId,
          isActive,
          comments,
          date: new Date(date),
        },
        phones: {
          commercialPhone: commercialPhone ? onlyNumbers(commercialPhone) : '',
          cellPhone: onlyNumbers(cellPhone),
          contact,
        },
      };

      if (!!Zipcode && !!Address) {
        providerValues.address = {
          zipcode: onlyNumbers(Zipcode),
          street: Address,
          complement: Complement,
          neighborhood: Neighborhood,
          city: City,
          state: State,
          streetNumber: !Address_Number ? '' : Address_Number,
          referencePoint: Reference_Point,
        };
      }

      const provider = await providersRepository.create(providerValues);
      onSubmit(provider);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao salvar o fornecedor. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const update = async (values) => {
    const {
      Address,
      Address_Number,
      City,
      Complement,
      IE,
      Neighborhood,
      Reference_Point,
      State,
      Zipcode,
      cellPhone,
      comments,
      commercialPhone,
      companyName,
      contact,
      cpfCnpj,
      RG,
      isActive,
      date,
      email,
      statusIE,
      tradingName,
      type,
      addressId,
      phonesId,
      cityIbgeCode,
    } = values;

    setLoading(true);
    const providerValues = {
      provider: {
        companyName,
        tradingName,
        cpfCnpj: cpfCnpj ? onlyNumbers(cpfCnpj) : null,
        RG: RG ? onlyNumbers(RG) : null,
        type,
        IE,
        statusIE,
        email,
        isActive,
        comments,
        date: new Date(date),
      },
      phones: {
        phonesId,
        commercialPhone: commercialPhone ? onlyNumbers(commercialPhone) : '',
        cellPhone: onlyNumbers(cellPhone),
        contact,
      },
    };

    if (!!Zipcode && !!Address) {
      providerValues.address = {
        addressId,
        zipcode: onlyNumbers(Zipcode),
        street: Address,
        complement: Complement,
        neighborhood: Neighborhood,
        city: City,
        state: State,
        streetNumber: Address_Number,
        referencePoint: Reference_Point,
        cityIbgeCode,
      };
    }

    try {
      await providersRepository.update(providerId, providerValues);
      setLoading(false);
      onSubmit({ id: providerId });
    } catch (err) {
      setLoading(false);
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao atualizar o fornecedor. Por favor, tente novamente'
      );
    }
  };

  const initialValues = {
    type: 'Juridica',
    isActive: true,
    date: new Date().toLocaleDateString(),
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        loading={loading}
        providerId={providerId}
        onCancel={onCancel}
        edit={edit}
      />
    </div>
  );
};

export default FormNewProvider;
