import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useState } from 'react';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const SalesPerDayDocument = ({ responseData }) => {
  const [headerData] = useState(responseData.header);
  const [totalsData] = useState(responseData.totals);
  const [reportData] = useState(responseData.report);

  return (
    <Document
      title={`impresso-${format(new Date(), 'dd/MM/yyyy')}`}
      subject="vendas-por-dia"
    >
      <Page style={styles.page} orientation={'landscape'} wrap={false}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '35%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Vendas Por dia</Text>
          </View>
          <Header headerData={headerData} />
        </View>
        <Totalizators totalsData={totalsData} />
        <SalesPerDayListing reportData={reportData} />
      </Page>
    </Document>
  );
};
const formatDate = (dateString) => {
  const date = new Date(dateString + 'T03:00:01Z');
  return format(date, 'dd/MM/yyyy');
};

const Header = ({ headerData }) => {
  return (
    <View style={{ width: '65%', flexDirection: 'row' }}>
      <View
        style={{
          flexDirection: 'column',
          width: '35%',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Tipo de Data: </Text>
          </View>
          <View>
            <Text>{headerData.typeOfDate}</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'column',
          width: '65%',
          marginBottom: '5px',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Data Início: </Text>
          </View>
          <View style={{ marginRight: '5px' }}>
            <Text>{formatDate(headerData.initialDate)}</Text>
          </View>

          <View>
            <Text style={styles.strong}>Data Fim: </Text>
          </View>
          <View>
            <Text>{formatDate(headerData.finalDate)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const Totalizators = ({ totalsData }) => {
  return (
    <>
      <TotalizatorsHeader />
      <TotalizatorsData totalizatorsData={totalsData} />
    </>
  );
};
const TotalizatorsHeader = () => {
  return (
    <>
      <View style={[styles.line, { marginTop: '10px' }]} />
      <View>
        <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
          Valores por Tipo de Venda
        </Text>
      </View>
      <View style={[styles.line]} />
      <View
        style={{
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          right: '2px',
        }}
      >
        <Text style={[styles.boldText, { width: '20%' }]}>Tipo</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Valor Bruto</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>
          Descontos Gerais
        </Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Produto</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Custo</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Margem</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Valor Serviço</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Valor Líquido</Text>
      </View>
      <View style={styles.line} />
    </>
  );
};
const TotalizatorsData = ({ totalizatorsData }) => {
  return (
    totalizatorsData &&
    totalizatorsData.map((element, key) => (
      <>
        <View
          key={key}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'left',
            padding: '1px',
          }}
        >
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{element.type}</Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{currency(element.grossValue)}</Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>
              {currency(element.generalDiscounts)}
            </Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{currency(element.productValue)}</Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{currency(element.productCost)}</Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{currency(element.margin)}</Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{currency(element.serviceValue)}</Text>
          </View>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>{currency(element.liquidValue)}</Text>
          </View>
        </View>
      </>
    ))
  );
};

const SalesPerDayListing = ({ reportData }) => {
  return (
    <>
      <ListingHeader />
      <ListingData listingData={reportData} />
    </>
  );
};
const ListingHeader = () => {
  return (
    <>
      <View style={[styles.line, { marginTop: '10px' }]} />
      <View>
        <Text style={[styles.title, { textAlign: 'center', margin: '2px 0' }]}>
          Listagem de Valores
        </Text>
      </View>
      <View style={[styles.line]} />
      <View style={[styles.line, { marginTop: '10px' }]} />
      <View
        style={{
          display: 'flex',
          padding: '2px',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          right: '2px',
        }}
      >
        <Text style={[styles.boldText, { width: '20%' }]}>Data</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Valor Bruto</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>
          Descontos Gerais
        </Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Produto</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Custo</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Margem</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Valor Serviço</Text>
        <Text style={[styles.boldText, { width: '20%' }]}>Valor Líquido</Text>
      </View>
      <View style={styles.line} />
    </>
  );
};
const ListingData = ({ listingData }) => {
  return (
    <>
      {listingData &&
        listingData.map((data, key) => (
          <View
            key={key}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'left',
              padding: '1px',
            }}
          >
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>
                {format(getDateOnlyFromDate(new Date(data.date)), 'dd/MM/yyyy')}
              </Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>{currency(data.grossValue)}</Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>{currency(data.generalDiscounts)}</Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>{currency(data.productValue)}</Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>{currency(data.productCost)}</Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>{currency(data.margin)}</Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>{currency(data.serviceValue)}</Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text style={styles.text}>{currency(data.liquidValue)}</Text>
            </View>
          </View>
        ))}
    </>
  );
};

export default SalesPerDayDocument;

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  boldText: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  mediumText: {
    fontSize: 10,
    fontWeight: 'medium',
    fontFamily: 'Roboto',
  },
  text: {
    fontSize: 9,
    fontFamily: 'Roboto',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
