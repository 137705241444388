import React from 'react';
import { Document, Text, Page, StyleSheet, View } from '@react-pdf/renderer';
import {
  cepMask,
  cpfOrCnpjMask,
  currency,
  kmMask,
  percentage,
  phoneMask,
} from '../../../client/components/ToNormalize/ToNormalize';
import { addDays, format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';

const Header = ({ initialDate, finalDate, searchBy, dateType, reportType }) => {
  return (
    <View style={{ width: '60%', flexDirection: 'row' }}>
      <View
        style={{
          flexDirection: 'column',
          width: '30%',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Tipo de Relatório: </Text>
          </View>
          <View>
            <Text>{reportType}</Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Tipo de Data: </Text>
          </View>
          <View>
            <Text>{dateType}</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'column',
          width: '70%',
          marginBottom: '5px',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Pesquisa por: </Text>
          </View>
          <View>
            <Text>{searchBy}</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <View>
            <Text style={styles.strong}>Data Início: </Text>
          </View>
          <View style={{ marginRight: '5px' }}>
            <Text>{initialDate}</Text>
          </View>

          <View>
            <Text style={styles.strong}>Data Fim: </Text>
          </View>
          <View>
            <Text>{finalDate}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const HeaderVehicleDetails = ({ data, reportType, vehicleId }) => {
  return data.map((item) => {
    return (
      <View style={{ marginTop: 3, marginBottom: 3 }}>
        <View style={styles.containerRow}>
          <View style={{ width: '5%' }}>
            <Text style={styles.strong}>Veículo: </Text>
          </View>
          <View style={{ width: '30%' }}>
            <Text>{`${
              reportType === 'ANALYTICAL'
                ? item.sales[0].vehicleLicensePlate ||
                  item.sales[0].vehicleMaintenanceObject ||
                  item.sales[0].vehicleSerialIdentification
                : item.sales[0].rows[0].vehicleLicensePlate ||
                  item.sales[0].rows[0].vehicleMaintenanceObject ||
                  item.sales[0].rows[0].vehicleSerialIdentification
            } - ${
              reportType === 'ANALYTICAL'
                ? `${item.sales[0].vehicleModel}`
                : `${item.sales[0].rows[0].vehicleModel}`
            }`}</Text>
          </View>

          <View style={{ width: '3%' }}>
            <Text style={styles.strong}>KM: </Text>
          </View>
          <View style={{ width: '10%' }}>
            <Text>
              {reportType === 'ANALYTICAL'
                ? item.sales[0].vehicleKm
                : item.sales[0].rows[0].vehicleKm.toString()}
            </Text>
          </View>

          <View style={{ width: '7%' }}>
            <Text style={styles.strong}>Montadora: </Text>
          </View>
          <View style={{ width: '10%' }}>
            <Text>
              {reportType === 'ANALYTICAL'
                ? item.sales[0].vehicleBrand
                : item.sales[0].rows[0].vehicleBrand}
            </Text>
          </View>

          <View style={{ width: '5%' }}>
            <Text style={styles.strong}>Chassi: </Text>
          </View>
          <View style={{ width: '15%' }}>
            <Text>
              {reportType === 'ANALYTICAL'
                ? item.sales[0].vehicleChassi
                : item.sales[0].rows[0].vehicleChassi}
            </Text>
          </View>
        </View>

        <View
          style={[
            styles.containerRow,
            {
              marginBottom: '5px',
            },
          ]}
        >
          <View style={{ width: '11%' }}>
            <Text style={styles.strong}>Combustível: </Text>
          </View>
          <View style={{ width: '15%' }}>
            <Text>
              {reportType === 'ANALYTICAL'
                ? item.sales[0].vehicleFuelType
                : item.sales[0].rows[0].vehicleFuelType}
            </Text>
          </View>

          <View style={{ width: '4%' }}>
            <Text style={styles.strong}>Cor: </Text>
          </View>
          <View style={{ width: '10%' }}>
            <Text>
              {reportType === 'ANALYTICAL'
                ? item.sales[0].vehicleColor
                : item.sales[0].rows[0].vehicleColor}
            </Text>
          </View>

          <View style={styles.containerRow}>
            <View style={{ width: '23%' }}>
              <Text style={styles.strong}>Cliente de última Venda/OS:</Text>
            </View>
            <View style={{ width: '30%' }}>
              <Text>
                {reportType === 'ANALYTICAL'
                  ? item.sales[item.sales.length - 1].saleCode
                  : item.sales[item.sales.length - 1].rows[
                      item.sales.length - 1
                    ].saleCode}
                {` - `}
                {reportType === 'ANALYTICAL'
                  ? item.sales[item.sales.length - 1].companyName ||
                    item.sales[item.sales.length - 1].tradingName
                  : item.sales[item.sales.length - 1].rows[
                      item.sales.length - 1
                    ].companyName ||
                    item.sales[item.sales.length - 1].rows[
                      item.sales.length - 1
                    ].tradingName}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  });
};

const HeaderCustomerDetails = ({ customer }) => {
  return customer.map((item) => {
    return (
      <View style={{ marginTop: 3, marginBottom: 3 }}>
        <View wrap={false}>
          <View style={[styles.containerRow]}>
            <View style={{ width: '7%' }}>
              <Text style={styles.strong}>CPF/CNPJ: </Text>
            </View>
            <View style={{ width: '20%' }}>
              <Text>{cpfOrCnpjMask(item.cpfCnpj)}</Text>
            </View>
            <View style={{ width: '12%' }}>
              <Text style={styles.strong}>Razão Social/Nome: </Text>
            </View>
            <View>
              <Text>{item.tradingName || item.companyName}</Text>
            </View>
          </View>
          <View style={[styles.containerRow]}>
            <View style={{ width: '6%' }}>
              <Text style={styles.strong}>Telefone: </Text>
            </View>
            <View style={{ width: '13%' }}>
              <Text>{phoneMask(item.numberPhone2 || item.numberPhone1)}</Text>
            </View>

            <View style={{ width: '4%' }}>
              <Text style={styles.strong}>Email: </Text>
            </View>
            <View style={{ width: '22%' }}>
              <Text>{item.email}</Text>
            </View>

            <View style={{ width: '6%' }}>
              <Text style={styles.strong}>Endereço: </Text>
            </View>
            <View style={{ width: '22%' }}>
              <Text>{item.addressDetails}</Text>
            </View>

            <View style={{ width: '2%' }}>
              <Text style={styles.strong}>Nº: </Text>
            </View>
            <View style={{ marginLeft: '2px' }}>
              <Text>{item.addressNumber}</Text>
            </View>
          </View>
          <View style={[styles.containerRow]}>
            <View style={{ width: '4%' }}>
              <Text style={styles.strong}>Bairro: </Text>
            </View>
            <View style={{ width: '15%' }}>
              <Text>{item.addressNeighborhood}</Text>
            </View>

            <View style={{ width: '7%' }}>
              <Text style={styles.strong}>Cidade/UF: </Text>
            </View>
            <View style={{ width: '15%' }}>
              <Text>
                {item.addressCity}/{item.addressState}
              </Text>
            </View>

            <View style={{ width: '3%' }}>
              <Text style={styles.strong}>CEP: </Text>
            </View>
            <View>
              <Text>{cepMask(item.addressZipCode)}</Text>
            </View>
          </View>
          <View style={[styles.line]} />
        </View>
      </View>
    );
  });
};

const SyntheticSalesDetails = ({
  data,
  searchBy,
  vehicleId,
  showObservations,
}) => {
  return (
    <View>
      {data.map((element) => {
        return (
          <View>
            {searchBy === 'CLIENT' && (
              <View wrap={false}>
                <View style={[styles.line]} />
                <View style={[styles.containerRow]}>
                  <View style={{ width: '7%' }}>
                    <Text style={styles.strong}>CPF/CNPJ: </Text>
                  </View>
                  <View style={{ width: '20%' }}>
                    <Text>{cpfOrCnpjMask(element.cpfCnpj)}</Text>
                  </View>

                  <View style={{ width: '12%' }}>
                    <Text style={styles.strong}>Razão Social/Nome: </Text>
                  </View>
                  <View>
                    <Text>{element.tradingName || element.companyName}</Text>
                  </View>
                </View>
                <View style={[styles.containerRow]}>
                  <View style={{ width: '6%' }}>
                    <Text style={styles.strong}>Telefone: </Text>
                  </View>
                  <View style={{ width: '13%' }}>
                    <Text>
                      {phoneMask(element.numberPhone2 || element.numberPhone1)}
                    </Text>
                  </View>

                  <View style={{ width: '4%' }}>
                    <Text style={styles.strong}>Email: </Text>
                  </View>
                  <View style={{ width: '22%' }}>
                    <Text>{element.email}</Text>
                  </View>

                  <View style={{ width: '6%' }}>
                    <Text style={styles.strong}>Endereço: </Text>
                  </View>
                  <View style={{ width: '22%' }}>
                    <Text>{element.addressDetails}</Text>
                  </View>

                  <View style={{ width: '2%' }}>
                    <Text style={styles.strong}>Nº: </Text>
                  </View>
                  <View style={{ marginLeft: '2px' }}>
                    <Text>{element.addressNumber}</Text>
                  </View>
                </View>
                <View style={[styles.containerRow]}>
                  <View style={{ width: '4%' }}>
                    <Text style={styles.strong}>Bairro: </Text>
                  </View>
                  <View style={{ width: '15%' }}>
                    <Text>{element.addressNeighborhood}</Text>
                  </View>

                  <View style={{ width: '7%' }}>
                    <Text style={styles.strong}>Cidade/UF: </Text>
                  </View>
                  <View style={{ width: '15%' }}>
                    <Text>
                      {element.addressCity}/{element.addressState}
                    </Text>
                  </View>

                  <View style={{ width: '3%' }}>
                    <Text style={styles.strong}>CEP: </Text>
                  </View>
                  <View>
                    <Text>{cepMask(element.addressZipCode)}</Text>
                  </View>
                </View>
              </View>
            )}

            {searchBy === 'VEHICLE' && !vehicleId && (
              <View>
                <View style={[styles.line]} />
                <View
                  style={[
                    styles.containerRow,
                    {
                      marginTop: '5px',
                    },
                  ]}
                >
                  <View style={{ width: '5%' }}>
                    <Text style={styles.strong}>Veículo: </Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text>{`${
                      element.sales[0].rows[0].vehicleLicensePlate ||
                      element.sales[0].rows[0].vehicleMaintenanceObject ||
                      element.sales[0].rows[0].vehicleSerialIdentification
                    } - ${
                      element.sales[0].rows[0].vehicleModel === null
                        ? ''
                        : element.sales[0].rows[0].vehicleModel
                    }`}</Text>
                  </View>

                  <View style={{ width: '3%' }}>
                    <Text style={styles.strong}>KM: </Text>
                  </View>
                  <View style={{ width: '10%' }}>
                    <Text>{element.sales[0].rows[0].vehicleKm}</Text>
                  </View>

                  <View style={{ width: '7%' }}>
                    <Text style={styles.strong}>Montadora: </Text>
                  </View>
                  <View style={{ width: '10%' }}>
                    <Text>{element.sales[0].rows[0].vehicleBrand}</Text>
                  </View>

                  <View style={{ width: '5%' }}>
                    <Text style={styles.strong}>Chassi: </Text>
                  </View>
                  <View style={{ width: '15%' }}>
                    <Text>{element.sales[0].rows[0].vehicleChassi}</Text>
                  </View>
                </View>

                <View
                  style={[
                    styles.containerRow,
                    {
                      marginBottom: '5px',
                    },
                  ]}
                >
                  <View style={{ width: '11%' }}>
                    <Text style={styles.strong}>Combustível: </Text>
                  </View>
                  <View style={{ width: '15%' }}>
                    <Text>{element.sales[0].rows[0].vehicleFuelType}</Text>
                  </View>

                  <View style={{ width: '4%' }}>
                    <Text style={styles.strong}>Cor: </Text>
                  </View>
                  <View style={{ width: '10%' }}>
                    <Text>{element.sales[0].rows[0].vehicleColor}</Text>
                  </View>

                  <View style={styles.containerRow}>
                    <View style={{ width: '23%' }}>
                      <Text style={styles.strong}>
                        Cliente de última Venda/OS:
                      </Text>
                    </View>
                    <View style={{ width: '30%' }}>
                      <Text>
                        {
                          element.sales[element.sales.length - 1].rows[
                            element.sales[element.sales.length - 1].rows
                              .length - 1
                          ].saleCode
                        }
                        {` - `}
                        {element.sales[element.sales.length - 1].rows[
                          element.sales[element.sales.length - 1].rows.length -
                            1
                        ].companyName ||
                          element.sales[element.sales.length - 1].rows[
                            element.sales[element.sales.length - 1].rows
                              .length - 1
                          ].tradingName}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}

            {searchBy === 'CLIENT' && (
              <>
                <View style={[styles.line]} />
                <View>
                  <Text style={styles.titleTexts}>Data</Text>
                </View>
              </>
            )}

            <View style={styles.line} />
            <View style={styles.containerRow}>
              <View style={{ width: '10%' }}>
                <Text style={styles.titleTexts}>Venda</Text>
              </View>
              <View style={{ width: '7%' }}>
                <Text style={styles.titleTexts}>Tipo</Text>
              </View>
              <View style={{ width: searchBy === 'CLIENT' ? '30%' : '10%' }}>
                <Text style={styles.titleTexts}>
                  {searchBy === 'CLIENT'
                    ? 'Placa/Obj. Manut. - Modelo'
                    : 'CPF/CNPJ'}
                </Text>
              </View>
              {searchBy === 'VEHICLE' && (
                <View style={{ width: '30%' }}>
                  <Text style={styles.titleTexts}>
                    Nome Completo/Razão Social
                  </Text>
                </View>
              )}
              <View style={{ width: '10%' }}>
                <Text style={styles.titleTexts}>KM</Text>
              </View>
              <View style={{ width: '10%' }}>
                <Text style={styles.titleTexts}>Nº da NF</Text>
              </View>
              <View style={{ width: '12%' }}>
                <Text style={styles.titleTexts}>Desconto Geral</Text>
              </View>
              <View style={{ width: '12%' }}>
                <Text style={styles.titleTexts}>Total Venda</Text>
              </View>
            </View>

            <View style={[styles.line]} />
            {element.sales.map((sale, index) => (
              <View style={{ marginBottom: 10 }}>
                <View>
                  <Text style={[styles.strong]}>{sale.saleDate}</Text>
                </View>

                {sale.rows.map((row, index) => (
                  <View style={[styles.containerRow]} key={index}>
                    <View style={{ width: '10%' }}>
                      <Text style={styles.text}>
                        {row.saleCode} ({row.salesSubtype})
                      </Text>
                    </View>
                    <View style={{ width: '7%' }}>
                      <Text style={styles.text}>
                        {row.salesTypeId === 1
                          ? 'Orçamento'
                          : row.salesTypeId === 2
                          ? 'Venda'
                          : row.salesTypeId === 3
                          ? 'OS'
                          : row.salesTypeId === 4
                          ? 'PDV'
                          : ''}
                      </Text>
                    </View>
                    <View
                      style={{ width: searchBy === 'CLIENT' ? '30%' : '10%' }}
                    >
                      <Text style={styles.text}>
                        {searchBy === 'CLIENT'
                          ? `${
                              row.vehicleLicensePlate
                                ? row.vehicleLicensePlate
                                : ''
                            } - ${row.vehicleModel ? row.vehicleModel : ''}`
                          : `${cpfOrCnpjMask(element.cpfCnpj)}`}
                        {}
                      </Text>
                    </View>
                    {searchBy === 'VEHICLE' && (
                      <View style={{ width: '30%' }}>
                        <Text style={styles.text}>
                          {element.tradingName || element.companyName}
                        </Text>
                      </View>
                    )}
                    <View style={{ width: '10%' }}>
                      <Text style={styles.text}>
                        {kmMask(
                          row && row.vehicleKm ? row.vehicleKm.toString() : ''
                        )}
                      </Text>
                    </View>
                    <View style={{ width: '10%', marginBottom: 10 }}>
                      <Text style={styles.text}>
                        {row.taxCodeNFe &&
                          `NFe: ${row.taxCodeNFe ? row.taxCodeNFe : ''}`}
                      </Text>
                      <Text style={styles.text}>
                        {row.taxCodeNFCe &&
                          `NFCe: ${row.taxCodeNFCe ? row.taxCodeNFCe : ''}`}
                      </Text>
                      <Text style={styles.text}>
                        {row.taxCodeNFSe &&
                          `NFSe: ${row.taxCodeNFSe ? row.taxCodeNFSe : ''}`}
                      </Text>
                      <Text style={styles.text}>
                        {row.taxCodeSAT &&
                          `SAT: ${row.taxCodeSAT ? row.taxCodeSAT : ''}`}
                      </Text>
                    </View>
                    <View style={{ width: '12%' }}>
                      <Text style={styles.text}>
                        {row && row.discountValue
                          ? currency(row.discountValue)
                          : currency(0)}
                      </Text>
                    </View>
                    <View style={{ width: '12%' }}>
                      <Text style={styles.text}>
                        {row && row.saleFinalValue
                          ? currency(row.saleFinalValue)
                          : currency(0)}
                      </Text>
                    </View>
                  </View>
                ))}

                <View style={[styles.containerRow, { width: '100%' }]}>
                  {showObservations === 1 && (
                    <>
                      <Text style={[styles.strong, { width: '10%' }]}>
                        Observações:
                      </Text>
                      <Text
                        style={[
                          styles.strong,
                          { width: searchBy === 'VEHICLE' ? '57%' : '47%' },
                        ]}
                      >
                        {sale.observations}
                      </Text>
                    </>
                  )}

                  {showObservations !== 1 && (
                    <View
                      style={{
                        width: searchBy === 'VEHICLE' ? '67%' : '57%',
                      }}
                    />
                  )}

                  <Text style={[styles.strong, { width: '10%' }]}>
                    Total por dia:
                  </Text>
                  <Text style={[styles.strong, { width: '12%' }]}>
                    {currency(sale.discountTotalSum)}
                  </Text>
                  <Text style={[styles.strong, { width: '12%' }]}>
                    {currency(sale.totalValueSum)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        );
      })}
    </View>
  );
};

const AnalyticalSalesDetails = ({
  customersAndSales,
  customerId,
  searchBy,
  vehicleId,
  showObservations,
}) => {
  return (
    <View>
      <View>
        {customersAndSales.map((element, index) => {
          return (
            <>
              {element.sales.map((sale, index) => {
                return (
                  <>
                    <View style={[styles.line]} />
                    {customerId === null && searchBy === 'CLIENT' && (
                      <>
                        <View style={{ marginTop: 3, marginBottom: 3 }}>
                          <View wrap={false}>
                            <View style={[styles.containerRow]}>
                              <View style={{ width: '7%' }}>
                                <Text style={styles.strong}>CPF/CNPJ: </Text>
                              </View>
                              <View style={{ width: '20%' }}>
                                <Text>{cpfOrCnpjMask(sale.cpfCnpj)}</Text>
                              </View>
                              <View style={{ width: '12%' }}>
                                <Text style={styles.strong}>
                                  Razão Social/Nome:{' '}
                                </Text>
                              </View>
                              <View>
                                <Text>
                                  {sale.tradingName || sale.companyName}
                                </Text>
                              </View>
                            </View>
                            <View style={[styles.containerRow]}>
                              <View style={{ width: '6%' }}>
                                <Text style={styles.strong}>Telefone: </Text>
                              </View>
                              <View style={{ width: '13%' }}>
                                <Text>
                                  {phoneMask(
                                    sale.numberPhone2 || sale.numberPhone1
                                  )}
                                </Text>
                              </View>

                              <View style={{ width: '4%' }}>
                                <Text style={styles.strong}>Email: </Text>
                              </View>
                              <View style={{ width: '22%' }}>
                                <Text>{sale.email}</Text>
                              </View>

                              <View style={{ width: '6%' }}>
                                <Text style={styles.strong}>Endereço: </Text>
                              </View>
                              <View style={{ width: '22%' }}>
                                <Text>{sale.addressDetails}</Text>
                              </View>

                              <View style={{ width: '2%' }}>
                                <Text style={styles.strong}>Nº: </Text>
                              </View>
                              <View style={{ marginLeft: '2px' }}>
                                <Text>{sale.addressNumber}</Text>
                              </View>
                            </View>
                            <View style={[styles.containerRow]}>
                              <View style={{ width: '4%' }}>
                                <Text style={styles.strong}>Bairro: </Text>
                              </View>
                              <View style={{ width: '15%' }}>
                                <Text>{sale.addressNeighborhood}</Text>
                              </View>

                              <View style={{ width: '7%' }}>
                                <Text style={styles.strong}>Cidade/UF: </Text>
                              </View>
                              <View style={{ width: '15%' }}>
                                <Text>
                                  {sale.addressCity}/{sale.addressState}
                                </Text>
                              </View>

                              <View style={{ width: '3%' }}>
                                <Text style={styles.strong}>CEP: </Text>
                              </View>
                              <View>
                                <Text>{cepMask(sale.addressZipCode)}</Text>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View style={styles.line} />
                      </>
                    )}
                    {searchBy === 'CLIENT' &&
                    !customerId &&
                    customerId !== null ? (
                      <>
                        <View
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                        >
                          <View style={{ width: '16%' }}>
                            <Text style={styles.text}>
                              <Text style={styles.titleTexts}>
                                Data Criação:{' '}
                              </Text>
                              {format(
                                new Date(
                                  getDateOnlyFromDate(sale.saleCreatedAt)
                                ),
                                'dd/MM/yyyy'
                              )}
                            </Text>
                          </View>
                          <View style={{ width: '19%' }}>
                            <Text style={styles.text}>
                              <Text style={styles.titleTexts}>
                                Data Fechamento:
                              </Text>
                              {format(
                                new Date(
                                  getDateOnlyFromDate(sale.saleUpdatedAt)
                                ),
                                'dd/MM/yyyy'
                              )}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                        >
                          <View style={{ width: '25%' }}>
                            <Text style={styles.text}>
                              <Text style={styles.titleTexts}>Cliente: </Text>
                              {element.tradingName || element.companyName}
                            </Text>
                          </View>
                          <View style={{ width: '10%' }}>
                            <Text style={styles.text}>
                              <Text style={styles.titleTexts}>Venda/OS: </Text>
                              {sale.saleCode}
                            </Text>
                          </View>
                          <View style={{ width: '10%' }}>
                            <Text style={styles.text}>
                              <Text style={styles.titleTexts}>Valor:</Text>
                              {currency(sale.saleFinalValue)}
                            </Text>
                          </View>
                          <View style={{ width: '13%' }}>
                            <Text style={styles.text}>
                              <Text style={styles.titleTexts}>
                                Desconto Geral:
                              </Text>
                              {currency(sale.discountValue)}
                            </Text>
                          </View>
                          <View style={{ width: '30%' }}>
                            <Text style={styles.text}>
                              <Text style={styles.titleTexts}>Veículo: </Text>
                              {`${
                                sale.vehicleLicensePlate
                                  ? sale.vehicleLicensePlate
                                  : ''
                              } - ${
                                sale.vehicleModel ? sale.vehicleModel : ''
                              }`}
                            </Text>
                          </View>
                          <View style={{ width: '10%' }}>
                            <Text style={styles.text}>
                              <Text style={styles.titleTexts}>KM: </Text>
                              {kmMask(
                                sale && sale.vehicleKm
                                  ? sale.vehicleKm.toString()
                                  : ''
                              )}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.line]} />
                      </>
                    ) : searchBy === 'VEHICLE' && !vehicleId ? (
                      <>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            marginTop: '5px',
                          }}
                        >
                          <View style={{ width: '5%' }}>
                            <Text style={styles.strong}>Veículo: </Text>
                          </View>
                          <View style={{ width: '30%' }}>
                            <Text>{`${
                              sale.vehicleLicensePlate === null
                                ? ''
                                : sale.vehicleLicensePlate ||
                                  (sale.vehicleMaintenanceObject === null
                                    ? ''
                                    : sale.vehicleMaintenanceObject ||
                                      (sale.vehicleSerialIdentification === null
                                        ? ''
                                        : sale.vehicleSerialIdentification))
                            } - ${
                              sale.vehicleModel === null
                                ? ''
                                : sale.vehicleModel
                            }`}</Text>
                          </View>

                          <View style={{ width: '3%' }}>
                            <Text style={styles.strong}>KM: </Text>
                          </View>
                          <View style={{ width: '10%' }}>
                            <Text>
                              {sale.vehicleKm ? sale.vehicleKm.toString() : ''}
                            </Text>
                          </View>

                          <View style={{ width: '7%' }}>
                            <Text style={styles.strong}>Montadora: </Text>
                          </View>
                          <View style={{ width: '10%' }}>
                            <Text>
                              {sale.vehicleBrand ? sale.vehicleBrand : ''}
                            </Text>
                          </View>

                          <View style={{ width: '5%' }}>
                            <Text style={styles.strong}>Chassi: </Text>
                          </View>
                          <View style={{ width: '15%' }}>
                            <Text>
                              {sale.vehicleChassi ? sale.vehicleChassi : ''}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={[
                            styles.containerRow,
                            {
                              marginBottom: '5px',
                            },
                          ]}
                        >
                          <View style={{ width: '11%' }}>
                            <Text style={styles.strong}>Combustível: </Text>
                          </View>
                          <View style={{ width: '15%' }}>
                            <Text>
                              {sale.vehicleFuelType ? sale.vehicleFuelType : ''}
                            </Text>
                          </View>

                          <View style={{ width: '4%' }}>
                            <Text style={styles.strong}>Cor: </Text>
                          </View>
                          <View style={{ width: '10%' }}>
                            <Text>
                              {sale.vehicleColor ? sale.vehicleColor : ''}
                            </Text>
                          </View>

                          <View style={styles.containerRow}>
                            <View style={{ width: '23%' }}>
                              <Text style={styles.strong}>
                                Cliente de última Venda/OS:
                              </Text>
                            </View>
                            <View style={{ width: '30%' }}>
                              <Text>
                                {sale.saleCode}
                                {` - `}
                                {sale.companyName || sale.tradingName}
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View style={styles.line} />
                      </>
                    ) : searchBy === 'VEHICLE' && vehicleId ? (
                      <>
                        <View
                          style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                          }}
                        >
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                              marginTop: '5px',
                            }}
                          >
                            <View style={{ width: '7%' }}>
                              <Text style={styles.strong}>Venda/OS: </Text>
                            </View>
                            <View style={{ width: '5%' }}>
                              <Text>{sale.saleCode ? sale.saleCode : ''}</Text>
                            </View>

                            <View style={{ width: '6%' }}>
                              <Text style={styles.strong}>Sub-total: </Text>
                            </View>
                            <View style={{ width: '7%' }}>
                              <Text>
                                {sale.saleSubTotal
                                  ? currency(sale.saleSubTotal)
                                  : ''}
                              </Text>
                            </View>

                            <View style={{ width: '10%' }}>
                              <Text style={styles.strong}>
                                Desconto Geral:{' '}
                              </Text>
                            </View>
                            <View style={{ width: '5%' }}>
                              <Text>
                                {sale
                                  ? currency(
                                      sale.discountValue + sale.saleCrmBonus
                                    )
                                  : ''}
                              </Text>
                            </View>

                            <View style={{ width: '8%' }}>
                              <Text style={styles.strong}>Total Venda: </Text>
                            </View>
                            <View style={{ width: '7%' }}>
                              <Text>
                                {sale
                                  ? currency(
                                      sale.saleSubTotal -
                                        (sale.discountValue + sale.saleCrmBonus)
                                    )
                                  : ''}
                              </Text>
                            </View>

                            <View style={{ width: '8%' }}>
                              <Text style={styles.strong}>Data Criação: </Text>
                            </View>
                            <View style={{ width: '10%' }}>
                              <Text>
                                {sale
                                  ? format(
                                      new Date(
                                        getDateOnlyFromDate(sale.saleCreatedAt)
                                      ),
                                      'dd/MM/yyyy'
                                    )
                                  : ''}
                              </Text>
                            </View>

                            <View style={{ width: '11%' }}>
                              <Text style={styles.strong}>
                                Data Fechamento:{' '}
                              </Text>
                            </View>
                            <View style={{ width: '10%' }}>
                              <Text>
                                {sale
                                  ? format(
                                      new Date(
                                        getDateOnlyFromDate(sale.saleUpdatedAt)
                                      ),
                                      'dd/MM/yyyy'
                                    )
                                  : ''}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                              marginBottom: '5px',
                            }}
                          >
                            <View style={{ width: '5%' }}>
                              <Text style={styles.strong}>Cliente: </Text>
                            </View>
                            <View style={{ width: '25%' }}>
                              <Text>{`${sale.customerId} - ${
                                sale.companyName || sale.tradingName
                              }`}</Text>
                            </View>

                            <View style={{ width: '5%' }}>
                              <Text style={styles.strong}>Celular: </Text>
                            </View>
                            <View style={{ width: '12%' }}>
                              <Text>
                                {phoneMask(
                                  sale.numberPhone2 || sale.numberPhone1
                                )}
                              </Text>
                            </View>

                            {sale.customerType !== 'Juridica' && (
                              <>
                                <View style={{ width: '8%' }}>
                                  <Text style={styles.strong}>
                                    Responsável:
                                  </Text>
                                </View>
                                <View style={{ width: '8%' }}>
                                  <Text>{sale.employeeName}</Text>
                                </View>
                              </>
                            )}

                            <View style={{ width: '7%' }}>
                              <Text style={styles.strong}>Endereço: </Text>
                            </View>
                            <View style={{ width: '30%' }}>
                              <Text>{`${sale.addressDetails}, ${sale.addressNumber} - ${sale.addressNeighborhood}, ${sale.addressCity}`}</Text>
                            </View>
                          </View>
                          <View style={styles.line} />
                        </View>
                      </>
                    ) : searchBy === 'CLIENT' && customerId ? (
                      <>
                        <View
                          style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                          }}
                        >
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                              marginTop: '5px',
                            }}
                          >
                            <View style={{ width: '7%' }}>
                              <Text style={styles.strong}>Venda/OS: </Text>
                            </View>
                            <View style={{ width: '5%' }}>
                              <Text>{sale.saleCode ? sale.saleCode : ''}</Text>
                            </View>

                            <View style={{ width: '6%' }}>
                              <Text style={styles.strong}>Sub-total: </Text>
                            </View>
                            <View style={{ width: '7%' }}>
                              <Text>
                                {sale.saleSubTotal
                                  ? currency(sale.saleSubTotal)
                                  : ''}
                              </Text>
                            </View>

                            <View style={{ width: '10%' }}>
                              <Text style={styles.strong}>
                                Desconto Geral:{' '}
                              </Text>
                            </View>
                            <View style={{ width: '5%' }}>
                              <Text>
                                {sale
                                  ? currency(
                                      sale.discountValue + sale.saleCrmBonus
                                    )
                                  : ''}
                              </Text>
                            </View>

                            <View style={{ width: '8%' }}>
                              <Text style={styles.strong}>Total Venda: </Text>
                            </View>
                            <View style={{ width: '7%' }}>
                              <Text>
                                {sale
                                  ? currency(
                                      sale.saleSubTotal -
                                        (sale.discountValue + sale.saleCrmBonus)
                                    )
                                  : ''}
                              </Text>
                            </View>

                            <View style={{ width: '8%' }}>
                              <Text style={styles.strong}>Data Criação: </Text>
                            </View>
                            <View style={{ width: '10%' }}>
                              <Text>
                                {sale
                                  ? format(
                                      new Date(
                                        getDateOnlyFromDate(sale.saleCreatedAt)
                                      ),
                                      'dd/MM/yyyy'
                                    )
                                  : ''}
                              </Text>
                            </View>

                            <View style={{ width: '11%' }}>
                              <Text style={styles.strong}>
                                Data Fechamento:{' '}
                              </Text>
                            </View>
                            <View style={{ width: '10%' }}>
                              <Text>
                                {sale
                                  ? format(
                                      new Date(
                                        getDateOnlyFromDate(sale.saleUpdatedAt)
                                      ),
                                      'dd/MM/yyyy'
                                    )
                                  : ''}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                              marginBottom: '5px',
                            }}
                          >
                            <View style={{ width: '6%' }}>
                              <Text style={styles.strong}>Veículo: </Text>
                            </View>
                            <View style={{ width: '30%' }}>
                              <Text>{`${
                                sale.vehicleLicensePlate === null
                                  ? ''
                                  : sale.vehicleLicensePlate ||
                                    (sale.vehicleMaintenanceObject === null
                                      ? ''
                                      : sale.vehicleMaintenanceObject ||
                                        (sale.vehicleSerialIdentification ===
                                        null
                                          ? ''
                                          : sale.vehicleSerialIdentification))
                              } - ${
                                sale.vehicleModel === null
                                  ? ''
                                  : sale.vehicleModel
                              }`}</Text>
                            </View>
                            <View style={{ width: '3%' }}>
                              <Text style={styles.strong}>KM: </Text>
                            </View>
                            <View style={{ width: '8%' }}>
                              <Text>
                                {sale.vehicleKm
                                  ? sale.vehicleKm.toString()
                                  : ''}
                              </Text>
                            </View>

                            <View style={{ width: '7%' }}>
                              <Text style={styles.strong}>Montadora: </Text>
                            </View>
                            <View style={{ width: '7%' }}>
                              <Text>{sale ? sale.vehicleBrand : ''}</Text>
                            </View>

                            <View style={{ width: '5%' }}>
                              <Text style={styles.strong}>Chassi: </Text>
                            </View>
                            <View style={{ width: '15%' }}>
                              <Text>{sale ? sale.vehicleChassi : ''}</Text>
                            </View>

                            <View style={{ width: '8%' }}>
                              <Text style={styles.strong}>Combustível: </Text>
                            </View>
                            <View style={{ width: '15%' }}>
                              <Text>{sale ? sale.vehicleFuelType : ''}</Text>
                            </View>
                          </View>
                          <View style={styles.line} />
                        </View>
                      </>
                    ) : (
                      <></>
                    )}
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      <View style={{ width: '8%' }}>
                        <Text style={styles.strong}>Código</Text>
                      </View>

                      <View style={{ width: '8%' }}>
                        <Text style={styles.strong}>Tipo</Text>
                      </View>

                      <View style={{ width: '25%' }}>
                        <Text style={styles.strong}>Descrição</Text>
                      </View>

                      <View style={{ width: '12%' }}>
                        <Text style={styles.strong}>Marca</Text>
                      </View>

                      <View style={{ width: '8%' }}>
                        <Text style={styles.strong}>Quantidade</Text>
                      </View>

                      <View style={{ width: '8%' }}>
                        <Text style={styles.strong}>R$ Custo</Text>
                      </View>

                      <View style={{ width: '10%' }}>
                        <Text style={styles.strong}>Preço Unit</Text>
                      </View>

                      <View style={{ width: '10%' }}>
                        <Text style={styles.strong}>Desc. Unit (%)</Text>
                      </View>

                      <View style={{ width: '10%' }}>
                        <Text style={styles.strong}>Total</Text>
                      </View>
                    </View>
                    <View style={styles.line} />
                    {sale.items.map((saleItem, index) => {
                      return (
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                          key={index}
                        >
                          <View style={{ width: '8%' }}>
                            <Text style={styles.content}>
                              {saleItem.productCode || saleItem.serviceCode}
                            </Text>
                          </View>

                          <View style={{ width: '8%' }}>
                            <Text style={styles.content}>
                              {saleItem.saleItemType}
                            </Text>
                          </View>

                          <View style={{ width: '25%' }}>
                            <Text style={styles.content}>
                              {saleItem.saleItemDescription}
                            </Text>
                          </View>

                          <View style={{ width: '12%' }}>
                            <Text style={styles.content}>
                              {saleItem.productBrand}
                            </Text>
                          </View>

                          <View style={{ width: '8%' }}>
                            <Text style={styles.content}>
                              {saleItem.productQuantity}
                            </Text>
                          </View>

                          <View style={{ width: '8%' }}>
                            <Text style={styles.content}>
                              {currency(
                                saleItem.saleItemValueCost *
                                  saleItem.productQuantity
                              )}
                            </Text>
                          </View>

                          <View style={{ width: '10%' }}>
                            <Text style={styles.content}>
                              {currency(saleItem.productUnitValue)}
                            </Text>
                          </View>

                          <View style={{ width: '10%' }}>
                            <Text style={styles.content}>
                              {percentage(saleItem.productUnitDiscountValue)}
                            </Text>
                          </View>

                          <View style={{ width: '10%' }}>
                            <Text style={styles.content}>
                              {currency(saleItem.productTotal)}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <View
                        style={[
                          styles.line,
                          {
                            width: showObservations === 1 ? '100%' : '82%',
                            marginTop: '5px',
                          },
                        ]}
                      />
                      <View
                        style={{
                          width: '100%',
                          marginTop: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                          }}
                        >
                          {showObservations === 1 ? (
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '18%',
                              }}
                            >
                              <Text
                                style={[styles.strong, { fontSize: '10px' }]}
                              >
                                Observações:
                              </Text>
                              <Text style={{ fontSize: '10px' }}>
                                {sale.observations}
                              </Text>
                            </View>
                          ) : (
                            <View style={{ width: '18%' }} />
                          )}
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '25%',
                            }}
                          >
                            <Text style={[styles.strong, { fontSize: '10px' }]}>
                              Condição de pagamento:
                            </Text>
                            <Text style={{ fontSize: '10px' }}>
                              {sale.payments[0].paymentCondition}
                            </Text>
                          </View>
                          <View style={{ width: '16%' }}>
                            <Text
                              style={[
                                styles.strong,
                                {
                                  alignItems: 'flex-start',
                                  fontSize: '10px',
                                },
                              ]}
                            >
                              Vencimento
                            </Text>
                          </View>
                          <View style={{ width: '20%' }}>
                            <Text style={[styles.strong, { fontSize: '10px' }]}>
                              Forma de Pagamento
                            </Text>
                          </View>
                          <View
                            style={{
                              width: '10%',
                            }}
                          >
                            <Text style={[styles.strong, { fontSize: '10px' }]}>
                              Valor
                            </Text>
                          </View>
                          <View style={{ width: '3%' }}>
                            <Text style={[styles.strong, { fontSize: '10px' }]}>
                              Pago?
                            </Text>
                          </View>
                        </View>

                        {sale.payments.map((payment, index) => {
                          return (
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '4px',
                              }}
                              key={index}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  width: '35%',
                                }}
                              />
                              <View style={{ width: '16%' }}>
                                <Text
                                  style={{
                                    alignItems: 'flex-start',
                                    fontSize: '10px',
                                    marginLeft: '64px',
                                  }}
                                >
                                  {format(
                                    getDateOnlyFromDate(
                                      new Date(payment.parcelDueDate)
                                    ),
                                    'dd/MM/yyyy'
                                  )}
                                </Text>
                              </View>
                              <View style={{ width: '20%' }}>
                                <Text
                                  style={{
                                    fontSize: '10px',
                                    marginLeft: '64px',
                                  }}
                                >
                                  {payment.formOfPaymentDescription}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: '10%',
                                }}
                              >
                                <Text
                                  style={[
                                    { fontSize: '10px', marginLeft: '64px' },
                                  ]}
                                >
                                  {currency(parseFloat(sale.salesSubtypeId === 1 ? payment.valueParcel : 0))}
                                </Text>
                              </View>
                              <View style={{ width: '3%' }}>
                                <Text
                                  style={{
                                    fontSize: '10px',
                                    marginLeft: '64px',
                                  }}
                                >
                                  {Number(payment.isReceived) === 1
                                    ? 'Sim'
                                    : 'Não'}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  </>
                );
              })}
            </>
          );
        })}
      </View>
      <View style={{ marginBottom: '25px' }} />
    </View>
  );
};

const ResumeHeaderTotalizatorByVehicleAndClient = ({ resumeData }) => {
  return (
    <>
      <View style={styles.containerRow}>
        <View style={{ width: '20%' }}>
          <Text style={styles.titleTexts}>Tipo</Text>
        </View>
        <View style={{ width: '7%' }}>
          <Text style={styles.titleTexts}>QTD</Text>
        </View>
      </View>

      <View style={[styles.line]} />

      {resumeData &&
        resumeData[0] &&
        resumeData[0].totalizatorsByVehicleAndClient.map((item, index) => {
          return (
            <View key={index} style={{ flexDirection: 'row' }}>
              <View style={{ width: '20%' }}>
                <Text style={styles.content}>{item.name}</Text>
              </View>
              <View style={{ width: '7%' }}>
                <Text style={styles.content}>{item.value}</Text>
              </View>
            </View>
          );
        })}
    </>
  );
};

const ResumeHeaderAverageTicket = ({ resumeData }) => {
  return (
    <>
      {resumeData &&
        resumeData[0] &&
        resumeData[0].totalizatorAverageTicket.rows.map((item, index) => {
          return (
            <View key={index} style={{ flexDirection: 'row' }}>
              <View style={{ width: '20%' }}>
                <Text style={styles.content}>{item.name}</Text>
              </View>
              <View style={{ width: '7%' }}>
                <Text style={styles.content}>
                  {item.name === 'Total Geral Vendido'
                    ? currency(item.value)
                    : item.value}
                </Text>
              </View>
            </View>
          );
        })}

      <View style={[styles.line, { width: '40%' }]} />

      <View style={[styles.containerRow]}>
        <View style={{ width: '20%' }}>
          <Text style={styles.strong}>Ticket Médio</Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text style={styles.strong}>
            {currency(
              resumeData &&
                resumeData[0] &&
                resumeData[0].totalizatorAverageTicket &&
                resumeData[0].totalizatorAverageTicket.averageTicket
            )}
          </Text>
        </View>
      </View>
    </>
  );
};

const ResumeHeader = ({ resumeData }) => {
  console.log(resumeData);

  return (
    <>
      <View style={styles.containerRow}>
        <View style={{ width: '20%' }}>
          <Text style={styles.titleTexts}>Tipo</Text>
        </View>
        <View style={{ width: '7%' }}>
          <Text style={styles.titleTexts}>QTD</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text style={styles.titleTexts}>Sub-total</Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.titleTexts}>Total Desconto Geral</Text>
        </View>
        <View style={{ width: '15%' }}>
          <Text style={styles.titleTexts}>Total Geral das Vendas</Text>
        </View>
      </View>
      <View style={[styles.line]} />
      {resumeData &&
        resumeData[0] &&
        resumeData[0].totalizators &&
        resumeData[0].totalizators.rows.map((item) => {
          return (
            <View key={item.id} style={{ flexDirection: 'row' }}>
              <View style={{ width: '20%' }}>
                <Text style={styles.content}>{item.typeItem}</Text>
              </View>
              <View style={{ width: '7%' }}>
                <Text style={styles.content}>{item.qtdSale}</Text>
              </View>
              <View style={{ width: '10%' }}>
                <Text style={styles.content}>
                  {item.os > 0
                    ? currency(item.os)
                    : item.pdv > 0
                    ? currency(item.pdv)
                    : item.sale > 0
                    ? currency(item.sale)
                    : item.orcamento > 0
                    ? currency(item.orcamento)
                    : currency(0)}
                </Text>
              </View>
              <View style={{ width: '15%' }}>
                <Text style={styles.content}>
                  {item.discountTotalGeneralOs > 0
                    ? currency(item.discountTotalGeneralOs)
                    : item.discountTotalGeneralPdv > 0
                    ? currency(item.discountTotalGeneralPdv)
                    : item.discountTotalGeneralSale > 0
                    ? currency(item.discountTotalGeneralSale)
                    : item.discountTotalGeneralOrcamento > 0
                    ? currency(item.discountTotalGeneralOrcamento)
                    : currency(0)}
                </Text>
              </View>
              <View style={{ width: '15%' }}>
                <Text style={styles.content}>
                  {item.totalGeneralOs > 0
                    ? currency(item.totalGeneralOs)
                    : item.totalGeneralPdv > 0
                    ? currency(item.totalGeneralPdv)
                    : item.totalGeneralSale > 0
                    ? currency(item.totalGeneralSale)
                    : item.totalGeneralOrcamento > 0
                    ? currency(item.totalGeneralOrcamento)
                    : currency(0)}
                </Text>
              </View>
            </View>
          );
        })}
      <View style={[styles.line, { width: '70%' }]} />
      <View style={[styles.containerRow]}>
        <View style={{ width: '20%' }}>
          <Text style={styles.strong}>Total</Text>
        </View>

        <View style={{ width: '7%' }}>
          <Text style={styles.strong}>
            {resumeData &&
              resumeData[0] &&
              resumeData[0].totalizators &&
              resumeData[0].totalizators.rowsQuantity}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text style={styles.strong}>
            {currency(
              resumeData &&
                resumeData[0] &&
                resumeData[0].totalizators &&
                resumeData[0].totalizators.rowsTotal
            )}
          </Text>
        </View>

        <View style={{ width: '15%' }}>
          <Text style={styles.strong}>
            {currency(
              resumeData &&
                resumeData[0] &&
                resumeData[0].totalizators &&
                resumeData[0].totalizators.rowsDiscountGeneral
            )}
          </Text>
        </View>

        <View style={{ width: '15%' }}>
          <Text style={styles.strong}>
            {currency(
              resumeData &&
                resumeData[0] &&
                resumeData[0].totalizators &&
                resumeData[0].totalizators.rowsTotalGeneral
            )}
          </Text>
        </View>
      </View>
      <View style={[styles.containerRow, { marginTop: 5 }]}>
        <View style={{ width: '20%' }}>
          <Text style={styles.strong}>Orçamentos em Aberto: </Text>
        </View>

        <View style={{ width: '7%' }}>
          <Text style={styles.strong}>
            {resumeData &&
              resumeData[0] &&
              resumeData[0].totalizators &&
              resumeData[0].totalizators.openBudgetsQuantity}
          </Text>
        </View>

        <View>
          <Text style={styles.strong}>
            {currency(
              resumeData &&
                resumeData[0] &&
                resumeData[0].totalizators &&
                resumeData[0].totalizators.openBudgetsValue
            )}
          </Text>
        </View>
      </View>
      <View style={[styles.containerRow, { marginBottom: '10px' }]}>
        <View style={{ width: '20%' }}>
          <Text style={styles.strong}>Ordens de Serviço em Aberto: </Text>
        </View>

        <View style={{ width: '7%' }}>
          <Text style={styles.strong}>
            {resumeData &&
              resumeData[0] &&
              resumeData[0].totalizators &&
              resumeData[0].totalizators.openServiceOrderQuantity}
          </Text>
        </View>

        <View>
          <Text style={styles.strong}>
            {currency(
              resumeData &&
                resumeData[0] &&
                resumeData[0].totalizators &&
                resumeData[0].totalizators.openServiceOrderValue
            )}
          </Text>
        </View>
      </View>

      <View style={[styles.containerRow, { justifyContent: 'flex-end' }]}>
        <Text style={{ fontSize: '8px', fontWeight: 'normal', color: 'red' }}>
          * Esse totalizador indica apenas as vendas do subtipo vendas. Outros
          subtipos não serão contabilizados.
        </Text>
      </View>

      <View
        style={[
          styles.containerRow,
          {
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          },
        ]}
      >
        {resumeData[0]?.totalizatorBySalesSubtype.map((item) => (
          <View key={item.id} style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '20%' }}>
              <Text style={styles.strong}>{item.description}: </Text>
            </View>

            <View style={{ width: '7%' }}>
              <Text style={styles.strong}>{item.quantity}</Text>
            </View>

            <View>
              <Text style={styles.strong}>{currency(item.total || 0)}</Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};

export const SalesPassingDocument = ({
  initialDate,
  finalDate,
  searchBy,
  dateType,
  reportType,
  showObservations,
  salesStatus,
  customerId,
  vehicleId,
  data,
}) => {
  return (
    <Document
      title={`venda-os-passagens${format(new Date(), 'dd/MM/yyyy')}`}
      subject="vendas"
    >
      <Page style={styles.page} orientation="landscape">
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: '40%',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.title}>Relatório de Venda/OS - Passagens</Text>
          </View>

          <Header
            initialDate={initialDate}
            finalDate={finalDate}
            searchBy={searchBy === 'CLIENT' ? 'Cliente' : 'Veículo'}
            dateType={dateType === 'CLOSURE' ? 'Fechamento' : 'Criação'}
            reportType={reportType === 'SYNTHETIC' ? 'Sintético' : 'Analítico'}
          />
        </View>

        <View style={[styles.line]} />

        {reportType === 'ANALYTICAL' && customerId && (
          <>
            <HeaderCustomerDetails customer={data} />
          </>
        )}

        {searchBy === 'VEHICLE' && vehicleId && (
          <>
            <HeaderVehicleDetails
              data={data}
              reportType={reportType}
              vehicleId={vehicleId}
            />
            <View style={[styles.line]} />
          </>
        )}

        <View>
          <Text style={styles.subtitle}>
            Totais por Tipo de Venda (
            {salesStatus === 5
              ? 'Finalizadas'
              : salesStatus === 3
              ? 'Em Aberto'
              : 'Canceladas'}
            )
          </Text>
        </View>

        <View style={[styles.line]} />

        <ResumeHeader resumeData={data} />

        <View style={[styles.line]} />

        <View>
          <Text style={styles.subtitle}>
            Totalizador de Veículos / Clientes
          </Text>
        </View>

        <View style={[styles.line]} />

        <ResumeHeaderTotalizatorByVehicleAndClient resumeData={data} />

        <View style={[styles.line]} />

        <View>
          <Text style={styles.subtitle}>Ticket Médio</Text>
        </View>

        <View style={[styles.line]} />

        <ResumeHeaderAverageTicket resumeData={data} />

        <View style={[styles.line]} />

        <View>
          <Text style={styles.subtitle}>Listagem de Vendas</Text>
        </View>

        {reportType === 'SYNTHETIC' ? (
          <SyntheticSalesDetails
            data={data}
            searchBy={searchBy}
            vehicleId={vehicleId}
            showObservations={showObservations}
          />
        ) : (
          <View />
        )}

        {reportType === 'ANALYTICAL' ? (
          <>
            <AnalyticalSalesDetails
              customersAndSales={data}
              customerId={customerId}
              searchBy={searchBy}
              vehicleId={vehicleId}
              showObservations={showObservations}
            />
          </>
        ) : (
          <View />
        )}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  containerRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  line: {
    width: '100%',
    borderBottomWidth: 1,
    height: 2,
  },
  text: {
    fontSize: 10,
  },
  content: {
    fontSize: 10,
  },
  titleTexts: {
    fontWeight: 'bold',
  },
  debitsText: {
    fontSize: 10,
    color: '#FF0000',
  },
  strong: {
    fontWeight: 'bold',
    fontSize: 10,
  },
});
