import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PersonalData from './PersonalData';
import bannerFirstPage from 'assets/img/banner-first-page.png';
import bannerWorkmotor from 'assets/img/banner-workmotor.png';
import { useBranding } from 'hooks/useBranding';

const TryItFreeForm = ({ onSubmit, loading, onCancel }) => {
  const { isWorkmotor, isTecnomotor, isBarros, isPO360 } = useBranding();

  return (
    <Col id="ExpGrtsContent" className="bg-expGratis">
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        id="HeaderExpGrts"
        className={`
          ${isWorkmotor ? 'wm-bg-primary' : ''}
          ${isTecnomotor ? 'tecnomotor-bg-primary' : ''}
          ${isBarros ? 'barros-bg-primary' : ''}
          ${isPO360 ? 'po360-bg-primary' : ''}

          `}
      >
        <div id="contentPage">
          <h1>
            Sistema Online Gratuito para Oficinas e Autopeças. <br />
            Cadastre Clientes, Produtos e Serviços, Gere O.S e Relatórios de
            Vendas <br />
          </h1>
          <h2
            className={`
              ${isWorkmotor ? 'wm-text-auxiliary' : ''}
              ${isTecnomotor ? 'tecnomotor-text-auxiliary' : ''}
              ${isBarros ? 'barros-text-auxiliary' : ''}
              ${isPO360 ? 'po360-text-auxiliary' : ''}
            `}
          >
            NÃO EXPIRA NUNCA, USE A VONTADE!
          </h2>
          <div id="contentImg">
            <img
              src={`${isWorkmotor ? bannerWorkmotor : bannerFirstPage}`}
              height="460px"
              width="695px"
            />
          </div>
          <div id="contentTextImg">
            <h1
              className={`
                ${isWorkmotor ? 'wm-text-primary' : ''}
                ${isTecnomotor ? 'tecnomotor-text-primary' : ''}
                ${isBarros ? 'barros-text-primary' : ''}
                ${isPO360 ? 'po360-text-primary' : ''}
              `}
            >
              TOTALMENTE
              <br />
              GRATUITO,
              <br /> 100% ONLINE
            </h1>
            <a
              href={
                isWorkmotor
                  ? 'https://www.workmotor.com.br/'
                  : isPO360
                  ? 'https://po360comercial.com.br/'
                  : 'https://osdig.com.br/'
              }
            >
              <button
                className={`
                  ${isWorkmotor ? 'wm-bg-primary-context-text-img' : ''}
                  ${isTecnomotor ? 'tecnomotor-bg-primary-context-img' : ''}
                  ${isBarros ? 'barros-bg-primary-context-text-img' : ''}
                  ${isPO360 ? 'po360-bg-primary-context-text-img' : ''}
                `}
              >
                CONHEÇA MAIS
              </button>
            </a>
          </div>
        </div>
      </Col>
      <PersonalData onSubmit={onSubmit} loading={loading} />
    </Col>
  );
};

TryItFreeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default TryItFreeForm;
