import React, { useEffect, useState } from 'react'

import { AppLoading } from 'client/routes/index.routes'
import AppError from 'components/AppError'
import constants from '../../utils/constants'
import CustomerRepository from '../../repositories/Customers'
import CompaniesRepository from '../../repositories/Companies'
import SalesRepository from '../../repositories/Sales'
import PrismaBoxRepository from 'repositories/PrismaBox'

import { EmptySheet } from './EmptySheet'
import { MotosSheet } from './MotosSheet'
import { CarrosSheet } from './CarrosSheet'
import { PesadosSheet } from './PesadosSheet'
import { decrypt } from 'client/components/ToNormalize/ToNormalize'
import { PDFViewer } from '@react-pdf/renderer'
import { useAuth } from 'contexts/auth'

export const SheetsAnnotation = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [type, setType] = useState('Carros')
  const [customer, setCustomer] = useState({})

  const { companyId } = useAuth()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams && companyId) return loadQuery(searchParams)
  }, [location.search, companyId])

  const loadQuery = async (searchParams) => {
    try {
      const typeQuery = searchParams.get('type')
      setType(typeQuery)
      const clientIdHash = searchParams.get('clientId')
      const vehicleIdHash = searchParams.get('vehicleId')
      const saleIdHash = searchParams.get('saleId')
      const prismaBoxIdHash = searchParams.get('prismaBoxId')

      let clientId
      let vehicleId
      let saleId
      let prismaBoxId

      if (clientIdHash) {
        clientId = Number(await decrypt(clientIdHash, '@OS-dig:clientId'))
      }

      if (vehicleIdHash) {
        vehicleId = Number(await decrypt(vehicleIdHash, '@OS-dig:vehicleId'))
      }

      if (saleIdHash) {
        saleId = Number(await decrypt(saleIdHash, '@OS-dig:saleId'))
      }

      if (prismaBoxIdHash) {
        prismaBoxId = Number(
          await decrypt(prismaBoxIdHash, '@OS-dig:prismaBoxId')
        )
      }

      if (!clientId) return handleLoadDataWithoutClientId()

      handleLoadData({ clientId, vehicleId, saleId, prismaBoxId })
    } catch (err) {
      setHasError(true)
      setLoading(false)
    }
  }

  const renderPdf = () => {
    switch (type) {
      case constants.SEGMENTS_DESCRIPTION.Vazio:
        return <EmptySheet customer={customer} />
      case constants.SEGMENTS_DESCRIPTION.Carros:
        return <CarrosSheet customer={customer} />
      case constants.SEGMENTS_DESCRIPTION.Motos:
        return <MotosSheet customer={customer} />
      case constants.SEGMENTS_DESCRIPTION.Pesados:
        return <PesadosSheet customer={customer} />
      default:
        setHasError(true)
        break
    }
  }

  const handleLoadDataWithoutClientId = async () => {
    try {
      const company = await CompaniesRepository.getById(companyId)

      const checkListData = {
        company,
        vehicle: {},
        date: new Date(),
      }

      setCustomer(checkListData)
    } catch (err) {
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  const handleLoadData = async ({
    clientId,
    vehicleId,
    saleId,
    prismaBoxId,
  }) => {
    try {
      const { data } = await CustomerRepository.getClientByIdForChecklistSheet(
        clientId
      )

      let prismaBox
      if (prismaBoxId) {
        const { data: prismaBoxData } = await PrismaBoxRepository.show(
          prismaBoxId
        )
        prismaBox = prismaBoxData.description
      }

      const company = data.Company
      delete data.Company

      const checkListData = {
        ...data,
        company,
        prismaBox,
        vehicle: vehicleId ? data.Vehicle.find((v) => v.id === vehicleId) : {},
        date: new Date(),
      }
      delete checkListData.Vehicle

      setCustomer(checkListData)
    } catch (err) {
      console.log(err)
      setHasError(true)
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <AppLoading />

  if (hasError)
    return (
      <AppError message="Ocorreu um erro ao carregar o impresso. Por favor, tente novamente" />
    )

  if (!!customer)
    return (
      <div id="sale-printing-sheet-page">
        <main style={{ height: '100vh', width: '100vw' }}>
          <PDFViewer style={{ height: '100%', width: '100%' }}>
            {renderPdf()}
          </PDFViewer>
        </main>
      </div>
    )
}
