import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AvailableEmployees from './components/AvailableEmployees';
import Maintenances from './components/Maintenances';

import { useAuth } from 'contexts/auth';
import { useWorkshopPanel } from 'contexts/workshopPanel';

import './index.css';
import { useBranding } from 'hooks/useBranding';

export default function WorkshopPanel() {
  const history = useHistory();
  const { companyId, loading: loadingAuth } = useAuth();

  const { reloadPanelInformation, loadPanelInformation, setLastRefresh } =
    useWorkshopPanel();

  const [refreshInterval, setRefreshInterval] = useState();

  const handleGetPanelInformation = useCallback(async () => {
    if (companyId) {
      await loadPanelInformation(companyId);
    }
    setLastRefresh(new Date());
  }, [companyId]);

  const validateUserSign = useCallback(() => {
    if (!loadingAuth && !companyId) {
      return history.replace('/');
    }
  }, [companyId, loadingAuth]);

  const refreshWorkshopPanel = useCallback(
    (refreshInterval) => {
      if (reloadPanelInformation) {
        if (refreshInterval) {
          clearInterval(refreshInterval);
        }

        handleGetPanelInformation();

        const refreshIntervalFn = setInterval(() => {
          handleGetPanelInformation();
        }, 300000);

        setRefreshInterval(refreshIntervalFn);
      }
    },
    [companyId, reloadPanelInformation]
  );

  const { isWorkmotor } = useBranding();

  useEffect(() => {
    document.title = isWorkmotor
      ? `WorkMotor - Painel da Oficina`
      : `OS Digital - Painel da Oficina`;
  }, []);

  useEffect(() => {
    validateUserSign();
  }, [validateUserSign]);

  useEffect(() => {
    refreshWorkshopPanel(refreshInterval);
  }, [refreshWorkshopPanel]);

  return (
    <div className="workshop-panel">
      <Maintenances />
      <AvailableEmployees />
    </div>
  );
}
