import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'contexts/auth';

import { getProductsUnderStock, handleChange } from './redux/actions';

import { usePlanSignatureContext } from '../../../contexts/plan-signature';

import './styles.css';
import { useHistory } from 'react-router-dom';
import constants from '../../../utils/constants';
import { useBranding } from 'hooks/useBranding';

export const MinStockAlertButton = () => {
  const { products } = useSelector((state) => state.minStockAlert);
  const dispatch = useDispatch();

  const { companyId } = useAuth();

  const history = useHistory();

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  useEffect(() => {
    if (!!companyId) {
      dispatch(getProductsUnderStock(companyId));
    } else {
      dispatch(handleChange('products', []));
    }
  }, [companyId]);

  const handleRedirectToMinMaxStockReport = () => {
    if (history.location.search.includes('?loadProductsInAlert=true'))
      return dispatch(getProductsUnderStock(companyId));
    history.push(
      constants.ROUTES.MIN_MAX_STOCK_REPORT + '?loadProductsInAlert=true'
    );
  };

  const { isWorkmotor } = useBranding();

  const getMinStockMessage = () => {
    const plural = products?.length > 0;

    if (plural) {
      return `Há produtos em estoque mínimo`;
    } else {
      return `1 produto está em estoque mínimo. Clique aqui para verificar`;
    }
  };

  return (
    !isPlanFree &&
    !isPlanStart &&
    products.length !== 0 && (
      <button
        type="button"
        id="min-stock-alert-button"
        onClick={handleRedirectToMinMaxStockReport}
      >
        {getMinStockMessage()}
      </button>
    )
  );
};
