import React from 'react';

import '../index.css';
import { useBranding } from 'hooks/useBranding';

export const FooterExternalWorkshop = () => {
  const { getMainLogoByBranding } = useBranding();

  return (
    <div className={`external-workshop-panel__footer`}>
      <img
        className="external-workshop-panel__footer__os-dig-logo"
        src={getMainLogoByBranding()}
        alt="Logo do OS Digital"
      />
    </div>
  );
};
