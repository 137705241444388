import React, { useState, useEffect } from 'react';
import { reduxForm, change, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormSection } from 'redux-form';
import { Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Input from './components/Input';

import CardForm from 'client/components/CardForm';
import DadosBasicos from './DadosBasicos';
import Endereco from './Endereco';
import FormSubmitButtons from '../../../../components/FormSubmitButtons';
import AlertModal from '../../../../../components/AlertModal/AlertModal';
import constants from '../../../../../utils/constants';

import './styles.css';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';

function FormCliente({ handleSubmit, ClientId, loading, history, hideModal }) {
  const dispatch = useDispatch();
  const [dadosB, setDadosB] = useState(true);
  const [enderecoPrincipal, setEnderecoPrincipal] = useState(false);
  const [enderecoEntrega, setEnderecoEntrega] = useState(false);
  const [modal, setModal] = useState(false);

  const {
    cadastroCliente: {
      values: { PrincipalAddress, DeliveryAddress, isSameDeliveryAddress },
    },
  } = useSelector((state) => state.form);

  function cancelModal() {
    if (hideModal) return hideModal();
    setModal(false);
    history.push(constants.ROUTES.CUSTOMERS);
  }

  function defineIsSameDeliveryAddress() {
    const principalZipcode = onlyNumbers(PrincipalAddress?.Zipcode);
    const deliveryZipcode = onlyNumbers(DeliveryAddress?.Zipcode);
    const principalAddressNumber = PrincipalAddress?.Address_Number;
    const deliveryAddressNumber = DeliveryAddress?.Address_Number;

    dispatch(
      change(
        'cadastroCliente',
        `isSameDeliveryAddress`,
        (principalZipcode &&
          deliveryZipcode &&
          principalZipcode === deliveryZipcode &&
          principalAddressNumber &&
          deliveryAddressNumber &&
          principalAddressNumber === deliveryAddressNumber) ||
          false
      )
    );
  }

  useEffect(() => {
    defineIsSameDeliveryAddress();
  }, [PrincipalAddress, DeliveryAddress]);

  return (
    <div className="form-client">
      <CardForm
        show={dadosB}
        title="Dados Básicos"
        onClick={() => setDadosB(!dadosB)}
      >
        <DadosBasicos
          onSubmit={(event) => {
            event.preventDefault();
            setDadosB(false);
            setEnderecoPrincipal(true);
            setEnderecoEntrega(false);
          }}
          edit={ClientId}
        />
      </CardForm>
      <CardForm
        show={enderecoPrincipal}
        title="Endereço Principal (Opcional)"
        onClick={() => setEnderecoPrincipal(!enderecoPrincipal)}
      >
        <Col lg={12} md={12} sm={12} xs={12}>
          <FormSection name="PrincipalAddress">
            <Endereco
              multi="PrincipalAddress"
              form="cadastroCliente"
              edit={!!ClientId}
              required={false}
            />
          </FormSection>
        </Col>
        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <fieldset className="address-fieldset">
            <label>Mesmo endereço de entrega</label>
            <div>
              <Field
                name="isSameDeliveryAddress"
                className="address-checkbox"
                component={Input}
                type="checkbox"
              />
            </div>
          </fieldset>
          <button
            className="btn-green"
            type="button"
            onClick={() => {
              setDadosB(false);
              setEnderecoPrincipal(false);
              setEnderecoEntrega(true);
            }}
          >
            Prosseguir
          </button>
        </Col>
      </CardForm>

      {console.log('PrincipalAddress', PrincipalAddress)}
      {console.log('DeliveryAddress', DeliveryAddress)}
      {console.log('isSameDeliveryAddress', isSameDeliveryAddress)}

      {!isSameDeliveryAddress && (
        <CardForm
          show={enderecoEntrega}
          title="Endereço de Entrega (Opcional)"
          onClick={() => setEnderecoEntrega(!enderecoEntrega)}
        >
          <FormSection name="DeliveryAddress">
            <Endereco
              multi="DeliveryAddress"
              form="cadastroCliente"
              edit={!!ClientId}
              required={false}
              duplicateZipCode={
                DeliveryAddress
                  ? DeliveryAddress?.Zipcode
                  : PrincipalAddress?.Zipcode
              }
              duplicateState={
                DeliveryAddress
                  ? DeliveryAddress?.State
                  : PrincipalAddress?.State
              }
              duplicateCity={
                DeliveryAddress ? DeliveryAddress?.City : PrincipalAddress?.City
              }
            />
          </FormSection>
        </CardForm>
      )}

      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{ padding: 0 }}
      >
        <FormSubmitButtons
          loading={loading}
          hasId={!!ClientId}
          onSubmit={handleSubmit}
          onCancel={() => setModal(true)}
        />
      </div>

      {modal && (
        <AlertModal
          show={modal}
          onHide={() => setModal(false)}
          onCancel={() => setModal(false)}
          message="Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar?"
          onSubmit={cancelModal}
        />
      )}
    </div>
  );
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'cadastroCliente',
})(withRouter(FormCliente));
