import React from 'react';

import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import DownloadXlsButton from '../../../components/DownloadXlsButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SelectBranding } from 'v2/components/Select';
import { InputLabel, InputContainer } from 'v2/components/Input';
import { SelectMulti } from 'v2/components/Select/Multi';

export default function ClientsFilter({
  plansOptions,
  statusOptions,
  type,
  setType,
  signaturePeriod,
  setSignaturePeriod,
  signatureStatusIds,
  setSignatureStatusIds,
  planIds,
  setPlanIds,
  dateType,
  setDateType,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  branding,
  setBranding,
  query,
  handleChangeQuery,
  isXlsExportReady,
  isXlsExportLoading,
  loadDataToExport,
  dataToExport,
}) {
  return (
    <div className="admin-client__filters">
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '10px',
          paddingLeft: '0px',
          marginBottom: 15,
        }}
      >
        <div
          className="col-xs-12 col-sm-5 col-md-5 col-lg-5"
          style={{ paddingLeft: '0px' }}
        >
          <i
            id="iconepesquisar"
            style={{ cursor: 'pointer' }}
            className="fa fa-search"
          />
          <input
            type="text"
            style={{
              paddingLeft: 35,
            }}
            className="form-control foco-input"
            placeholder="Pesquisar por CPF/CNPJ, Nome/Razão Social, Nome fantasia ou ID"
            value={query}
            onChange={(e) => handleChangeQuery(e.target.value)}
          />
        </div>
        <div className="flex center gap-025">
          <SelectBranding
            variant="row"
            height={35}
            value={branding}
            onChange={(e) => setBranding(e)}
            showAllOption
          />
        </div>
        <div className="flex center gap-025">
          <InputContainer variant="row">
            <InputLabel>Planos:</InputLabel>
            <SelectMulti
              width={130}
              height={35}
              options={plansOptions}
              value={planIds}
              onChange={(e) => setPlanIds(e)}
              hasAllOption
            />
          </InputContainer>
        </div>
        <div className="flex center gap-025">
          <InputContainer variant="row">
            <InputLabel>Status:</InputLabel>
            <SelectMulti
              width={130}
              height={35}
              options={statusOptions}
              value={signatureStatusIds}
              onChange={(e) => setSignatureStatusIds(e)}
              hasAllOption
              selectAllByDefault
            />
          </InputContainer>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          width: '100%',
          marginBottom: 15,
        }}
      >
        <div className="flex center gap-025">
          <label>Tipo de data:</label>
          <select
            value={dateType}
            onChange={(e) => setDateType(e.target.value)}
            className="form-control foco-input"
          >
            <option value="createdAt">Cadastro</option>
            <option value="lastSignatureEndDate">Vigência</option>
            <option value="lastLogin">Últ. Acesso</option>
            <option value="lastSaleDate">Últ. Venda</option>
          </select>
        </div>
        <div className="flex center gap-025">
          <label>Data Inicial:</label>
          <input
            className="form-control foco-input"
            type="date"
            value={initialDate}
            onChange={(e) => setInitialDate(e.target.value)}
          />
        </div>
        <div className="flex center gap-025">
          <label>Data Final:</label>
          <input
            className="form-control foco-input"
            type="date"
            value={finalDate}
            onChange={(e) => setFinalDate(e.target.value)}
          />
        </div>
        <div className="flex center gap-025">
          <label>Período:</label>
          <select
            value={signaturePeriod}
            onChange={(e) => setSignaturePeriod(e.target.value)}
            className="form-control foco-input"
          >
            <option value="">Todos</option>
            <option value="Mensal">Mensal</option>
            <option value="Trimestral">Trimestral</option>
            <option value="Semestral">Semestral</option>
            <option value="Anual">Anual</option>
          </select>
        </div>
        <div className="flex center gap-025">
          <label>Tipo:</label>
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="form-control foco-input"
          >
            <option value="ALL">Ambos</option>
            <option value="Física">Física</option>
            <option value="Jurídica">Jurídica</option>
          </select>
        </div>
        <div className="flex center gap-025">
          {isXlsExportReady ? (
            <DownloadXlsButton
              archiveName="Relação de Clientes"
              data={dataToExport}
              className="btn btn-export"
              columns={[
                {
                  name: 'CPF/CNPJ',
                  acessor: 'Cpf_Cnpj',
                },
                {
                  name: 'RAZÃO SOCIAL',
                  acessor: 'Company_Name',
                },
                {
                  name: 'EMAIL',
                  acessor: 'Email',
                },
                {
                  name: 'CIDADE',
                  acessor: 'City',
                },
                {
                  name: 'ESTADO',
                  acessor: 'State',
                },
                {
                  name: 'TELEFONE',
                  acessor: 'Number_Phone1',
                },
                {
                  name: 'PRODUTO',
                  acessor: 'branding',
                },
                {
                  name: 'PLANO',
                  acessor: 'planDescription',
                },
                {
                  name: 'DT. CADASTRO',
                  acessor: 'createdAt',
                },
                {
                  name: 'DT. VIGÊNCIA',
                  acessor: 'SignatureEnd_Date',
                },
                {
                  name: 'ULTIMA VENDA',
                  acessor: 'lastSaleDate',
                },
                {
                  name: 'ULTIMO ACESSO',
                  acessor: 'lastLogin',
                },
              ]}
            >
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Baixar
              Relatório
            </DownloadXlsButton>
          ) : (
            <button
              className="btn btn-export"
              onClick={() => loadDataToExport()}
              disabled={isXlsExportLoading}
            >
              {isXlsExportLoading ? (
                <span className="fa fa-spinner fa-pulse fa-1x"></span>
              ) : (
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
              )}
              &nbsp;Exportar .xls
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
