import React, { useState } from 'react';
import {
  faEdit,
  faTrashAlt,
  faPrint,
  faSearch,
  faWindowClose,
  faShareAltSquare,
  faPenSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';

import { INVOICE_STATUS_DESCRIPTION } from '../../../utils/constants';
import NFesRepository from '../../../repositories/NFes';

import AlertModal from 'components/AlertModal/AlertModal';
import CancelInvoiceModal from './CancelInvoiceModal';
import CorrectionLetterModal from './CorrectionLetterModal';
import Loader from '../../../components/Loader';
import ShareNFe from '../../components/ShareNFe/ShareNFe';

const NFeActions = ({ NFe, onChange, onEdit }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showCancelInvoiceModal, setShowCancelInvoiceModal] = useState(false);
  const [showCorrectionLetterModal, setShowCorrectionLetterModal] =
    useState(false);
  const [isLoadingDeleteInvoice, setIsLoadingDeleteInvoice] = useState(false);
  const [loadingConsult, setLoadingConsult] = useState(false);
  const [isShareNFeModalOpen, setIsShareNFeModalOpen] = useState(false);

  const isOpen = NFe.status === INVOICE_STATUS_DESCRIPTION.OPEN;
  const isOpenWithErrors =
    NFe.status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS;
  const isDisabled = NFe.status === INVOICE_STATUS_DESCRIPTION.DISABLED;
  const isIssued = NFe.status === INVOICE_STATUS_DESCRIPTION.ISSUED;
  const isInContingency = NFe.status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY;

  async function handleDeleteNFe() {
    setIsLoadingDeleteInvoice(true);
    try {
      await NFesRepository.deleteNFe(NFe.id);
      toastr.success('NF-e deletada com sucesso');
      setIsDeleteModalOpen(false);
      onChange();
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao deletar a NF-e. Por favor, tente novamente'
      );
    } finally {
      setIsLoadingDeleteInvoice(false);
    }
  }

  function onSuccessCorrectionLetterModal() {
    setShowCorrectionLetterModal(false);
    onChange();
  }

  function onSuccessCancelInvoiceModal() {
    setShowCancelInvoiceModal(false);
    onChange();
  }

  function handleOpenPDFDownloadPage() {
    window.open(NFe.docPDFDownload);
  }

  async function handleConsultNFeInContingency() {
    setLoadingConsult(true);
    try {
      await NFesRepository.consultContingency(NFe.id);
      toastr.success('NF-e consultada com sucesso.');
      onChange();
    } catch (err) {
      console.log(err.response);
      toastr.error(
        'Ocorreu um erro ao consultar NF-e em contingência. Por favor, tente novamente'
      );
    } finally {
      setLoadingConsult(false);
    }
  }

  async function handleConsultNFe() {
    setLoadingConsult(true);
    try {
      await NFesRepository.consult(NFe.id);
      toastr.success('NF-e consultada com sucesso.');
      onChange();
    } catch (err) {
      console.log(err.response);
      toastr.error(
        'Ocorreu um erro ao consultar NF-e. Por favor, tente novamente'
      );
    } finally {
      setLoadingConsult(false);
    }
  }

  if (isDisabled) return null;

  return (
    <div style={{ display: 'flex' }}>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => onEdit(NFe)}
        className="btn-acoes"
        style={{ color: '#000000', fontSize: '14px' }}
      />
      {(isOpen || isOpenWithErrors) && (
        <FontAwesomeIcon
          onClick={() => setIsDeleteModalOpen(true)}
          icon={faTrashAlt}
          className="btn-acoes"
          style={{ color: '#e61610', fontSize: '14px' }}
        />
      )}
      {isInContingency && (
        <FontAwesomeIcon
          icon={faSearch}
          onClick={handleConsultNFeInContingency}
          className="btn-acoes"
          style={{ color: '#444444', fontSize: '14px' }}
        />
      )}
      {isIssued && (
        <>
          <FontAwesomeIcon
            icon={faPenSquare}
            onClick={() => setShowCorrectionLetterModal(true)}
            className="btn-acoes"
            style={{ color: '#008db1', fontSize: '14px' }}
          />
          <FontAwesomeIcon
            icon={faPrint}
            onClick={handleOpenPDFDownloadPage}
            className="btn-acoes"
            style={{ color: '#444444', fontSize: '14px' }}
          />
          <FontAwesomeIcon
            icon={faSearch}
            onClick={handleConsultNFe}
            className="btn-acoes"
            style={{ color: '#444444', fontSize: '14px' }}
          />
          <FontAwesomeIcon
            icon={faWindowClose}
            onClick={() => setShowCancelInvoiceModal(true)}
            className="btn-acoes"
            style={{ color: '#e61610', fontSize: '14px' }}
          />
          <FontAwesomeIcon
            icon={faShareAltSquare}
            onClick={() => setIsShareNFeModalOpen(true)}
            className="btn-acoes"
            style={{ color: '#72bb53', fontSize: '14px' }}
          />
        </>
      )}

      <AlertModal
        message="Deseja realmente excluir a nota?"
        show={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onHide={() => setIsDeleteModalOpen(false)}
        onSubmit={handleDeleteNFe}
        loading={isLoadingDeleteInvoice}
      />

      <CancelInvoiceModal
        show={showCancelInvoiceModal}
        onCancel={() => setShowCancelInvoiceModal(false)}
        onHide={() => setShowCancelInvoiceModal(false)}
        onSuccess={onSuccessCancelInvoiceModal}
        nfceSelected={NFe}
      />

      <CorrectionLetterModal
        show={showCorrectionLetterModal}
        onCancel={() => setShowCorrectionLetterModal(false)}
        onHide={() => setShowCorrectionLetterModal(false)}
        onSuccess={onSuccessCorrectionLetterModal}
        nfeSelected={NFe}
      />

      {isShareNFeModalOpen && (
        <ShareNFe
          show={isShareNFeModalOpen}
          animation
          onHide={() => setIsShareNFeModalOpen(false)}
          nfe={NFe}
          onSubmit={() => setIsShareNFeModalOpen(false)}
        />
      )}

      {loadingConsult && <Loader message="Aguarde, consultando NF-e" />}
    </div>
  );
};

export default NFeActions;
