import React, { useState } from 'react';
import { reduxForm } from 'redux-form';

import { useSelector } from 'react-redux';

import CardForm from '../../../../client/components/CardForm';
import Footer from './Footer';
import Title from './Title';
import Payment from '../../../components/BillPayments';

import './styles.css';
import BillDataForm from './BillDataForm';

const FormBillToReceive = ({
  handleSubmit,
  cancel,
  loading,
  setLoading,
  titleId,
}) => {
  const [isTitleCardFormOpen, setIsTitleCardFormOpen] = useState(true);
  const [isPaymentDataFormOpen, setIsPaymentDataFormOpen] = useState(true);
  const [isBillDataFormOpen, setIsBillDataFormOpen] = useState(true);
  const { bankSlipId } = useSelector(
    (state) => state.form.billToReceive?.values
  );

  return (
    <form
      onSubmit={handleSubmit}
      onCancel={cancel}
      style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}
    >
      <CardForm
        show={isTitleCardFormOpen}
        onClick={() => setIsTitleCardFormOpen(!isTitleCardFormOpen)}
        title="Dados do Título"
      >
        <Title titleId={titleId} setLoading={setLoading} />
      </CardForm>

      {bankSlipId !== 0 && bankSlipId !== null && (
        <CardForm
          show={isBillDataFormOpen}
          onClick={() => setIsBillDataFormOpen(!isBillDataFormOpen)}
          title="Dados do boleto"
        >
          <BillDataForm loading={loading} />
        </CardForm>
      )}

      <CardForm
        show={isPaymentDataFormOpen}
        onClick={() => setIsPaymentDataFormOpen(!isPaymentDataFormOpen)}
        title="Dados do Pagamento"
      >
        <Payment loading={loading} name="billToReceive" />
      </CardForm>

      <Footer
        handleSubmit={handleSubmit}
        handleCancel={cancel}
        loading={loading}
      />
    </form>
  );
};

export default reduxForm({
  form: 'billToReceive',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(FormBillToReceive);
