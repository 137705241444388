import React, { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'

import RenderPDV from './RenderPDV'

import salesRepository from '../../../repositories/Sales'

import './styles.css'

const SalePrint = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [sale, setSale] = useState(null)

  useEffect(() => {
    const getSaleIdFromPathName = () =>
      location.pathname
        .split('Vend=v723dha5tHSSpS83711')[1]
        ?.split('5422hsGsdAQLk8423oPL31')[0]
    const saleId = getSaleIdFromPathName()
    if (!!saleId) {
      loadSale(saleId)
    }
  }, [])

  const loadSale = async (saleId) => {
    try {
      const sale = await salesRepository.getSaleToPrintPDV(saleId, {
        ['x-force-auth']: true,
      })
      setSale(sale)
    } catch (err) {
      console.error(err)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = () => {
    document.title = `OS Digital - ${sale?.SalesType?.Description} #${sale?.Code}`
    window.print()
    document.title = 'OS Digital'
  }

  if (loading) {
    return (
      <LoadingOverlay
        active={true}
        spinner
        text="Aguarde..."
        classNamePrefix="MyLoader_"
        fadeSpeed={100}
      />
    )
  }

  if (!loading && error) {
    return <div id="pdv-load-error">Falha ao carregar dados</div>
  }

  return (
    <div id="pdv-to-print-wrapper">
      <div className="impHeader">
        <button onClick={handlePrint}>
          <span className="fa fa-print" />
          Imprimir
        </button>
      </div>
      <div id="toprint">
        <RenderPDV sale={sale} />
      </div>
    </div>
  )
}

export default SalePrint
